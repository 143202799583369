import {
  CHANGE_LANGUAGE_SUCCESS,
  LOAD_DECISION_TREE_SUCCESS,
  SET_CONFIGURATION_FAIL,
  SET_CONFIGURATION_SUCCESS,
  UPDATE_POLICIES_CONFIGURATION_SUCCESS,
} from 'redux/actions/types';
import { Configuration, ConfigurationState } from 'ts/types/config.types';
import { ApplicationActionType } from 'redux/actions/applicationActions';

const initState: ConfigurationState = {
  configuration: {} as Configuration,
  error: null,
  isAppIntroEnabled: false,
  currentLanguage: undefined,
};

function applicationReducer(state = initState, action: ApplicationActionType) {
  switch (action.type) {
    case SET_CONFIGURATION_FAIL:
      return Object.assign({}, state, { error: action.payload.error });
    case SET_CONFIGURATION_SUCCESS:
      return Object.assign({}, state, {
        configuration: action.payload.configuration,
      });
    case UPDATE_POLICIES_CONFIGURATION_SUCCESS:
      return Object.assign({}, state, {
        configuration: {
          ...state.configuration,
          policies: action.payload.configuration,
        },
      });
    case LOAD_DECISION_TREE_SUCCESS:
      return Object.assign({}, state, {
        configuration: {
          ...state.configuration,
          decisionTree: {
            ...state.configuration.decisionTree,
            [action.payload.decisionTree.language]:
              action.payload.decisionTree.decisionTree,
          },
        },
      });
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        currentLanguage: action.payload.language,
      };
    default:
      return state;
  }
}

export default applicationReducer;
