import { init as initAnalytics } from 'analytics';
import { LOGGER } from 'constants/application';

export const isTheSameEnvironmentVariable = (firstEnv, secondEnv) => {
  const firstEnvValue = JSON.stringify(firstEnv);
  const secondEnvValue = JSON.stringify(secondEnv);
  return firstEnvValue === secondEnvValue;
};

export const initAnalyticsLogging = (custom_steps, gtm_env, gtm_id) => {
  initProviders(custom_steps, gtm_env, gtm_id, true);
};
export const initOnlyRequiredAnalyticsLogging = (
  custom_steps,
  gtm_env,
  gtm_id,
) => {
  initProviders(custom_steps, gtm_env, gtm_id, false);
};

export const initProviders = (
  custom_steps,
  gtm_env,
  gtm_id,
  shouldStartAll,
) => {
  const LOGGER_ENV = JSON.stringify(process.env.REACT_APP_LOGGER);
  const LOGGER_CONSTANT = JSON.stringify(LOGGER);
  const activateLogger = LOGGER_ENV === LOGGER_CONSTANT;
  const initParamsAnalytics = {
    logger: activateLogger,
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    initGlobalGA: false,
  };
  if (shouldStartAll) {
    const configEnv = process.env.REACT_APP_ABODE_ENV;
    initParamsAnalytics.gtm = gtm_id
      ? { ...(gtm_env || {})[configEnv], gtmId: gtm_id }
      : {};
    initParamsAnalytics.initGlobalGA = true;
    initParamsAnalytics.customPageViewDataLayers = {};
    custom_steps.forEach(({ path, page_name, page_type, virtual_page_url }) => {
      initParamsAnalytics.customPageViewDataLayers[path] = [
        page_name,
        page_type,
        virtual_page_url,
      ];
    });
  }
  initAnalytics(initParamsAnalytics);
};
