import { put, select } from 'redux-saga/effects';
import { contactUsFail, contactUsSuccess } from 'redux/actions/contactActions';
import { contactPhoneSelector } from 'redux/selectors/config';

export function* contactUsSaga() {
  try {
    const phoneNumber = yield select(contactPhoneSelector);
    window.open(`tel:${phoneNumber}`, '_self');
    yield put(contactUsSuccess());
  } catch (error) {
    yield put(contactUsFail());
  }
}
