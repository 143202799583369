import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LoaderIcon from '../Icons/LoaderIcon';
import LoaderStyled from './LoaderStyled';
import { isLoadingSelector } from 'redux/selectors/loading';

export const Loader = ({ opacity, isStatic, force }) => {
  const isLoading = useSelector(isLoadingSelector);

  if (!isLoading && !force) {
    return null;
  }

  return (
    <LoaderStyled
      data-testid={'LoaderIcon'}
      opacity={opacity}
      isStatic={isStatic}
    >
      <LoaderIcon title="loading icon" />
    </LoaderStyled>
  );
};

Loader.propTypes = {
  opacity: PropTypes.string,
  isStatic: PropTypes.bool,
};

Loader.defaultProps = {
  opacity: '0.5',
  isStatic: false,
};

export default Loader;
