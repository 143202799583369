import { getStore } from 'redux/store/config';
import { showError } from 'redux/actions/errorActions';
import { GLOBAL_TIMEOUT } from 'constants/application';
import { event } from 'analytics';
const { fetch: originalFetch } = window;
const FETCH_ERROR_EVENT_LABEL = 'fetch_error_on_response';
const FETCH_CALL_LABEL = 'fetch_call';

const parseJSONResponse = async response => {
  try {
    return await response.clone().json();
  } catch (error) {
    console.error(error);
    return {};
  }
};

window.fetch = async (...args) => {
  try {
    const [resource, config] = args;
    const response = await originalFetch(resource, config);
    if (!response.ok) {
      const body = await parseJSONResponse(response);
      event(
        FETCH_ERROR_EVENT_LABEL,
        FETCH_CALL_LABEL,
        [response?.statusText || 'Error'],
        {
          ...body,
        },
      );
      return Promise.reject(response);
    }
    return response;
  } catch (e) {
    console.error(FETCH_ERROR_EVENT_LABEL, e);
    event(
      FETCH_ERROR_EVENT_LABEL,
      FETCH_CALL_LABEL,
      ['CORS', 'Invalid HTTP', 'Invalid URL'],
      {
        message: e.message,
      },
    );
    return Promise.reject({
      ok: false,
      message: 'CORS, Invalid HTTP or Invalid URL error',
      error: e.toString(),
    });
  }
};

const manageHeaders = ({ headers, withCredentials, bearerToken }) => {
  if (withCredentials && bearerToken) {
    headers = { ...headers, Authorization: 'Bearer ' + bearerToken };
  }
  return headers;
};

const getUrlWithParams = ({ url, params }) => {
  const paramsToParse = params
    ? Object.keys(params).reduce((acc, currrentValue) => {
        return params[currrentValue]
          ? { ...acc, [currrentValue]: params[currrentValue] }
          : acc;
      }, {})
    : {};

  return `${url}${
    Object.keys(paramsToParse).length
      ? '?' + new URLSearchParams(paramsToParse)
      : ''
  }`;
};

const fetchApi = (
  url,
  {
    method = 'GET',
    params,
    withCredentials = true,
    credentials = 'omit',
    bearerToken = null,
    headers = {
      'content-type': 'application/json; charset=utf-8',
    },
    body = undefined,
    proxy = undefined,
    referrer = undefined,
    hasToStringifyBody = true,
    referrerPolicy = 'origin',
    mode = 'cors',
  },
) => {
  Promise.any = promises => {
    let firstOneToResolve = '';
    return Promise.race(
      promises.map(promise =>
        promise
          .then(promiseResolved => {
            if (firstOneToResolve === '' && promiseResolved === 'timeout') {
              const { store } = getStore();
              store.dispatch(
                showError({
                  title: 'error_popup-title',
                  body: 'error_popup-text_1',
                  button: 'error_popup-button_1',
                }),
              );
            } else {
              firstOneToResolve = promiseResolved;
            }
            return firstOneToResolve;
          })
          .catch(error => {
            if (firstOneToResolve === '') {
              firstOneToResolve = error;
            }
            return error;
          }),
      ),
    );
  };

  headers = manageHeaders({ headers, withCredentials, bearerToken });
  const urlWithParams = getUrlWithParams({ url, params });
  return Promise.any([
    fetch(urlWithParams, {
      body: hasToStringifyBody ? JSON.stringify(body) : body,
      headers,
      method, // *GET, POST, PUT, DELETE, etc.
      credentials,
      withCredentials,
      proxy,
      referrerPolicy,
      referrer,
      mode,
    }),
    new Promise(resolve =>
      setTimeout(() => resolve('timeout'), GLOBAL_TIMEOUT),
    ),
  ]);
};

export default fetchApi;
