const NotGeolocatemeIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0909 10.0909C18.6364 6.27273 15.6364 3.27273 11.9091 2.90909V1H10.0909V2.90909C6.27273 3.27273 3.27273 6.27273 2.90909 10.0909H1V11.9091H2.90909C3.36364 15.7273 6.36364 18.7273 10.0909 19.0909V21H11.9091V19.0909C15.7273 18.6364 18.7273 15.6364 19.0909 11.9091H21V10.0909H19.0909ZM11 7.36364C9 7.36364 7.36364 9 7.36364 11C7.36364 13 9 14.6364 11 14.6364C13 14.6364 14.6364 13 14.6364 11C14.6364 9 13 7.36364 11 7.36364ZM4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4C7.1 4 4 7.1 4 11Z"
      stroke="none"
    />
    <path d="M1.5 20.5L20.5 1.5" strokeLinecap="square" />
  </svg>
);

export default NotGeolocatemeIcon;
