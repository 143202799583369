export const TextSize = {
  xs: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  sm: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  md: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  lg: {
    fontSize: '18px',
    lineHeight: '28px',
  },
  xl: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  '2xl': {
    fontSize: '24px',
    lineHeight: '32px',
  },
  '3xl': {
    fontSize: '30px',
    lineHeight: '36px',
  },
};

type TextSizeType = keyof typeof TextSize;

export default TextSizeType;
