import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { registerEventHandler } from './analytics';
import Application from './App';

import { getStore } from './redux/store/config';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from './serviceWorker';

export const { store, history, persistor } = getStore();

async function renderWithConfig() {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <PersistGate loading={null} persistor={persistor}>
          <Application />
        </PersistGate>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );
}

renderWithConfig();
serviceWorker.register();
registerEventHandler();
