import { call, put, select } from 'redux-saga/effects';
import getMission from 'api/mission';
import { partnerSelector } from 'redux/selectors/config';
import actions from 'redux/actions/missionActions';
import { MissionActionType } from 'redux/actions/missionActions.types';
import { ApiResponse } from 'ts/interfaces/api-response.interface';
import { MissionResponse } from 'ts/types/mission.types';

export function* fetchMission(action: MissionActionType) {
  const { id } = action.payload;
  const { getMissionSuccess, getMissionFail } = actions;
  try {
    const partner: string = yield select(partnerSelector);
    const response: ApiResponse<MissionResponse> = yield call(getMission, {
      id,
    });
    const { result, status } = response;
    const partnerId = (result as MissionResponse).partnerId;
    if ([200, 201].includes(status) && partner === partnerId) {
      yield put(getMissionSuccess(result as MissionResponse));
    } else {
      yield put(getMissionFail('Mission not found'));
    }
  } catch (error) {
    yield put(getMissionFail(error.message || error));
  }
}
