import {
  CLEAR_SCHEDULES,
  SET_SCHEDULE,
  START_SUBMIT_APPOINTMENT,
  SUCCESS_SUBMIT_APPOINTMENT,
  FAIL_SUBMIT_APPOINTMENT,
} from './types';

export const clearSchedules = () => ({
  type: CLEAR_SCHEDULES,
});

export const setSchedule = schedule => ({
  type: SET_SCHEDULE,
  payload: schedule,
});

export const submitAppointment = schedule => ({
  type: START_SUBMIT_APPOINTMENT,
  payload: schedule,
});

export const successSubmitAppointment = schedule => ({
  type: SUCCESS_SUBMIT_APPOINTMENT,
  payload: schedule,
});

export const failSubmitAppointment = schedule => ({
  type: FAIL_SUBMIT_APPOINTMENT,
  payload: schedule,
});
