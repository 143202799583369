import {
  CREATE_CASE_ENABLE_SKIP_IDENTIFICATION,
  CREATE_CASE_FAIL,
  CREATE_CASE_INSTANT_DROPOFF_CLEAN,
  CREATE_CASE_RETRY_COUNT,
  CREATE_CASE_RETRY_RESET_COUNT,
  CREATE_CASE_START,
  CREATE_CASE_SUCCESS,
  CREATE_CASE_TOGGLE_EDIT_MODE,
  CREATE_CASE_TOGGLE_VISIBLE_DETAILS,
  UPDATE_CASE_CREATION,
  UPDATE_CASE_CREATION_DECISION_TREE,
  REMOVE_CASE_CREATION,
  UPDATE_PASSENGERS,
  SET_SKIP_CONTACT_DETAILS,
} from 'redux/actions/types';
import { CaseCreationActionType } from 'redux/actions/caseCreationActions';
import type {
  CaseCreationState,
  PassengersData,
} from 'ts/types/case-creation.types';
import {
  CaseSuccessResponseModel,
  DecisionTreeDataResultModel,
} from 'redux/actions/caseCreationActions.types';

const initialState: CaseCreationState = {
  decisionTree: { result: {} as DecisionTreeDataResultModel, optionPath: [] },
  data: {
    pictures: [],
  },
  response: {} as CaseSuccessResponseModel,
  isEditMode: false,
  retryAttempts: 0,
  skipIdentification: false,
  error: null,
  skipContactDetails: false,
};

function caseReducer(state = initialState, action: CaseCreationActionType) {
  switch (action.type) {
    case CREATE_CASE_ENABLE_SKIP_IDENTIFICATION:
      return { ...state, skipIdentification: true };
    case REMOVE_CASE_CREATION:
      const { [action.payload.propKey]: propRemoved, ...rest } = state.data;
      return {
        ...state,
        data: { ...rest },
        error: null,
      };
    case UPDATE_CASE_CREATION:
      return {
        ...state,
        data: { ...state.data, ...action.payload.caseCreationData },
        error: null,
      };
    case UPDATE_CASE_CREATION_DECISION_TREE:
      return {
        ...state,
        decisionTree: action.payload.caseCreationData.decisionTree,
        error: null,
      };
    case CREATE_CASE_RETRY_RESET_COUNT:
      return { ...state, retryAttempts: 0 };
    case CREATE_CASE_RETRY_COUNT:
      return { ...state, retryAttempts: state.retryAttempts + 1 };
    case CREATE_CASE_START:
      return {
        ...state,
        response: {},
        error: null,
      };
    case CREATE_CASE_SUCCESS:
      const { caseGuid, caseReference, content } = action.payload.caseData;
      return {
        ...state,
        decisionTree: {
          optionPath: [],
          result: { content } as DecisionTreeDataResultModel,
        },
        data: {
          pictures: [],
        },
        retryAttempts: 0,
        response: { caseGuid, caseReference },
      };
    case CREATE_CASE_FAIL:
      return {
        ...state,
        error: action.payload.error,
      };
    case CREATE_CASE_TOGGLE_EDIT_MODE:
      return { ...state, isEditMode: !state.isEditMode };
    case CREATE_CASE_TOGGLE_VISIBLE_DETAILS:
      return { ...state, areDetailsVisible: !state.areDetailsVisible };
    case CREATE_CASE_INSTANT_DROPOFF_CLEAN:
      return { ...state, response: {} };
    case UPDATE_PASSENGERS:
      return {
        ...state,
        data: {
          ...state.data,
          passengers: action.payload.passengersData as PassengersData,
        },
      };
    case SET_SKIP_CONTACT_DETAILS:
      return {
        ...state,
        skipContactDetails: true,
      };
    default:
      return state;
  }
}

export default caseReducer;
