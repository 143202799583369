import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { MainButton, OutlinedButton } from './Buttons';

export const Fluid = styled.div`
  width: 100%;
  height: auto;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

export const Main = styled(Fluid)`
  max-width: ${({ theme }) => theme.maxWidth};
  display: flex;
  flex-direction: column;
  padding-bottom: 54px;
  padding-top: ${({ paddingTop }) => paddingTop || '20px'};
  padding-left: ${({ paddingLeft }) => paddingLeft || 'inherit'};
  padding-right: ${({ paddingRight }) => paddingRight || 'inherit'};

  .bottomButton {
    margin-top: 30px;
  }
`;

export const InfoSectionMain = styled(Main)`
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 48px;
  margin-top: 24px;
  margin-bottom: auto;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.primaryColor};
  font-size: 24px;
  text-align: center;
`;

export const SectionTitle = styled(Title)`
  text-align: left;
  font-weight: normal;
  letter-spacing: 1px;
  margin: 5px;
`;

export const InfoSectionTitle = styled(SectionTitle)`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.historyProviderColor};
`;

export const Button = styled.button`
  padding: 10px;
  width: 100%;
  font-size: 12px;
`;

export const PrimaryButton = MainButton;

export const SecretButton = OutlinedButton;

export const AlternativeButton = styled(SecretButton)`
  border: 1px solid
    ${({ theme }) => theme.primaryButtonColor || theme.primaryColor};
  color: ${({ theme }) => theme.primaryButtonColor || theme.primaryColor};
  a {
    color: ${({ theme }) => theme.primaryButtonColor || theme.primaryColor};
  }
`;

export const DottedButton = styled(AlternativeButton)`
  border: 1px dotted ${({ theme }) => theme.backgroundColorButtonDisabled};
`;

export const Paragraph = styled.p`
  margin: 20px;
`;

export const InlineParagraph = styled.p`
  margin: 5px 26px;
`;

export const CenteredNoMargin = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  word-break: break-word;
`;

export const Centered = styled(CenteredNoMargin)`
  margin-bottom: 10px;
`;

export const Toolbar = styled.div`
  margin: 10px 25px 25px 20px;
  display: flex;
  justify-content: center;

  button {
    margin-left: 5px;
    max-width: 120px;
    padding: 10px;
  }
`;

const LinkStyles = css`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  letter-spacing: 1px;
  text-decoration: none;
  color: ${({ theme }) => theme.nonVisitedLink};

  .right__icon {
    margin-left: auto;

    svg {
      margin: 5px;
      margin-right: 10px;
      margin-left: 18px;
    }
  }

  .left__icon {
    margin-right: auto;

    svg {
      margin: 5px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  &:focus,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.visitedLink};
  }
`;

export const StyledLink = styled(Link)`
  ${LinkStyles}
`;

export const LeftLink = styled(StyledLink)`
  margin-right: auto;
  text-transform: uppercase;
`;

export const LeftLinkButton = styled.div`
  ${LinkStyles}
  margin-right: auto;
  text-transform: uppercase;

  :hover {
    cursor: pointer;
  }
`;

export const LeftHistory = styled.div`
  ${LinkStyles}
  margin-right: auto;
  text-transform: uppercase;
`;

export const TextArea = styled.textarea`
  margin: 0px 25px;
  width: 195px;
  height: 60px;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.surfaceColor};
  resize: none;

  @media only screen and (max-width: 300px) {
    width: 78%;
  }
`;

export const MoreInformationCentered = styled(Centered)`
  margin-top: 5px;
  margin-bottom: 25px;
  font-size: 14px;
  font-style: italic;
  color: ${({ theme }) => theme.onTertiaryColor};
`;

export const PicturesContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  margin: 20px 0;
  grid-template-columns: 1fr 1fr;
`;

export const PictureCover = styled.div`
  height: 88px;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.specialBannerBorderTop};
  background-color: ${({ theme }) => theme.specialPopupBackgroundColor};
`;

export const PictureImage = styled.div`
  background-image: url(${({ backgroundSrc }) => backgroundSrc});
  margin: 4px;
  margin-bottom: 0;
  height: 80px;
  min-width: 80px;
  opacity: ${({ status, historic }) =>
    status !== 'SENT' || historic ? '0.4' : '1'};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const PictureBox = styled.div`
  width: 115px;
  height: 80px;
  margin: 2px;
  background: ${({ theme }) => theme.onSurfaceColor};

  svg {
    width: 115px;
    height: 75px;
    margin: 0;
  }
`;

export const ReadOnly = styled.div`
  pointer-events: none;
`;

export const StepPageTitle = styled.p`
  margin: 0 0 24px;
  max-width: 304px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ theme }) => theme.onBackgroundColor};
`;

export const StepForm = styled.form`
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 304px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > *:last-child {
    margin-bottom: 0;
  }
`;
export const openCaseFormButton = css`
  min-width: 304px;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 1.17px;
  text-align: center;
  border-radius: 0;
`;

export const FormContinueButton = styled(MainButton)`
  ${openCaseFormButton}
  margin: 20px 0 40px;
`;

export const FormCancelButton = styled(AlternativeButton)`
  ${openCaseFormButton}
  margin: 16px 0 0;
`;

export const PageBase = styled.div`
  width: 100%;
  height: 100%;
  margin: 24px auto 48px;
  position: relative;
  max-width: 304px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const PageLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
