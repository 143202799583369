import { UPDATE_LOCATION } from 'redux/actions/types';

const initialState = [];

function locationReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LOCATION:
      return action.payload;
    default:
      return state;
  }
}

export default locationReducer;
