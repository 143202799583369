import { SHOW_ERROR, HIDE_ERROR } from 'redux/actions/types';
import { checkBooleanInPayload } from 'utils/global-utils';

function errorReducer(state = { isModalVisible: false }, action) {
  switch (action.type) {
    case SHOW_ERROR: {
      return {
        ...state,
        ...action.payload,
        isModalVisible: checkBooleanInPayload(action, 'isModalVisible', true),
      };
    }

    case HIDE_ERROR: {
      return {
        ...state,
        ...action.payload,
        isModalVisible: checkBooleanInPayload(action, 'isModalVisible', false),
      };
    }
    default:
      return state;
  }
}

export default errorReducer;
