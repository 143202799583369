import { REQUEST_RECAPTCHA, USE_RECAPTCHA } from 'redux/actions/types';

const initialState = {
  requested: false,
};

function recaptchaReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_RECAPTCHA:
      return { requested: true };
    case USE_RECAPTCHA:
      return { requested: false };
    default:
      return state;
  }
}

export default recaptchaReducer;
