import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  selectMetadata,
  selectFavIcon,
  selectBrand,
} from 'redux/selectors/config';

const HeadComponent = () => {
  const metadata = useSelector(selectMetadata);
  const brand = useSelector(selectBrand);
  const favicon = useSelector(selectFavIcon);
  const helmetProps = {
    title: brand,
    meta: metadata,
    link: [
      {
        rel: 'shortcut icon',
        href: `${process.env.PUBLIC_URL}${favicon}`,
      },
    ],
  };
  return <Helmet {...helmetProps} />;
};

export default HeadComponent;
