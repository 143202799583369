import { Fragment } from 'react';
import PolicyCardStyled from './PolicyCardStyled';
import PropTypes from 'prop-types';
import { useTranslate } from 'hooks/translation-hooks';
import { event } from 'analytics';
import { useSelector } from 'react-redux';
import {
  dataDomainScriptIdSelector,
  showLegalDisclaimerSelector,
} from 'redux/selectors/config';

const GeneratePoliciesLinks = ({ policies }) => {
  const policiesKeys = Object.keys(policies).sort();
  const t = useTranslate();
  return policiesKeys.map((key, iterationIndex) => {
    const policyData = policies[key];
    const getAnalytics = () => {
      event(`${key}`, 'footer');
      if (policyData.onClick) {
        policyData.onClick();
      }
    };
    const PolicyLink = () => (
      <a
        href={policyData.url}
        rel="noopener noreferrer"
        target="_blank"
        data-testid={`policies_${key}`}
        key={key}
        onClick={getAnalytics}
      >
        {t(policyData.label)}
      </a>
    );

    return (
      <Fragment key={key}>
        {iterationIndex > 0 && <span className="separator">{' | '}</span>}
        <PolicyLink key={key} />
      </Fragment>
    );
  });
};

const PolicyCard = ({ policies, copyrightLabel }) => {
  const t = useTranslate();
  const showLegalDisclaimer = useSelector(showLegalDisclaimerSelector);
  const dataDomainScriptId = useSelector(dataDomainScriptIdSelector);
  const policiesLinks = policies;

  if (dataDomainScriptId) {
    policiesLinks['cookies_settings'] = {
      label: 'cookies_settings',
      onClick: () => {
        if (window?.OneTrust?.ToggleInfoDisplay) {
          window.OneTrust.ToggleInfoDisplay();
        }
      },
    };
  }

  return (
    <>
      <hr />
      <PolicyCardStyled>
        <GeneratePoliciesLinks policies={policiesLinks} />
        <br />
        <span data-testid="copyright-label">{copyrightLabel}</span>
        <br />
        {showLegalDisclaimer && (
          <span data-testid="footer-legal_disclaimer">
            {t('footer-legal_disclaimer')}
          </span>
        )}
      </PolicyCardStyled>
    </>
  );
};

PolicyCard.defaultProps = {
  policies: {},
  copyrightLabel: '',
};

PolicyCard.propTypes = {
  policies: PropTypes.shape({
    privacy_policy: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    cookies_policy: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
  copyrightLabel: PropTypes.string,
};

export default PolicyCard;
