import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { event } from 'analytics';
import { HeaderContainer, HeaderLayout, Logo } from './HeaderStyled';
import Toolbar from '../Toolbar';
import {
  selectBrandImage,
  selectLanguages,
  pagesWithoutHeaderSelector,
} from 'redux/selectors/config';
import { languageSelector } from 'redux/selectors/common';
import { addGtmDataLayer } from 'redux/actions/gtmActions';
import { goHome } from 'redux/actions/routeActions';

const Header = () => {
  const brandImage = useSelector(selectBrandImage);
  const pagesWithoutHeader = useSelector(pagesWithoutHeaderSelector);
  const shouldDisplayHeader = !useRouteMatch({
    path: pagesWithoutHeader,
    exact: true,
  });
  const languages = useSelector(selectLanguages);
  const language = useSelector(languageSelector);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedLanguage = languages.find(lang => lang.startsWith(language));
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
      dispatch(
        addGtmDataLayer({ language: selectedLanguage.replace(/-/g, '_') }),
      );
    }
  }, [dispatch, i18n, language, languages]);
  if (!shouldDisplayHeader) {
    return null;
  }
  const goToHome = () => {
    dispatch(goHome());
    event('home-logo-click', 'navigation');
  };
  return (
    <HeaderLayout>
      <HeaderContainer>
        <Logo
          src={brandImage}
          alt="Brand Logo AXA"
          onClick={goToHome}
          data-testid={'header-logo'}
        />
        <Toolbar />
      </HeaderContainer>
    </HeaderLayout>
  );
};

export default Header;
