import { Redirect } from 'react-router-dom';
import routes from 'Routes/routesConfig';

const RedirectionCapability = (Component, capability) => props => {
  const { NOT_FOUND } = routes;

  if (!capability) {
    return <Redirect to={NOT_FOUND} />;
  }

  return <Component {...props} />;
};

export default RedirectionCapability;
