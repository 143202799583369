import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import manageRootReducers from 'redux/reducers';
import { LOGGER } from 'constants/application';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import sagas from 'redux/sagas';
import { listenToWindowEvent } from 'utils/listener';
import { globalEvent } from 'redux/actions/applicationActions';

export const history = createBrowserHistory();

const LOGGER_ENV = JSON.stringify(process.env.REACT_APP_LOGGER);
const LOGGER_CONSTANT = JSON.stringify(LOGGER);

export const getMiddlewares = sagaMiddleware => {
  return [routerMiddleware(history), sagaMiddleware];
};

const persistConfig = {
  key: 'Charmander',
  storage: storage,
  whitelist: [
    'tutorial',
    'cookies',
    'privatePolicies',
    'notification',
    'step',
    'caseCreation',
    'chat',
    'carDetails',
  ],
};

const persistedReducer = persistReducer(
  persistConfig,
  manageRootReducers(history),
);

const startListeners = store => {
  listenToWindowEvent(store.dispatch, 'consent.onetrust', globalEvent);
};

let storeCached;

export const getCachedStore = () => {
  return storeCached;
};

const configure = initialState => {
  const composeEnhancers =
    (LOGGER_ENV === LOGGER_CONSTANT &&
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const sagaMiddleware = createSagaMiddleware();

  let middlewares = getMiddlewares(sagaMiddleware);

  if (LOGGER_ENV === LOGGER_CONSTANT) {
    middlewares = [...middlewares, logger];
  }
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      /* eslint-disable */
      const nextRootReducer = require('../reducers/index');
      /* eslint-enable */
      store.replaceReducer(nextRootReducer);
    });
  }
  startListeners(store);
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);
  storeCached = store;
  return { store, persistor, history };
};

const { store, persistor } = configure();

export const getStore = () => {
  return { store, persistor, history };
};

export default configure;
