const selectAlerts = state => state.alerts;

export const alertsSelector = state =>
  selectAlerts(state) && selectAlerts(state).alert;

export const selectSpecialAlertStatus = state =>
  selectAlerts(state) && selectAlerts(state).special;

export const selectBannerAlertStatus = state =>
  selectAlerts(state) && selectAlerts(state).banner;

export const selectSpecialAlertIsShownStatus = state =>
  selectAlerts(state) && selectAlerts(state).hasBeenSpecialActivated;

export const selectBannerAlertsIsShownStatus = state =>
  selectAlerts(state) && selectAlerts(state).hasBeenBannerActivated;
