import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguages } from 'redux/selectors/config';
import { dataSelector, languageSelector } from 'redux/selectors/common';
import { addGtmDataLayer } from 'redux/actions/gtmActions';
import { updateNotification } from 'redux/actions/currentNotificationActions';
import applicationActions from 'redux/actions/applicationActions';
import { event } from 'analytics';
const { changeLanguageReady } = applicationActions;

export const useLanguages = () => {
  const languages = useSelector(selectLanguages);
  const caseLanguage = useSelector(languageSelector);
  const caseOrder = useSelector(dataSelector);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(caseLanguage || i18n.language);
  const dispatch = useDispatch();
  useEffect(() => {
    const selectedLanguage = languages.find(lang =>
      lang.startsWith(caseLanguage),
    );
    if (selectedLanguage && selectedLanguage !== language) {
      setLanguage(selectedLanguage);
    }
  }, [caseLanguage, language, languages]);
  const handleButtonClick = lang => e => {
    e.stopPropagation();
    dispatch(addGtmDataLayer({ language: lang.replace(/-/g, '_') }));
    event('language_option_click', 'language_selection');
    i18n.changeLanguage(lang);
    setLanguage(lang);
    if (caseOrder.caseGuid) {
      dispatch(
        updateNotification({
          caseGuid: caseOrder.caseGuid,
          preferredLanguage: lang,
        }),
      );
    } else {
      dispatch(changeLanguageReady(lang));
    }
  };

  return { handleButtonClick, language, languages };
};
