import { call, put, select } from 'redux-saga/effects';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import { partnerSelector } from 'redux/selectors/config';
import getCase from 'api/case';
import { getCaseFail, getCaseSuccess } from 'redux/actions/caseActions';
import {
  addCaseGuidTutorial,
  toggleTutorial,
} from 'redux/actions/tutorialActions';
import { caseSelector } from 'redux/selectors/case';

const getMissionsWithReadFlag = (
  nextMissionOrders = [],
  previousMissionOrders = [],
) =>
  nextMissionOrders.map(currentMission => {
    const prevMission = previousMissionOrders.find(
      prevMission =>
        prevMission.missionOrderId === currentMission.missionOrderId,
    );
    const hasPrevMission = prevMission;
    const hasNewEventType =
      prevMission &&
      currentMission &&
      prevMission.eventTypeName !== currentMission.eventTypeName;

    if (!hasPrevMission || hasNewEventType) {
      return { ...currentMission, read: false };
    }

    return {
      ...currentMission,
      read: true,
    };
  });

const casesAdapter = (nextCase, previousCase) => {
  const { missionOrders: nextMissionOrders } =
    (nextCase && nextCase.embedded) || [];
  const { missionOrders: previousMissionOrders } =
    (previousCase && previousCase.embedded) || [];
  const missionOrdersWithReadFlag = getMissionsWithReadFlag(
    nextMissionOrders,
    previousMissionOrders,
  );

  return {
    ...nextCase,
    embedded: {
      ...nextCase.embedded,
      missionOrders: missionOrdersWithReadFlag,
    },
  };
};

export function* getCaseSaga({ payload: { id, caseId, caseReference } }) {
  try {
    const config_file = yield select(partnerSelector);
    const previousCase = yield select(caseSelector);
    yield put(startLoad());
    const response = yield call(getCase, {
      id,
      caseId,
      caseReference,
      config_file,
    });

    const { result, status } = response;
    const { partnerReference: partnerId } = (result && result.data) || result;
    if (status < 300 && config_file === partnerId) {
      const casesAdapted = casesAdapter(result, previousCase);
      if (caseReference) {
        yield put(addCaseGuidTutorial(casesAdapted.caseGuid));
        yield put(toggleTutorial());
      }
      yield put(getCaseSuccess(casesAdapted));
      return;
    }
    yield put(getCaseFail('Case not found'));
  } catch (error) {
    yield put(getCaseFail(error?.message || error));
  } finally {
    yield put(stopLoad());
  }
}
