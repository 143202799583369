import {
  GET_CASE_START,
  GET_CASE_FAIL,
  GET_CASE_SUCCESS,
  UPLOAD_PICTURE_SUCCESS,
} from 'redux/actions/types';

const initialState = {
  data: {},
  error: null,
};

function caseReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CASE_START:
      return {
        ...state,
        error: null,
      };
    case GET_CASE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_CASE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPLOAD_PICTURE_SUCCESS:
      const { data } = state;
      const { numberOfPicturesUploaded } = data;
      if (numberOfPicturesUploaded !== undefined) {
        data.numberOfPicturesUploaded = numberOfPicturesUploaded + 1;
      }
      return {
        ...state,
        data,
      };
    default:
      return state;
  }
}

export default caseReducer;
