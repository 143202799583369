import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import routeReducer from './routeReducer';
import historyReducer from './historyReducer';
import appointmentReducer from './appointmentReducer';
import errorReducer from './errorReducer';
import loadingReducer from './loadingReducer';
import currentNotificationReducer from './currentNotificationReducer';
import chatReducer from './chatReducer';
import NotificationReducer from './notificationReducer';
import missionReducer from './missionReducer';
import pictureReducer from './pictureReducer';
import applicationReducer from './applicationReducer';
import modalsReducer from './modalReducer';
import caseReducer from './caseReducer';
import caseCreationReducer from './caseCreationReducer';
import cameraReducer from './cameraReducer';
import tutorialReducer from './tutorialReducer';
import alertsReducer from './alertsReducer';
import recaptchaReducer from './recaptchaReducer';
import stepReducer from './stepReducer';
import privatePoliciesReducer from './privatePoliciesReducer';
import providerRouteReducer from './providerRouteReducer';
import analyticsReducer from './analyticsReducer';
import gtmReducer from './gtmReducer';
import locationReducer from './locationReducer';
import hereWithYouReducer from './hereWithYouReducer';
import optimizeReducer from './optimizeReducer';
import browserLocationReducer from './browserLocationReducer';
import carDetailsReducer from './carDetailsReducer';
import { History } from 'history';
import { ActionTypes } from 'redux/actions/action-utils';
import { ConfigurationState } from 'ts/types/config.types';
import { CaseCreationState } from 'ts/types/case-creation.types';
import { BrowSerLocationState } from 'ts/types/browser-location.types';
import { ChatState } from 'ts/types/chat.types';
import { CarDetailsState } from 'ts/types/car-details.types';
export interface ApplicationState {
  alerts: any;
  analytics: any;
  application: ConfigurationState;
  appointment: any;
  browserLocation: BrowSerLocationState;
  camera: any;
  carDetails: CarDetailsState;
  case: any;
  caseCreation: CaseCreationState;
  chat: ChatState;
  currentNotification: any;
  error: any;
  gtm: any;
  hwyData: any;
  history: any;
  isLoading: any;
  location: any;
  mission: any;
  modals: any;
  notification: any;
  optimize: any;
  pictures: any;
  privatePolicies: any;
  providerRoute: any;
  recaptcha: any;
  route: any;
  router: any;
  step: any;
  tutorial: any;
}

const appReducers = (history: History) =>
  combineReducers<ApplicationState>({
    alerts: alertsReducer,
    analytics: analyticsReducer,
    application: applicationReducer,
    appointment: appointmentReducer,
    browserLocation: browserLocationReducer,
    camera: cameraReducer,
    carDetails: carDetailsReducer,
    case: caseReducer,
    caseCreation: caseCreationReducer,
    chat: chatReducer,
    currentNotification: currentNotificationReducer,
    error: errorReducer,
    gtm: gtmReducer,
    hwyData: hereWithYouReducer,
    history: historyReducer,
    isLoading: loadingReducer,
    location: locationReducer,
    mission: missionReducer,
    modals: modalsReducer,
    notification: NotificationReducer,
    optimize: optimizeReducer,
    pictures: pictureReducer,
    privatePolicies: privatePoliciesReducer,
    providerRoute: providerRouteReducer,
    recaptcha: recaptchaReducer,
    route: routeReducer,
    router: connectRouter(history),
    step: stepReducer,
    tutorial: tutorialReducer,
  });

const manageRootReducers =
  (history: History) => (state: ApplicationState, action: ActionTypes) =>
    appReducers(history)(state, action);

export default manageRootReducers;
