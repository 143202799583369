/* eslint-disable no-unused-vars */
import { ChangeEventHandler } from 'react';

import {
  InputStyledProps,
  InputStyledSize,
} from '../InputStyled/InputStyled.types';

export type AutocompleteProps = InputStyledProps & {
  /**
   * Overload of the onChange to allow sending any type of value
   */
  onChange?: ChangeEventHandler<any>;
  /**
   * The options to show in the autocomplete
   */
  options: any[];
  /**
   * Function to trigger an external search
   */
  onSearch?: Function;
  /**
   * Function to render an option
   */
  getOptionLabel: Function;
  /**
   * The reference to attach to the input
   */
  ref?: any;
};

export type size = {
  fontSize: string;
  padding: string;
};

export interface ThemeProps {
  size: {
    [InputStyledSize.SMALL]: size;
    [InputStyledSize.LARGE]: size;
  };
}

export const AutocompleteBaseTheme: ThemeProps = {
  size: {
    [InputStyledSize.SMALL]: {
      fontSize: '16px',
      padding: '12px',
    },
    [InputStyledSize.LARGE]: {
      fontSize: '20px',
      padding: '12px',
    },
  },
};

export type AutocompleteOptionStyledProps = {
  size: InputStyledSize;
};
