import {
  CONTACT_REQUEST_START,
  CONTACT_REQUEST_SUCCESS,
  CONTACT_REQUEST_FAIL,
  CONTACT_US_FAIL,
  CONTACT_US_START,
  CONTACT_US_SUCCESS,
} from './types';

export const contactRequestStart = payload => ({
  type: CONTACT_REQUEST_START,
  ...payload,
});

export const contactRequestSuccess = payload => ({
  type: CONTACT_REQUEST_SUCCESS,
  payload,
});

export const contactRequestFail = error => ({
  type: CONTACT_REQUEST_FAIL,
  payload: error,
});

export const contactUsFail = payload => ({
  type: CONTACT_US_FAIL,
  payload,
});

export const contactUsStart = payload => ({
  type: CONTACT_US_START,
  payload,
});

export const contactUsSuccess = payload => ({
  type: CONTACT_US_SUCCESS,
  payload,
});
