import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { missionSelector } from 'redux/selectors/mission';
import { currentRouteSelector } from 'redux/selectors/route';
import {
  LinkItem,
  LoadPageLinksProps,
  Location,
  PictureUploadConfig,
  UseMenuProps,
} from './types';
import {
  selectMenuLinks,
  showCasePageSelector,
  enablePicturesSelector,
  maxNumberOfPicturesSelector,
} from 'redux/selectors/config';
import { caseSelector } from 'redux/selectors/case';
import routes from 'Routes/routesConfig';
import { insidePictureSectionSelector } from 'redux/selectors/picture';
import { dataSelector } from 'redux/selectors/common';
import { event } from 'analytics';
import { noop } from 'utils/global-utils';
import { MenuLink } from 'ts/types/config.types';
import { useRoutes } from 'hooks/routes-hooks';

const selectRoute = (pathname: string) => {
  if (pathname.includes('history-case')) {
    return routes.HISTORY_CASE;
  }
  if (pathname.includes('history')) {
    return routes.HISTORY;
  }
  if (pathname.includes('notification')) {
    return routes.NOTIFICATION;
  }
  return pathname;
};

const getLinks =
  ({ pathname, currentUrl }: Location) =>
  (item: LinkItem) =>
    item?.url !== pathname && item.url !== currentUrl;

const getPathMatch = () =>
  matchPath(window.location.pathname, {
    path: selectRoute(window.location.pathname),
  });

const checkPictureUploadDisabled = ({
  maxNumberOfPictures,
  data,
  isInsidePictureSection,
}: PictureUploadConfig) => {
  if (isInsidePictureSection) {
    return true;
  }
  if (
    data &&
    (data.numberOfPicturesUploaded || data.numberOfPicturesUploaded === 0)
  ) {
    const { numberOfPicturesUploaded } = data;
    return numberOfPicturesUploaded >= maxNumberOfPictures;
  }
  return true;
};

const loadPageLinks = ({
  isCaseOpening,
  links,
  route,
  shouldDisplayCasePage,
}: LoadPageLinksProps) => {
  let menuItems: LinkItem[] = [
    ...links.filter((link: MenuLink) => link.isMenuLink),
  ];

  if (route === routes.NOT_FOUND || isCaseOpening) {
    menuItems = menuItems.filter(link => link.url !== routes.CONTACT);
  } else if (shouldDisplayCasePage) {
    menuItems = [
      {
        key: 'history_page',
        url: routes.HISTORY_CASE,
        action: 'history_button-click',
      },
      ...menuItems,
    ];
  } else {
    menuItems = [
      {
        key: 'history_page',
        url: routes.HISTORY,
        action: 'history_button-click',
      },
      {
        key: 'current_notification',
        url: routes.NOTIFICATION,
        action: 'current_notification_button-click',
      },
      ...menuItems,
    ];
  }
  return menuItems;
};

const useMenu = ({ toggleMenu }: UseMenuProps) => {
  const dispatch = useDispatch();
  const mission = useSelector(missionSelector);
  const currentCase = useSelector(caseSelector);
  const links: MenuLink[] = useSelector(selectMenuLinks);
  const shouldDisplayCasePage = useSelector(showCasePageSelector);
  const route = useSelector(currentRouteSelector);
  const location = useSelector(useLocation);
  const shouldEnablePictures = useSelector(enablePicturesSelector);
  const data = useSelector(dataSelector);
  const maxNumberOfPictures = useSelector(maxNumberOfPicturesSelector);
  const isInsidePictureSection = useSelector(insidePictureSectionSelector);

  const { caseGuid: caseGuidFromMission, missionOrderId } = mission;
  const { caseGuid: caseGuidFromCase } = currentCase;
  const caseGuid = caseGuidFromMission || caseGuidFromCase;

  const currentUrl = getPathMatch()?.url;
  const { currentPath } = useRoutes();
  const id = currentPath?.params?.id;

  const reference = (shouldDisplayCasePage ? caseGuid : missionOrderId) || id;

  const goToItem = (url: string, action: string) => () => {
    event(action, 'menu');
    toggleMenu();
    if (url.startsWith('/')) {
      dispatch(push(url));
      return;
    }
    if (url.startsWith('tel:')) {
      document.location.href = url;
      return;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const menuItems = loadPageLinks({
    links,
    route,
    shouldDisplayCasePage,
    isCaseOpening: !id,
  }).filter(getLinks({ currentUrl, ...location }));

  const isPictureUploadDisabled = checkPictureUploadDisabled({
    maxNumberOfPictures,
    isInsidePictureSection,
    data,
  });

  const uploadPicturesRoute = routes.UPLOAD_PICTURES.replace(':id', caseGuid);

  const redirection = isPictureUploadDisabled
    ? noop
    : goToItem(uploadPicturesRoute, 'photos_button-click');

  return {
    menuItems,
    route,
    shouldEnablePictures,
    isPictureUploadDisabled,
    redirection,
    caseGuid,
    goToItem,
    reference,
  };
};
export { useMenu };
