import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const HERE_WITH_YOU_LOCATION_URL =
  process.env.REACT_APP_HERE_WITH_YOU_LOCATION_URL;
const HERE_WITH_YOU_MAP_ACCESS_URL =
  process.env.REACT_APP_HERE_WITH_YOU_MAP_ACCESS_URL;

export const getMapAccess = async ({ trackingReference }) => {
  const rs = await fetchApi(
    `${HERE_WITH_YOU_MAP_ACCESS_URL}/${trackingReference}`,
    {
      method: 'GET',
      headers: {},
    },
  );
  const data = getProps(rs, 'json') && (await rs.json());
  return { status: rs.status, ok: rs.ok, result: data };
};

export const getLocations = async ({
  trackingIdentifier,
  hereWithYouToken,
}) => {
  const rs = await fetchApi(
    `${HERE_WITH_YOU_LOCATION_URL}/${trackingIdentifier}`,
    {
      headers: {},
      withCredentials: true,
      bearerToken: hereWithYouToken,
      method: 'GET',
    },
  );
  const data = getProps(rs, 'json') && (await rs.json());
  return { status: rs.status, ok: rs.ok, result: data };
};

const defaultHereWithYouActions = { getMapAccess, getLocations };

export default defaultHereWithYouActions;
