import { FC } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../theme/theme';

import {
  ButtonBaseTheme,
  ButtonProps,
  ButtonSize,
  ButtonStyledProps,
  ButtonTypes,
  getColorProps,
  ButtonColorDictionary,
} from './Button.types';

const colorDictionary: ButtonColorDictionary = {
  [ButtonTypes.PRIMARY]: {
    background: 'primaryColor',
    color: 'onPrimaryColor',
    dark: 'darkPrimaryColor',
  },
  [ButtonTypes.SECONDARY]: {
    background: 'secondaryColor',
    color: 'onSecondaryColor',
    dark: 'darkSecondaryColor',
  },
};

const getColor = ({ buttonType, theme }: getColorProps): string =>
  theme?.[colorDictionary[buttonType].color]
    ? theme?.[colorDictionary[buttonType].color]
    : ButtonBaseTheme.palette[buttonType].color;

const getBackground = ({ buttonType, theme }: getColorProps): string =>
  theme?.[colorDictionary[buttonType].background]
    ? theme?.[colorDictionary[buttonType].background]
    : ButtonBaseTheme.palette[buttonType].background;

const getDarkColor = ({ buttonType, theme }: getColorProps): string =>
  theme?.[colorDictionary[buttonType].dark]
    ? theme?.[colorDictionary[buttonType].dark]
    : ButtonBaseTheme.palette[buttonType].dark;

const ButtonStyled = styled.button<ButtonStyledProps>`
  font-family: ${({ theme }) => theme.fontFamily || 'Source Sans Pro'};
  margin: 16px 0;
  cursor: pointer;
  font-style: normal;
  font-size: ${({ size }) => ButtonBaseTheme.size[size].fontSize};
  font-weight: 700;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: ${({ size }) => ButtonBaseTheme.size[size].padding};
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;
  border: none;
  border-bottom: solid 2px;
  background-color: ${getBackground};
  color: ${getColor};
  border-bottom: 2px solid ${getDarkColor};

  :disabled {
    cursor: not-allowed;
    border: none;
    border-bottom: solid 2px
      ${({ theme }) =>
        theme.colorButtonDisabled || defaultTheme.colorButtonDisabled};
    background-color: ${({ theme }) =>
      theme.backgroundColorButtonDisabled ||
      defaultTheme.backGroundColorButtonDisabled};
    color: ${({ theme }) =>
      theme.onColorButtonDisabled || defaultTheme.onColorButtonDisabled};
  }
`;

const ButtonOutlineStyled = styled(ButtonStyled)`
  border: solid 2px ${({ theme }) => theme?.primaryColor};
  border-bottom: solid 2px ${({ theme }) => theme?.primaryColor};
  background-color: transparent;
  color: ${({ theme }) => theme?.primaryColor};
  width: 312px;
`;

/**
 * Primary UI component for user interaction
 */
const Button: FC<ButtonProps> = ({
  buttonType = ButtonTypes.PRIMARY,
  size = ButtonSize.MEDIUM,
  children,
  onClick,
  dataTestId,
  type = 'button',
  variant = 'default',
  ...props
}) => {
  const buttonProps = {
    'data-testid': dataTestId,
    type: type,
    buttonType: buttonType as ButtonTypes,
    onClick: onClick,
    size: size,
    variant: variant,
    ...props,
  };
  const isOutlined = variant === 'outlined';
  return isOutlined ? (
    <ButtonOutlineStyled {...buttonProps}>{children}</ButtonOutlineStyled>
  ) : (
    <ButtonStyled {...buttonProps}>{children}</ButtonStyled>
  );
};

export default Button;
