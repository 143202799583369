import styled from 'styled-components';

export const HeaderLayout = styled.div`
  width: 100%;
  z-index: 1;
`;

export const HeaderContainer = styled.header`
  z-index: 2;
  align-items: center;
  background: ${({ theme }) => theme.headerColor};
  object-fit: contain;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  padding: ${({ theme }) => theme.headerPadding || '12.5px 15px'};
`;

export const Logo = styled.img`
  height: ${({ theme }) => theme.iconHeight || '36px'};
  max-width: ${({ theme }) => theme.iconWidth || '70px'};
  object-fit: contain;
  image-rendering: -webkit-optimize-contrast;
`;
