import fetchApi from 'utils/api-utils';
import { getProps } from 'utils/global-utils';
import { CarDetailsAPiCallParams } from 'ts/types/car-details.types';

const SYNERGY_BACKEND_URL = process.env.REACT_APP_SYNERGY_BACKEND_URL;

export const getCarDetails = async ({
  tenantId,
  moveVersion,
  sessionToken,
  plateNumber,
  partnerReference,
  numberOfRetries = 0,
  timeout = 7,
}: CarDetailsAPiCallParams) =>
  fetchApi(`${SYNERGY_BACKEND_URL}/v2/getPlateInformation`, {
    method: 'GET',
    params: {
      registration_number: plateNumber,
      partner_reference: partnerReference,
      number_of_retries: `${numberOfRetries}`,
      timeout: `${timeout}`,
    },
    headers: {
      Accept: 'application/json; charset=utf-8',
      'x-env': process.env.REACT_APP_ABODE_ENV,
      'x-token': sessionToken,
      'x-tenant-id': tenantId,
      'x-provider': moveVersion,
      'x-requested-by': 'synergy',
    } as any,
  }).then(async rs => {
    const data = rs.status !== 404 && getProps(rs, 'json') && (await rs.json());
    return { status: rs.status, ok: rs.ok, result: data };
  });

export default getCarDetails;
