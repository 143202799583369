import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const EVENT_URL = process.env.REACT_APP_EVENT_URL;

export const deleteEvent = async ({ id, type }) =>
  await fetchApi(EVENT_URL, {
    params: {
      id,
      type,
    },
    method: 'DELETE',
  }).then(async rs => {
    const data = getProps(rs, 'json') && (await rs.json());
    return { status: rs.status, ok: rs.ok, result: data };
  });

const defaultEventActions = { deleteEvent };

export default defaultEventActions;
