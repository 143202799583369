import {
  ADD_GTM_DATALAYER,
  ADD_GTM_DATALAYER_DONE,
  ADD_GTM_OPEN_CASE_DATALAYER,
  UPDATE_GTM,
  REMOVE_MISSION_DATA_FROM_GTM,
  RESET_OPEN_CASE_DATA_FROM_GTM,
  ADD_GTM_PAGEVIEW,
  ADD_GTM_MISSION_PAGEVIEW,
  ADD_WAITING_PAGEVIEW_GTM,
  REMOVE_WAITING_PAGEVIEW_GTM,
  UPDATE_LAST_PAGEVIEW_GTM,
  ADD_GTM_OPEN_CASE_PAGEVIEW,
} from 'redux/actions/types';

export const addGtmDataLayer = params => ({
  type: ADD_GTM_DATALAYER,
  payload: params,
});

export const addGtmDataLayerDone = () => ({
  type: ADD_GTM_DATALAYER_DONE,
});

export const addGtmOpenCaseDataLayer = params => ({
  type: ADD_GTM_OPEN_CASE_DATALAYER,
  payload: params,
});

export const updateGtmDataLayer = params => ({
  type: UPDATE_GTM,
  payload: params,
});

export const removeMissionDataFromGtm = () => ({
  type: REMOVE_MISSION_DATA_FROM_GTM,
});

export const resetOpenCaseDataFromGtm = () => ({
  type: RESET_OPEN_CASE_DATA_FROM_GTM,
});

export const addGtmPageView = params => ({
  type: ADD_GTM_PAGEVIEW,
  payload: params,
});

export const addGtmMissionPageView = params => ({
  type: ADD_GTM_MISSION_PAGEVIEW,
  payload: params,
});

export const addGtmOpenCasePageView = params => ({
  type: ADD_GTM_OPEN_CASE_PAGEVIEW,
  payload: params,
});

export const addWaitingPageView = params => ({
  type: ADD_WAITING_PAGEVIEW_GTM,
  payload: params,
});

export const removeWaitingPageView = () => ({
  type: REMOVE_WAITING_PAGEVIEW_GTM,
});

export const updateGtmLastPageView = params => ({
  type: UPDATE_LAST_PAGEVIEW_GTM,
  payload: params,
});
