import fetchApi from 'utils/api-utils';
import { cleanObject } from 'utils/global-utils';

const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL;

export const updateCurrentNotification = async ({
  missionOrderId,
  deviceId,
  caseGuid,
  customerNotificationOption,
  preferredLanguage,
}) => {
  const rs = await fetchApi(NOTIFICATION_URL, {
    method: 'PUT',
    body: cleanObject({
      missionOrderId,
      deviceId,
      caseGuid,
      customerNotificationOption,
      preferredLanguage,
    }),
  });
  return { status: rs.status, ok: rs.ok };
};

export default updateCurrentNotification;
