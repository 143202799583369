import { put, select } from 'redux-saga/effects';
import { addGtmOpenCaseDataLayer } from 'redux/actions/gtmActions';
import {
  currentLanguageSelector,
  decisionTreeSelector,
} from 'redux/selectors/config';
import {
  decisionTreeSelector as currentDecisionTreeSelector,
  isPropertyOwnerSelector,
} from 'redux/selectors/caseCreation';

export function* updateCaseSaga({ payload }) {
  const { isPropertyOwner: isOwner } = payload.caseCreationData;
  if (isOwner !== undefined) {
    const isOwnerGTMData = yield getIsOwnerGTMData(isOwner);
    yield put(addGtmOpenCaseDataLayer(isOwnerGTMData));
  }
}

export function* updateCaseDTSaga({ payload }) {
  const { decisionTree } = payload.caseCreationData;
  if (decisionTree) {
    const decisionTreeGTMData = yield getDecisionTreeGTMData(decisionTree);
    yield put(addGtmOpenCaseDataLayer(decisionTreeGTMData));
  }
}

export function* resetOpenCaseGtmDataLayer() {
  const isOwner = yield select(isPropertyOwnerSelector);
  const decisionTree = yield select(currentDecisionTreeSelector);
  const decisionTreeGTMData = yield getDecisionTreeGTMData(decisionTree);
  const isOwnerGTMData = getIsOwnerGTMData(isOwner);
  yield put(
    addGtmOpenCaseDataLayer({
      ...isOwnerGTMData,
      ...decisionTreeGTMData,
      case_reference: undefined,
      hashed_case_id: undefined,
      funnel_step: 1,
    }),
  );
}

function* getDecisionTreeGTMData(decisionTree) {
  const {
    result: { dropout, shortDescription: incident_subcategory },
    optionPath,
  } = decisionTree;
  const language = yield select(currentLanguageSelector);
  const configDecisionTree = yield select(decisionTreeSelector(language));
  let incident_category, tree_questionnaire_step;
  if (optionPath.length && configDecisionTree) {
    incident_category = configDecisionTree.find(
      ({ id }) => id === optionPath[0],
    )?.text;
    tree_questionnaire_step = optionPath.length + 2;
  }
  const drop_off_types = dropout ? 'DropOff_DecisionTree' : '';
  return {
    drop_off_types,
    incident_subcategory,
    tree_questionnaire_step,
    incident_category,
  };
}

function getIsOwnerGTMData(isOwner) {
  const is_owner = isOwner ? 1 : 0;
  return { is_owner };
}
