import defaultTheme from '../../theme/theme';

import { ButtonSize, ButtonTypes, ThemeProps } from '../Button/Button.types';

export const GeolocatemeButtonBaseTheme: ThemeProps = {
  palette: {
    [ButtonTypes.PRIMARY]: {
      background: defaultTheme.onPrimaryColor,
      color: defaultTheme.primaryColor,
      dark: defaultTheme.darkPrimaryColor,
    },
    [ButtonTypes.SECONDARY]: {
      background: defaultTheme.onSecondaryColor,
      color: defaultTheme.secondaryColor,
      dark: defaultTheme.darkSecondaryColor,
    },
  },
  size: {
    [ButtonSize.SMALL]: {
      fontSize: '13px',
      padding: '7px',
    },
    [ButtonSize.MEDIUM]: {
      fontSize: '16px',
      padding: '10px',
    },
    [ButtonSize.LARGE]: {
      fontSize: '19px',
      padding: '13px',
    },
  },
};
