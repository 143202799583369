import { push } from 'connected-react-router';
import { fetchCurrentNotification } from 'redux/actions/currentNotificationActions';
import {
  successSubmitAppointment,
  failSubmitAppointment,
} from 'redux/actions/appointmentActions';
import { routes } from 'Routes/routesConfig';
import { addErrorAlert } from 'redux/actions/alertActions';
import rescheduleAppointmentApi from 'api/appointment/rescheduleAppointment';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import { call, put, select } from 'redux-saga/effects';
import { missionJobReferenceSelector } from 'redux/selectors/mission';

export function* appointmentSaga({
  payload: { schedules, missionOrderId, errorMessage },
}) {
  try {
    yield put(startLoad());
    const jobReference = yield select(missionJobReferenceSelector);
    const response = yield call(rescheduleAppointmentApi, {
      schedules,
      missionOrderId,
      jobReference,
    });
    const { result: schedulesSent, status } = response;
    if (status === 201 || status === 200) {
      yield put(fetchCurrentNotification({ id: missionOrderId }));
      yield put(successSubmitAppointment(schedulesSent));
      yield put(push(routes.NOTIFICATION.replace(':id', missionOrderId)));
      return;
    }
    yield put(addErrorAlert(errorMessage));
    yield put(failSubmitAppointment('Case not found'));
    return;
  } catch (error) {
    yield put(addErrorAlert(errorMessage));
    yield put(failSubmitAppointment(error.message || error));
    return;
  } finally {
    yield put(stopLoad());
  }
}
