import { call, put } from 'redux-saga/effects';
import {
  getHWYMapAccessFail,
  getHWYMapAccessSuccess,
} from 'redux/actions/hereWithYouActions';
import { getMapAccess } from 'api/hereWithYou';

export function* getHereWithYouMapAccessSaga(action) {
  try {
    const { trackingReference } = action.payload;
    const response = yield call(getMapAccess, { trackingReference });
    const { result, status } = response;
    if (status === 200) {
      yield put(getHWYMapAccessSuccess(result));
      return;
    }
    yield put(getHWYMapAccessFail(result));
  } catch (error) {
    yield put(getHWYMapAccessFail(error));
  }
}
