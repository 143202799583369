import LanguageItem from './LanguageItem';
import { LanguageContainer } from './LanguageStyled';
import { useLanguages } from './LanguageHook';

const LanguagesList = () => {
  const { handleButtonClick, language, languages } = useLanguages();

  const renderLanguagesText = languageItem => (
    <LanguageItem
      key={languageItem}
      language={language}
      handleOnClick={handleButtonClick(languageItem)}
      itemLanguage={languageItem}
    />
  );

  return (
    languages.length > 1 && (
      <LanguageContainer data-testid="menu-lang-container">
        {languages.map(renderLanguagesText)}
      </LanguageContainer>
    )
  );
};

export default LanguagesList;
