import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { dismissAlert } from 'redux/actions/alertActions';
import {
  AlertContainer,
  HeaderContainer,
  IconTitleContainer,
  AlertButton,
  TextContainer,
} from './GenericAlertStyled';
import XButton from 'components/Icons/XIcon';
import Markdown from 'components/Markdown';

const GenericAlert = ({ children: icon, id, message, title, type }) => {
  const dispatch = useDispatch();
  const closeAlert = () => {
    dispatch(dismissAlert(id));
  };

  return (
    <AlertContainer data-testid="alert-container" type={type}>
      <HeaderContainer>
        <IconTitleContainer>
          {icon}
          <TextContainer>
            {title && <div>{title}</div>}
            {message && <Markdown text={message} />}
          </TextContainer>
        </IconTitleContainer>
        <AlertButton
          data-testid="close-error-x-button-action"
          type="button"
          onClick={closeAlert}
        >
          <XButton />
        </AlertButton>
      </HeaderContainer>
    </AlertContainer>
  );
};

GenericAlert.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
};

GenericAlert.defaultProps = {
  id: '',
  message: '',
};

export default GenericAlert;
