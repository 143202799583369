import { forwardRef } from 'react';
import styled from 'styled-components';
import {
  InputStyledBaseTheme,
  InputStyledProps,
  StyledComponentInput,
  InputStyledSize,
} from './InputStyled.types';
import defaultTheme from '../../theme/theme';

export const InputField = styled.input<StyledComponentInput>`
  width: 100%;
  height: ${({ inputSize }) => InputStyledBaseTheme.size[inputSize].height};
  padding: ${({ inputSize }) => InputStyledBaseTheme.size[inputSize].padding};
  font-size: ${({ inputSize }) =>
    InputStyledBaseTheme.size[inputSize].fontSize};
  border: solid 1px
    ${(props: StyledComponentInput) =>
      props.isError ? props.theme.errorAlertBorderColor : '#cccccc'};
  margin: 8px 0 0;
  background-color: #ffffff;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;

  &::placeholder {
    color: #999999;
  }

  color: #333333;
  border-radius: 4px;

  &:focus {
    outline: none;
    border: solid 1px
      ${(props: StyledComponentInput) =>
        props.isError
          ? props.theme.errorAlertBorderColor
          : props.theme.primaryColor || defaultTheme.primaryColor};
  }
`;

const InputStyled = forwardRef(
  (
    {
      dataTestId = 'input',
      autoComplete = 'on',
      isError = false,
      inputSize = InputStyledSize.SMALL,
      ...rest
    }: InputStyledProps,
    ref,
  ) => (
    <InputField
      {...ref}
      inputSize={inputSize}
      data-testid={dataTestId}
      isError={isError}
      {...rest}
    />
  ),
);

export default InputStyled;
