import {
  CREATE_EVENT_START,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAIL,
  DELETE_EVENT_START,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAIL,
  UPDATE_EVENT_START,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAIL,
} from './types';

export const createEventStart = payload => ({
  type: CREATE_EVENT_START,
  payload,
});

export const createEventSuccess = history => ({
  type: CREATE_EVENT_SUCCESS,
  payload: history,
});

export const createEventFail = error => ({
  type: CREATE_EVENT_FAIL,
  payload: error,
});

export const deleteEventStart = payload => ({
  type: DELETE_EVENT_START,
  payload,
});

export const deleteEventSuccess = history => ({
  type: DELETE_EVENT_SUCCESS,
  payload: history,
});

export const deleteEventFail = error => ({
  type: DELETE_EVENT_FAIL,
  payload: error,
});

export const updateEventSuccess = () => ({
  type: UPDATE_EVENT_SUCCESS,
});

export const updateEventFail = error => ({
  type: UPDATE_EVENT_FAIL,
  payload: error,
});

export const updateEventStart = payload => ({
  type: UPDATE_EVENT_START,
  payload,
});
