import {
  ModalStyled,
  Title,
  BodyContainer,
  Button,
  ButtonRight,
  CornerIcon,
  ModalViewPort,
} from './ModalStyled';
import StatusIcon from 'components/Icons/StatusIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import Markdown from 'components/Markdown';
import { useModal } from './useModal';

export const Modal = props => {
  const {
    customButtonLeft,
    customButtonRight,
    body,
    componentBody,
    modals,
    title,
    type,
    primaryButtonLabel,
    status,
    visible,
    secondaryButtonLabel,
    onClickFirstButton,
    createButtonToolbar,
    onCloseButton,
    onClickSecondButton,
    carryProps,
  } = useModal(props);
  const LeftButton = customButtonLeft || Button;
  const RightButton = customButtonRight || ButtonRight;
  if (!visible) {
    return null;
  }
  return (
    <ModalStyled {...carryProps} type={type} modals={modals}>
      <ModalViewPort>
        <CornerIcon
          data-testid="close-modal-button"
          onClick={onCloseButton || onClickSecondButton}
        >
          <CloseIcon testid="close-modal-icon" />
        </CornerIcon>
        <Title>
          <StatusIcon status={status} isFloating />
          <span>{title}</span>
        </Title>
        <BodyContainer>
          {body && <Markdown text={body} />}
          {componentBody}
        </BodyContainer>
        {createButtonToolbar({
          children: (
            <>
              {primaryButtonLabel && (
                <LeftButton
                  onClick={onClickFirstButton}
                  data-testid="primary-button-modal"
                >
                  {primaryButtonLabel}
                </LeftButton>
              )}
              {secondaryButtonLabel && (
                <RightButton
                  onClick={onClickSecondButton}
                  data-testid="secondary-button-modal"
                >
                  {secondaryButtonLabel}
                </RightButton>
              )}
            </>
          ),
        })}
      </ModalViewPort>
    </ModalStyled>
  );
};

export default Modal;
