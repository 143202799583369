import {
  UPDATE_NOTIFICATION_START,
  GET_CURRENT_NOTIFICATION_FAIL,
  GET_CURRENT_NOTIFICATION_SUCCESS,
  GET_CURRENT_NOTIFICATION_START,
  UPDATE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_SUCCESS,
  REMOVE_MISSION_DATA_FROM_GTM,
} from 'redux/actions/types';
import notificationOptions from 'constants/notificationOptions';

const initialState = {
  data: {
    id: null,
    jobTitle: '',
    jobDescription: '',
    customerNotificationOption: notificationOptions.DEFAULT,
    providerName: '',
    providerId: '',
    jobReference: '',
    description: '',
    moreInformation: '',
    date: '',
    type: '',
  },
  error: null,
};

function routeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_NOTIFICATION_START:
    case UPDATE_NOTIFICATION_START:
      return {
        ...state,
        error: null,
      };
    case GET_CURRENT_NOTIFICATION_SUCCESS:
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        error: null,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case GET_CURRENT_NOTIFICATION_FAIL:
    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case REMOVE_MISSION_DATA_FROM_GTM:
      return initialState;
    default:
      return state;
  }
}

export default routeReducer;
