import styled from 'styled-components';

const PolicyCardStyled = styled.div`
  background: ${({ theme }) => theme.footerBackgroundColor};
  color: ${({ theme }) => theme.footerColor};
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: normal;
  opacity: 1;
  padding: 16px 29px;
  text-align: left;
  width: 100%;

  .separator,
  a {
    opacity: ${({ theme }) => theme.footerLinkOpacity || '0.65'};
  }

  a {
    border-bottom: 0;
    color: inherit;
    font-size: 13px;
    line-height: 1.5;
    text-decoration: none;
    cursor: pointer;
  }

  button#ot-sdk-btn.ot-sdk-show-settings {
    color: ${({ theme }) => theme.footerColor};
    text-align: center;
    border: none;

    &:hover,
    &:focus {
      background: transparent;
      border: none;
      outline: none;
    }
  }
`;

export default PolicyCardStyled;
