import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const WaitingIcon = ({ title, theme, isFloating }) => (
  <Icon isFloating={isFloating}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <title>{title}</title>
      <circle
        xmlns="http://www.w3.org/2000/svg"
        cx="13"
        cy="13"
        r="13"
        fill={theme.warningColor}
        fillRule="nonzero"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="-4 -4 24 24"
      >
        <g fill={theme.onWarningColor} fillRule="evenodd">
          <path
            fillRule="nonzero"
            d="M8 1.33A6.67 6.67 0 1 0 14.67 8 6.66 6.66 0 0 0 8 1.33zm0 12A5.33 5.33 0 1 1 8 2.67a5.33 5.33 0 0 1 0 10.66z"
          />
          <path d="M8.33 4.67h-1v4l3.5 2.1.5-.82-3-1.78z" />
        </g>
      </svg>
    </svg>
  </Icon>
);

WaitingIcon.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  isFloating: PropTypes.bool,
};

WaitingIcon.defaultProps = {
  title: 'Waiting Icon',
  isFloating: false,
};

export default withTheme(WaitingIcon);
