import { snakeToCamelCase } from 'utils/global-utils';
import { Map, StringMap } from 'ts/types/common.types';
import { GetKeyName } from './prismic.types';

const isBooleanField = (name: string): boolean => {
  const prefixes = ['is_', 'should_'];
  return prefixes.some(prefix => name.startsWith(prefix));
};

const getKeyName = ({ shouldConvertToCamelCase, propName }: GetKeyName) =>
  shouldConvertToCamelCase ? snakeToCamelCase(propName) : propName;

export const clean = (obj: Map, shouldConvertToCamelCase: boolean) => {
  const cleanedObject: any = {};
  for (const propName in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, propName)) {
      let value = obj[propName];
      if (isBooleanField(propName)) {
        value = obj[propName] === 'true';
      }
      if (obj[propName] !== null && obj[propName] !== undefined) {
        cleanedObject[getKeyName({ shouldConvertToCamelCase, propName })] =
          value;
      }
    }
  }
  return cleanedObject;
};

export const getTheme = (data: Map) =>
  clean(
    data.theme?.find(({ slice_type }: Map) => slice_type === 'theme')
      ?.primary || {},
    true,
  );

export const getImages = (data: Map) =>
  clean(
    data.theme?.find(({ slice_type }: Map) => slice_type === 'images')
      ?.primary || {},
    false,
  );

export const castListToObject = (data: Map, key: string, reducer: any) => {
  const newObject = data[key]
    ?.map((object: Map) => clean(object, true))
    .reduce(reducer, {});
  if (newObject) {
    delete newObject['undefined'];
  }
  return newObject;
};

export const castPrismicObject = (data: any[]) => {
  let value = {};
  try {
    if (data?.length > 0 && data[0]?.text) {
      value = JSON.parse(data[0]?.text);
    }
  } catch {}
  return value;
};

export const castStepOrderSliceToList = (data: any[]) =>
  data?.map(slice =>
    slice.slice_type === 'step'
      ? slice.primary.link_text
      : slice.items.map((item: Map) => item.link_text),
  ) || [];

export const castCustomStepSlice = (data: any[]) => {
  return (
    data?.map(slice => ({
      ...slice.primary,
      inputs:
        slice.items[0][Object.keys(slice.items[0])[0]] === null
          ? []
          : slice.items.map(
              ({ input_name, input_type, pattern, required }: StringMap) =>
                clean(
                  {
                    input_name,
                    input_type,
                    pattern,
                    required,
                  },
                  false,
                ),
            ),
    })) || []
  );
};

export const castIdentificationToObject = (data: any[]) => {
  if (!data || !data.length || data[0]?.element === null) {
    return {};
  }
  const identification: Map = {
    usual: [],
    alternative: [],
  };
  data.forEach(
    ({
      element,
      element_pattern,
      is_alternative,
      should_display_tips,
    }: Map) => {
      const type = is_alternative ? 'alternative' : 'usual';
      identification[type].push({
        element: snakeToCamelCase(element),
        element_pattern,
        should_display_tips,
      });
    },
  );
  return identification;
};

export const castObjectToList = (data: string[]) =>
  data.map(data => Object.values(data)[0]);
