import { takeLatest } from 'redux-saga/effects';
import {
  GET_HWY_LOCATION_START,
  GET_HWY_MAP_ACCESS_START,
} from 'redux/actions/types';
import { getHereWithYouLocationSaga } from './hwy-location-saga';
import { getHereWithYouMapAccessSaga } from './hwy-map-access-saga';

export default function* hereWithYouSagas() {
  yield takeLatest(GET_HWY_LOCATION_START, getHereWithYouLocationSaga);
  yield takeLatest(GET_HWY_MAP_ACCESS_START, getHereWithYouMapAccessSaga);
}
