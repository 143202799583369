export const BusinessCategory = {
  MOTOR: 'bl_motor',
  HOME: 'bl_home',
};

export const TranslationDirectory = {
  MOTOR: 'synergy',
  HOME: 'caseOpening',
};

export const QueryParams = {
  PHONE_NUMBER: 'phonenumber',
};

export const DocsDirectory = {
  MOTOR: 'motor',
  HOME: 'home',
};
