import { providerToCountry } from 'mappers/providers';
import { DocsDirectory } from 'constants/synergy';
import { Map } from 'ts/types/common.types';

const checkPolicyUrl = (url: string | undefined) => {
  if (
    url &&
    (/(https?:[/|.|\w|\s|-]*)/.test(url) || /([/|.|\w|\s|-]*).pdf/.test(url))
  ) {
    return url;
  }
  return null;
};

const generatePolicyUrl = (
  provider: string,
  targetPolicy: string,
  partner: string | undefined,
  language: string,
  projectDocsPathName = DocsDirectory.HOME,
) => {
  const policyFileName = targetPolicy.split('_policy')[0];
  const countryByProvider = providerToCountry[provider].toLowerCase();

  return partner
    ? `/docs/${projectDocsPathName}/${countryByProvider}/${partner}/${language.toLowerCase()}/${policyFileName}.pdf`
    : `/docs/${projectDocsPathName}/${countryByProvider}/${language.toLowerCase()}/${policyFileName}.pdf`;
};

function getPolicies({
  prismicConfig,
  partner,
  language,
  provider,
  projectDocsPathName,
}: Map) {
  const { policies } = prismicConfig;

  return Object.keys(policies).reduce((acc, policyToMap) => {
    const { url, urlType, label } = policies[policyToMap];

    if (policyToMap) {
      const policyUrl =
        (urlType === 'manual' && checkPolicyUrl(url)) ||
        (urlType === 'partner_specific' &&
          generatePolicyUrl(
            provider,
            policyToMap,
            partner,
            language,
            projectDocsPathName,
          )) ||
        generatePolicyUrl(
          provider,
          policyToMap,
          undefined,
          language,
          projectDocsPathName,
        );

      if (policyUrl) {
        return {
          ...acc,
          [policyToMap]: {
            label: label,
            url: policyUrl,
          },
        };
      }
    }
    return acc;
  }, {});
}

export default getPolicies;
