import { put, select } from 'redux-saga/effects';
import { dataLayer, DATALAYER_NAME, pageview } from 'analytics';
import {
  gtmDataSelector,
  gtmLastPageViewSelector,
  gtmWaitingPageViewSelector,
} from 'redux/selectors/gtm';
import {
  addGtmDataLayerDone,
  addWaitingPageView,
  removeWaitingPageView,
  updateGtmDataLayer,
  updateGtmLastPageView,
} from 'redux/actions/gtmActions';
import { getCaseAndMissionDataFromCase } from '../config/gtm-saga-helper';
import { caseSelector } from 'redux/selectors/case';
import {
  customStepsSelector,
  selectLanguage,
  showCaseCreationPageSelector,
} from 'redux/selectors/config';
import { caseCreationEditModeSelector } from 'redux/selectors/caseCreation';
import { currentRouteSelector } from 'redux/selectors/route';
import { caseOpeningRoutes, commonRoutes } from 'Routes/routesConfig';
import { addGtmDataLayer as addGtmDataLayerAction } from 'redux/actions/gtmActions';
import routes from 'Routes/routesConfig';

const NEEDED_COMMON_DATA_LAYER_KEYS = [
  'business_line',
  'country',
  'global_digital_asset',
  'instance_environment',
  'journey_type',
  'language',
  'main_linked_system',
  'partner_id',
  'partner_name',
];
const NEEDED_CASE_DATA_LAYER_KEYS = [
  ...NEEDED_COMMON_DATA_LAYER_KEYS,
  'case_reference',
  'hashed_case_id',
];
const NEEDED_MISSION_DATA_LAYER_KEYS = [
  ...NEEDED_CASE_DATA_LAYER_KEYS,
  'incident_category',
  'incident_subcategory',
  'case_reference',
  'mission_numbers',
  'mission_id',
  'hashed_case_id',
  'provider_name',
  'funnel_step',
];
const NEEDED_OPEN_CASE_DATA_LAYER_KEYS = [
  ...NEEDED_COMMON_DATA_LAYER_KEYS,
  'journey_type',
];

function validDataLayer(currentData) {
  return NEEDED_CASE_DATA_LAYER_KEYS.every(key => currentData[key]);
}

function validMissionDataLayer(currentData) {
  return NEEDED_MISSION_DATA_LAYER_KEYS.every(key => currentData[key]);
}

function validOpenCaseDataLayer(currentData) {
  return NEEDED_OPEN_CASE_DATA_LAYER_KEYS.every(key => currentData[key]);
}

export function* addGtmDataLayer({ payload: dataLayerVariables }, validator) {
  if (!validator) {
    const currentLocation = yield select(currentRouteSelector);
    const showCaseOpening = yield select(showCaseCreationPageSelector);
    validator =
      showCaseOpening &&
      caseOpeningRoutes.filter(route => route.path === currentLocation).length
        ? validOpenCaseDataLayer
        : validDataLayer;
  }
  const currentGTMData = yield select(gtmDataSelector);
  const language = yield select(selectLanguage);
  const newGTMData = { language, ...currentGTMData, ...dataLayerVariables };
  const different =
    JSON.stringify(currentGTMData) !== JSON.stringify(newGTMData);
  if (validator(newGTMData) && different) {
    yield dataLayer(newGTMData, DATALAYER_NAME);
    yield sendWaitingPageViews();
  }
  yield put(updateGtmDataLayer(newGTMData));
  yield put(addGtmDataLayerDone());
}

export function* addCaseGtmDataLayer() {
  const caseOrder = yield select(caseSelector);
  const dataLayerVariables = getCaseAndMissionDataFromCase(caseOrder);
  yield addGtmDataLayer({ payload: dataLayerVariables });
}

export function* addOpenCaseGtmDataLayer(payload) {
  yield addGtmDataLayer(payload, validOpenCaseDataLayer);
}

export function* addGtmPageView({ payload }) {
  const customSteps = (yield select(customStepsSelector)) || [];
  const isCommonPage = commonRoutes.filter(
    route => route.path.split('/').pop() === payload.split('/').pop(),
  ).length;
  const isCaseOpening = [...caseOpeningRoutes, ...customSteps].filter(
    route => route.path === payload,
  ).length;
  if (isCommonPage) {
    yield addGtmCommonPageView(payload);
  } else if (isCaseOpening) {
    yield put(
      addGtmDataLayerAction({
        digital_business_process: 'open_request',
      }),
    );
    yield addGtmOpenCasePageView({ payload });
  } else {
    yield put(
      addGtmDataLayerAction({
        digital_business_process: 'mission_follow_up',
      }),
    );
    yield addGtmFollowMyCasePageView(payload);
  }
}

export function* addGtmMissionPageView({ payload }) {
  yield addGtmAbstractPageView(payload, validMissionDataLayer);
}

function* addGtmCommonPageView(payload) {
  payload = payload.split('/').pop();
  const validation = currentData =>
    validDataLayer(currentData) || validOpenCaseDataLayer(currentData);
  yield addGtmAbstractPageView(payload, validation);
}

function* addGtmFollowMyCasePageView(payload) {
  payload = payload.split('/').pop();
  yield addGtmAbstractPageView(payload, validDataLayer);
}

export function* addGtmOpenCasePageView({ payload }) {
  const isEditMode = yield select(caseCreationEditModeSelector);
  yield addGtmAbstractPageView(
    payload,
    validOpenCaseDataLayer,
    isEditMode && { page_type: 'edit_page' },
  );
}

function* addGtmAbstractPageView(path, validation, overwrite) {
  const lastPageView = yield select(gtmLastPageViewSelector);
  const different =
    path !== lastPageView || path === routes.INCIDENT_DESCRIPTION;
  if (different) {
    const currentGTMData = yield select(gtmDataSelector);
    if (validation(currentGTMData)) {
      yield pageview(path, overwrite);
    } else {
      yield put(addWaitingPageView({ path, overwrite }));
    }
    yield put(updateGtmLastPageView(path));
  }
}

function* sendWaitingPageViews() {
  const waitingPageViews = yield select(gtmWaitingPageViewSelector);
  waitingPageViews.forEach(({ path, overwrite }) => pageview(path, overwrite));
  yield put(removeWaitingPageView());
}
