import {
  RESET_HISTORY,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAIL,
} from 'redux/actions/types';

const initialState = {
  data: [],
  error: null,
};

function historyReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_HISTORY:
      return {
        ...state,
        data: [],
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default historyReducer;
