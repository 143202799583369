import { StepOrderType } from 'ts/types/config.types';

const checkUpPath = '/check/up';

const removeCheckUpToStepsOrder = (stepsOrder: StepOrderType) => {
  return stepsOrder
    .filter(step => step !== checkUpPath)
    .map(step => {
      if (typeof step === 'string') {
        return step;
      }
      return step.filter(subStep => subStep !== checkUpPath);
    });
};

export const getStepsAndSubSteps = (
  stepsOrder: StepOrderType,
  checkup: boolean | undefined,
) => {
  if (checkup) {
    return stepsOrder;
  } else {
    return removeCheckUpToStepsOrder([...stepsOrder]);
  }
};
