import {
  ADD_CHAT_MESSAGE,
  ADVANCE_CHAT_STATE,
  DISABLE_CHATBOT_NOTIFICATION,
  ENABLE_CHATBOT_NOTIFICATION,
  REPLACE_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE,
} from './types';
import { createAction, getActionList } from './action-utils';
import { ChatStream } from 'ts/types/chat.types';

const actions = {
  advanceChatState: () => createAction(ADVANCE_CHAT_STATE, {}),
  addChatMessage: (chatStream: ChatStream) =>
    createAction(ADD_CHAT_MESSAGE, { chatStream }),
  replaceChatMessage: (message: string) =>
    createAction(REPLACE_CHAT_MESSAGE, { message }),
  sendChatMessage: (message: string) =>
    createAction(SEND_CHAT_MESSAGE, { message }),
  disableChatBotNotification: () =>
    createAction(DISABLE_CHATBOT_NOTIFICATION, {}),
  enableChatBotNotification: () =>
    createAction(ENABLE_CHATBOT_NOTIFICATION, {}),
};

export default actions;

const actionList = getActionList(actions);

export type ChatActionType = typeof actionList[number];
