import { StringMap } from '../ts/types/common.types';

export const providerToCountry: StringMap = {
  its: 'ES',
  jl: 'GB',
  'ecare-uk': 'GB',
  concerto: 'FR',
  'ecare-fr': 'FR',
  'ecare-be': 'BE',
  'concerto-be': 'BE',
  'motor-ecare-fr': 'FR',
  'motor-sp': 'SP',
};
