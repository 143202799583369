import {
  DISABLE_NOTIFICATION_MODAL,
  ENABLE_NOTIFICATION_MODAL,
  FCM_SERVICE_INITIALIZED,
  GET_CURRENT_NOTIFICATION_FAIL,
  GET_CURRENT_NOTIFICATION_START,
  GET_CURRENT_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION_START,
  UPDATE_NOTIFICATION_SUCCESS,
} from './types';

export const fetchCurrentNotification = params => ({
  type: GET_CURRENT_NOTIFICATION_START,
  payload: params,
});

export const getCurrentNotificationSuccess = currentNotification => ({
  type: GET_CURRENT_NOTIFICATION_SUCCESS,
  payload: currentNotification,
});

export const getCurrentNotificationFail = error => ({
  type: GET_CURRENT_NOTIFICATION_FAIL,
  payload: error,
});

export const updateNotification = params => ({
  type: UPDATE_NOTIFICATION_START,
  payload: params,
});

export const updateNotificationSuccess = currentNotification => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: currentNotification,
});

export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
});

export const fcmServiceInitialized = payload => ({
  type: FCM_SERVICE_INITIALIZED,
  payload,
});

export const disableNotificationModal = () => ({
  type: DISABLE_NOTIFICATION_MODAL,
});

export const enableNotificationModal = () => ({
  type: ENABLE_NOTIFICATION_MODAL,
});
