import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from 'redux/actions/alertActions';
import { useTranslate } from 'hooks/translation-hooks';
import { event } from 'analytics';
import {
  OverlayStyled,
  PopupContainer,
  HeaderContainer,
  IconTitleContainer,
  Title,
  Button,
  ButtonContainer,
  MessageContainer,
} from './SpecialAlertStyled';
import AlertIcon from 'components/Icons/AlertIcon';
import { selectSpecialAlertStatus } from 'redux/selectors/alerts';
import Markdown from 'components/Markdown';

const SPECIAL_ALERT_KEY = 'special';

const SpecialAlert = () => {
  const dispatch = useDispatch();
  const t = useTranslate();

  const displaySpecialAlert = useSelector(selectSpecialAlertStatus);
  const closeSpecialPopup = () => {
    event('accept', 'special_event_popup');
    dispatch(toggleAlert(SPECIAL_ALERT_KEY));
  };

  if (!displaySpecialAlert) {
    return null;
  }

  return (
    <OverlayStyled id="special-alert-body" data-testid="special-alert-body">
      <PopupContainer data-testid="special-alert-container">
        <HeaderContainer>
          <IconTitleContainer>
            <AlertIcon />
            <Title data-testid="special-alert-title">
              {t('special_event_popup-title')}
            </Title>
          </IconTitleContainer>
        </HeaderContainer>
        <MessageContainer data-testid="special-alert-text">
          <Markdown text={t('special_event_popup-text_1')} />
        </MessageContainer>
        <ButtonContainer>
          <Button
            type="button"
            data-testid="close-special-alert-text-button-action"
            onClick={closeSpecialPopup}
          >
            {t('special_event_popup-button')}
          </Button>
        </ButtonContainer>
      </PopupContainer>
    </OverlayStyled>
  );
};
export default SpecialAlert;
