import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { useRouteMatch } from 'react-router-dom';
import { pagesWithoutFooterSelector } from 'redux/selectors/config';
import FooterStyled from './FooterStyled';
import PolicyCard from './PolicyCard';

import { useFooterHook } from './useFooter';
import { useTranslate } from 'hooks/translation-hooks';

const Footer = () => {
  const t = useTranslate();
  const { policies, goToItem, footerLinks, copyrightLabel } = useFooterHook();
  const pagesWithoutFooter = useSelector(pagesWithoutFooterSelector);
  const shouldDisplayFooter = !useRouteMatch({
    path: pagesWithoutFooter,
    exact: true,
  });

  if (!shouldDisplayFooter) {
    return null;
  }
  return (
    <FooterStyled
      as="footer"
      id="appFooter"
      itemScope
      data-testid="footer-links"
      itemType="https://pending.schema.org/termsOfService"
    >
      {footerLinks.map((item, key, links) => (
        <Fragment key={`footer-link-${item.key}`}>
          <button
            type="button"
            onClick={goToItem(item.url)}
            data-testid={`${item.key}-link`}
            key={item.key}
          >
            <span>{t(`global-${item.key.toLowerCase()}_link`)}</span>
          </button>
          {links.length - 1 !== key && <hr />}
        </Fragment>
      ))}
      <PolicyCard policies={policies} copyrightLabel={t(copyrightLabel)} />
    </FooterStyled>
  );
};

export default Footer;
