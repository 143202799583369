import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const OverlayStyled = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`;

export const PopupContainer = styled.div`
  border-radius: 8px;
  border-color: ${({ theme }) => theme.specialPopupBorderColor};
  width: 340px;
  padding: 14px 16px 14px 16px;
  z-index: 1;
  visibility: visible;
  bottom: 20px;
  position: relative;
  background-color: ${({ theme }) => theme.specialPopupBackgroundColor};
  -webkit-animation: ${fadeIn} 0.5s;
  animation: ${fadeIn} 0.5s;
`;

export const ButtonContainer = styled.div`
  text-align: end;
  margin-right: 10px;
`;

export const Button = styled.button`
  background: transparent;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.specialPopupDefaultColor};
  cursor: pointer;
`;

export const IconTitleContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextButton = styled.button`
  background: transparent;
  font-weight: bold;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-left: 17px;
  color: ${({ theme }) => theme.specialPopupDefaultColor};
`;

export const MessageContainer = styled.div`
  p {
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 15px;
    color: black;
  }
`;
