import { ToolbarContainer, ToolbarTitle } from './ToolbarStyled';
import BurgerMenuIcon from '../Icons/BurgerMenuIcon';
import CloseMenuIcon from '../Icons/CloseMenuIcon';
import Menu from '../Menu';
import { useToolbar } from './hooks/useToolbar';
import { useSelector } from 'react-redux';
import {
  selectShowDisplayBrandHeader,
  selectGlobalHeaderTitle,
} from 'redux/selectors/config';
import { useTranslate } from 'hooks/translation-hooks';

export const Toolbar = () => {
  const partnerHeaderTitle = useSelector(selectGlobalHeaderTitle);
  const shouldDisplayBrandHeader = useSelector(selectShowDisplayBrandHeader);
  const t = useTranslate();

  const { toggleMenu, displayMenu } = useToolbar('menu');
  return (
    <ToolbarContainer>
      {shouldDisplayBrandHeader && (
        <ToolbarTitle>
          {partnerHeaderTitle || t('global-header_title')}
        </ToolbarTitle>
      )}
      {!displayMenu ? (
        <BurgerMenuIcon
          dataAttributes={[{ key: 'testid', value: 'menu-opened' }]}
          onClick={toggleMenu}
        />
      ) : (
        <>
          <CloseMenuIcon
            dataAttributes={[{ key: 'testid', value: 'menu-closed' }]}
            onClick={toggleMenu}
          />
          <Menu id="menu" toggleMenu={toggleMenu} />
        </>
      )}
    </ToolbarContainer>
  );
};

export default Toolbar;
