import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';
import encodeRequestToHtml from 'utils/encoder';

const UPDATE_APPOINTMENT_URL = process.env.REACT_APP_UPDATE_APPOINTMENT_URL;

export const updateAppointment = async ({
  jobReference,
  missionOrderId,
  status,
  additional_information,
}) => {
  const rs = await fetchApi(UPDATE_APPOINTMENT_URL, {
    params: {
      jobReference,
      missionOrderId,
    },
    body: encodeRequestToHtml({
      status,
      additional_information,
    }),
    method: 'PUT',
  });
  const data = getProps(rs, 'json') && (await rs.json());
  return { status: rs.status, ok: rs.ok, result: data };
};

export default updateAppointment;
