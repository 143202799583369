import {
  MOVE_TO_NEXT_STEP,
  MOVE_TO_PREVIOUS_STEP,
  UPDATE_CURRENT_STEP,
  UPDATE_MAX_STEP,
} from './types';

export const moveToNextStep = () => ({
  type: MOVE_TO_NEXT_STEP,
});

export const moveToPreviousStep = () => ({
  type: MOVE_TO_PREVIOUS_STEP,
});

export const updateCurrentStep = payload => ({
  type: UPDATE_CURRENT_STEP,
  payload,
});

export const updateMaxStep = payload => ({
  type: UPDATE_MAX_STEP,
  payload,
});
