/* eslint-disable no-unused-vars */
import React, { MouseEventHandler } from 'react';
import defaultTheme from '../../theme/theme';

export enum ButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
}

export enum ButtonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export type ButtonStyledProps = {
  buttonType: ButtonTypes;
  size: ButtonSize;
  variant?: string;
};

type palette = {
  color: string;
  dark: string;
  background: string;
};

type size = {
  fontSize: string;
  padding: string;
};

export interface ThemeProps {
  palette: {
    [ButtonTypes.PRIMARY]: palette;
    [ButtonTypes.SECONDARY]: palette;
  };
  size: {
    [ButtonSize.SMALL]: size;
    [ButtonSize.MEDIUM]: size;
    [ButtonSize.LARGE]: size;
  };
}

export type ButtonColorDictionary = {
  [key in ButtonTypes]: palette;
};

export const ButtonBaseTheme: ThemeProps = {
  palette: {
    [ButtonTypes.PRIMARY]: {
      background: defaultTheme.primaryColor,
      color: defaultTheme.onPrimaryColor,
      dark: defaultTheme.darkPrimaryColor,
    },
    [ButtonTypes.SECONDARY]: {
      background: defaultTheme.secondaryColor,
      color: defaultTheme.onSecondaryColor,
      dark: defaultTheme.darkSecondaryColor,
    },
  },
  size: {
    [ButtonSize.SMALL]: {
      fontSize: '12px',
      padding: '10px 16px',
    },
    [ButtonSize.MEDIUM]: {
      fontSize: '14px',
      padding: '11px 20px',
    },
    [ButtonSize.LARGE]: {
      fontSize: '16px',
      padding: '12px 24px',
    },
  },
};

export interface getColorProps {
  buttonType: ButtonTypes;
  theme: any;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  buttonType?: ButtonTypes | keyof typeof ButtonTypes;
  /**
   * How large should the button be?
   */
  size?: ButtonSize;
  /**
   * Data test id
   */
  dataTestId?: string;
  /**
   * Optional click handler
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Button variant
   */
  variant?: string;
  /**
   * Button contents
   */
  children?: React.ReactNode;
}
