import styled from 'styled-components';
import { ColorTheme } from '../../types/ColorTheme.type';
import { Spacing } from '../../types/Spacing.type';
import { TextSize } from '../../types/TextSize.type';
import { LinkProps, IconProps } from './Link.types';

export const LinkStyled = styled.a`
  font-family: ${({ theme }) => theme.fontFamily || 'Source Sans Pro'};
  font-size: ${({ size }: LinkProps) => TextSize[size].fontSize};
  line-height: ${({ size }: LinkProps) => TextSize[size].lineHeight};
  margin: ${({
    marginBottom = 'none',
    marginLeft = 'none',
    marginRight = 'none',
    marginTop = 'none',
  }: LinkProps) =>
    `${Spacing[marginTop]} ${Spacing[marginRight]} ${Spacing[marginBottom]} ${Spacing[marginLeft]}`};
  font-weight: ${({ fontWeight }: LinkProps) => fontWeight};
  text-align: ${({ textAlign }: LinkProps) => textAlign};
  color: ${({ linkType = 'primary', theme }: LinkProps): string =>
    theme[`${linkType}Color`] || ColorTheme[linkType]?.color};
  cursor: pointer;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
`;

export const IconContainer = styled.span`
  ${({ iconPosition }: IconProps) =>
    iconPosition === 'left' ? 'margin-right: 8px;' : 'margin-left: 8px;'};
  display: flex;
  align-items: center;
`;
