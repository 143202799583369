import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {bottom: -70px;}
  to {bottom: 20px; }
`;

export const AlertContainer = styled.div`
  display: flex;
  flex: 1;
  border-radius: 8px;
  width: 340px;
  padding: 14px 16px 14px 16px;
  z-index: 1;
  visibility: visible;
  -webkit-animation: ${fadeIn} 1s;
  animation: ${fadeIn} 1s;
  bottom: 20px;
  position: relative;
`;

export const ButtonContainer = styled.div`
  text-align: end;
  margin-right: 10px;
`;

export const DismissButton = styled.button`
  background: transparent;
  font-weight: bold;
`;

export const IconTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const TextButton = styled.button`
  background: transparent;
  font-weight: bold;
`;

export const Title = styled.h4`
  font-weight: bold;
  margin-left: 17px;
`;

export const Message = styled.p`
  text-align: left;
  margin-top: 20px;
  margin-bottom: 15px;
`;
