import fetchApi from 'utils/api-utils';

const CONTACT_REQUEST_URL = process.env.REACT_APP_CONTACT_REQUEST_URL;

export const contactRequest = async ({
  case_guid,
  reason,
  description,
  preferred_touch_point,
}) => {
  const rs = await fetchApi(CONTACT_REQUEST_URL, {
    body: {
      case_guid,
      reason,
      description,
      preferred_touch_point,
    },
    method: 'POST',
  });
  return { status: rs.status, ok: rs.ok };
};

export default contactRequest;
