import { keyframes, createGlobalStyle } from 'styled-components';
import * as fonts from './fonts';
import { definedProps } from 'utils/random-utils';

const appearSlide = keyframes`
  from {
    top: -700px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
`;

const disappearSlide = keyframes`
  from {
    top: 0;
    opacity: 1;
  }
  to {
    top: -600px;
    opacity: 0;
  }
`;

export const GlobalStyles = createGlobalStyle`
  ${Object.keys(fonts).map(font => fonts[font])}

  *,
    *::after,
    *::before {
    box-sizing: border-box;
    font-family: ${props => props.theme.fontFamily}, sans-serif;
    border: 0;
    margin: 0;
    padding: 0;
  }

  ::selection {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.onPrimaryColor};
  }

  html,
  body {
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.onBackgroundColor};
    font-size: 16px;
    font-family: ${props => props.theme.fontFamily}, sans-serif;
    line-height: 21px;
    text-size-adjust: 100%;
    height: 100%;
  }

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Webkit browsers like Safari and Chrome */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border: 1px solid #ccc;
  }

  button {
    text-transform: uppercase;
  }

  button:disabled,
  button[disabled] {
    border-top-width: initial;
    border-right-width: initial;
    border-left-width: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    background-color: rgb(229, 229, 229);
    color: rgb(255, 255, 255);
    border-style: none none solid;
    border-image: initial;
    border-bottom: 2px solid rgb(155, 155, 155);
  }

  #root {
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .camera {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.85);
    animation: ${appearSlide} 0.7s linear forwards alternate;

    & > div {
      overflow: hidden;
      display: flex;
      flex: 1;
      align-items: center;
      background-color: ${({ theme }) => theme.darkColor};
      justify-content: center;
    }

    &.modal-closed {
      animation: ${disappearSlide} 0.7s linear forwards alternate;
    }
  }

  body > .camera:empty {
    overflow: hidden;
    display: none;
  }

  .camera.modal-closed {
    animation: ${disappearSlide} 0.7s linear forwards alternate;
  }

  div > .react-html5-camera-photo {
    position: static;
  }

  /* Modal */
  .modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10;
    display: flex;
    flex: 0.1;
    justify-content: center;
    background: rgba(0, 0, 0, 0.22);
    animation: ${appearSlide} 0.7s linear forwards alternate;
  }

  .ReactModal__Body--open {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  body > .modal:empty {
    overflow: hidden;
    display: none;
  }

  .modal > div {
    overflow: hidden;
    background-color: ${({ theme }) => theme.darkColor};
    box-shadow: 0 4px 2px -2px gray;
    width: 100%;
    padding: 20px 30px;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  .modal.modal-closed {
    animation: ${disappearSlide} 0.7s linear forwards alternate;
  }

  #modal-errors > div {
    background-color: ${({ theme }) => theme.errorColor};
  }

  /* Material UI */
  div{
    .MuiTypography-root{
      font-family: ${props => props.theme.fontFamily}, sans-serif;
    }

  .MuiPaper-root {
    max-width: 345px;
  }

  .MuiInputBase-root {
    background: white;
    border: 1px solid ${({ theme }) => theme.nonActiveColor};
    padding-left:10px;
    margin: 8px 0 16px 0;
    height: 40px;
    width: 60%;
    ::before, ::after {
      display: none;
    }
    :focus{
      border: 1px solid ${({ theme }) => theme.primaryColor};
    }
  }

  .MuiFormControl-marginNormal{
    margin: 0;
  }

    div[class^='Mui'], div[class*=' Mui']{
    font-family: ${props => props.theme.fontFamily}, sans-serif;
    }

    .MuiButtonBase-root.Mui-disabled{
      opacity: 0;
    }

    .MuiPickersToolbar-toolbar{
      background-color: ${({ theme }) => theme.primaryColor};
    }

    .MuiPickersDay-daySelected{
      background-color: ${({ theme }) => theme.primaryColor};
    }

    .MuiInputBase-input{
      font-family: ${props => props.theme.fontFamily}, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: 0.23px;

      &:focus {
        background: transparent;
      }
    }

    .MuiSvgIcon-root{
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  /* Animations */
  @keyframes animateLeft {
    0% {
      left: -300px;
      opacity: 0;
    }
    100% {
      left: 0;
      opacity: 1;
    }
  }
  @keyframes animateTop {
    0% {
      top: -150px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }

  p,
  h3,
  h2 {
    &.success {
      color: ${({ theme }) => theme.successColor};
    }

    &.error {
      color: ${({ theme }) => theme.errorColor};
    }

    &.important {
      color: ${({ theme }) => theme.primaryColor};
    }
  }

  .grecaptcha-badge {
    display: none !important;
  }
`;

export const getAppTheme = ({ theme }) => definedProps(theme);
