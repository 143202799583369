import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const HISTORY_URL = process.env.REACT_APP_HISTORY_URL;

export const getHistory = async ({ params }) => {
  return await fetchApi(HISTORY_URL, { params }).then(async rs => {
    const data = getProps(rs, 'json') && (await rs.json());
    return { status: rs.status, ok: rs.ok, result: data };
  });
};

export default getHistory;
