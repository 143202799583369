import styled from 'styled-components';
import {
  AlertContainer as GenericContainer,
  DismissButton,
} from '../GlobalAlertStyles';
import { AlertTypes } from 'constants/alerts';

export { HeaderContainer, IconTitleContainer } from '../GlobalAlertStyles';

export const AlertContainer = styled(GenericContainer)`
  justify-content: space-between;
  flex-direction: column;
  border: 1.3px solid
    ${({ type, theme }) =>
      type === AlertTypes.SUCCESS
        ? theme.successColor
        : theme.errorAlertBorderColor};
  background-color: ${({ theme }) => theme.backgroundAlertBorderColor};
  width: fit-content;
`;

export const TextContainer = styled.div`
  align-self: center;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.onBackgroundColor};
  margin-left: 4px;

  div:first-child {
    font-weight: 600;
  }
`;

export const AlertButton = styled(DismissButton)`
  color: ${({ theme }) => theme.onBackgroundColor};
`;
