import { put } from 'redux-saga/effects';
import { setBrowserLocation } from 'redux/actions/browserLocationActions';
import { getBrowserLocation } from 'utils/browser-location';

export function* browserLocationSaga() {
  try {
    const coordinates = yield getBrowserLocation();
    yield put(setBrowserLocation(coordinates));
  } catch (e) {
    console.error(e);
  }
}
