import { HIDE_MODAL, SHOW_MODAL } from './types';

export const showModal = payload => ({
  type: SHOW_MODAL,
  payload,
});

export const hideModal = payload => ({
  type: HIDE_MODAL,
  payload,
});
