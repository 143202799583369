import {
  UPDATE_GTM,
  REMOVE_MISSION_DATA_FROM_GTM,
  ADD_WAITING_PAGEVIEW_GTM,
  REMOVE_WAITING_PAGEVIEW_GTM,
  UPDATE_LAST_PAGEVIEW_GTM,
} from 'redux/actions/types';
const MISSION_KEYS = [
  'incident_category',
  'incident_subcategory',
  'case_reference',
  'mission_numbers',
  'mission_id',
  'hashed_case_id',
  'provider_name',
  'funnel_step',
];

function gtmReducer(state = { data: {}, pageViews: { waiting: [] } }, action) {
  const {
    data,
    pageViews: { last, waiting },
  } = state;
  switch (action.type) {
    case UPDATE_GTM:
      return { ...state, data: { ...state.data, ...action.payload } };
    case REMOVE_MISSION_DATA_FROM_GTM:
      const cleanState = Object.entries(data)
        .map(([key, value]) => [
          key,
          MISSION_KEYS.includes(key) ? undefined : value,
        ])
        .reduce((acc, current) => ({ ...acc, [current[0]]: current[1] }), {});
      return { ...state, data: cleanState };
    case UPDATE_LAST_PAGEVIEW_GTM:
      return { ...state, pageViews: { last: action.payload, waiting } };
    case ADD_WAITING_PAGEVIEW_GTM:
      return {
        ...state,
        pageViews: { last, waiting: [...waiting, action.payload] },
      };
    case REMOVE_WAITING_PAGEVIEW_GTM:
      return { ...state, pageViews: { last, waiting: [] } };
    default:
      return state;
  }
}

export default gtmReducer;
