export const RESET_GLOBAL_STATE = 'RESET_GLOBAL_STATE';

export const GET_HISTORY_START = 'GET_HISTORY_START';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAIL = 'GET_HISTORY_FAIL';
export const RESET_HISTORY = 'RESET_HISTORY';

export const GET_NOTIFICATION_START = 'GET_NOTIFICATION_START';

export const ENTER_PICTURE_SECTION = 'ENTER_PICTURE_SECTION';
export const EXIT_PICTURE_SECTION = 'EXIT_PICTURE_SECTION';

export const LOAD_START = 'LOAD_START';
export const FCM_SERVICE_INITIALIZED = 'FCM_SERVICE_INITIALIZED';
export const LOAD_STOP = 'LOAD_STOP';

export const GET_CURRENT_NOTIFICATION_START = 'GET_CURRENT_NOTIFICATION_START';
export const GET_CURRENT_NOTIFICATION_SUCCESS =
  'GET_CURRENT_NOTIFICATION_SUCCESS';
export const GET_CURRENT_NOTIFICATION_FAIL = 'GET_CURRENT_NOTIFICATION_FAIL';

export const UPDATE_NOTIFICATION_START = 'UPDATE_NOTIFICATION_START';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL';

export const CREATE_EVENT_START = 'CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'CREATE_EVENT_FAIL';

export const DELETE_EVENT_START = 'DELETE_EVENT_START';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'DELETE_EVENT_FAIL';

export const UPDATE_EVENT_START = 'UPDATE_EVENT_START';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const CREATE_PICTURE_START = 'CREATE_PICTURE_START';
export const CREATE_PICTURE_SUCCESS = 'CREATE_PICTURE_SUCCESS';
export const CREATE_PICTURE_FAIL = 'CREATE_PICTURE_FAIL';
export const REMOVE_PICTURE = 'REMOVE_PICTURE';
export const PREPARE_PICTURE = 'PREPARE_PICTURE';
export const PREPARE_PICTURE_SUCCESS = 'PREPARE_PICTURE_SUCCESS';
export const UPDATE_PREPARE_PICTURE = 'UPDATE_PREPARE_PICTURE';
export const PREPARE_PICTURE_FAILED = 'PREPARE_PICTURE_FAILED';
export const CLEAN_PICTURES = 'CLEAN_PICTURES';
export const RESET_PICTURES = 'RESET_PICTURES';
export const UPLOAD_PICTURE = 'UPLOAD_PICTURE';
export const UPLOAD_PICTURE_SUCCESS = 'UPLOAD_PICTURE_SUCCESS';
export const IS_PICTURE_LOADING = 'IS_PICTURE_LOADING';
export const MARK_PICTURE_TO_UPLOAD = 'MARK_PICTURE_TO_UPLOAD';
export const UPLOAD_PICTURE_TO_CMX = 'UPLOAD_PICTURE_TO_CMX';
export const UPLOAD_PICTURES = 'UPLOAD_PICTURES';
export const UPLOAD_PICTURES_TO_CMX = 'UPLOAD_PICTURES_TO_CMX';

export const GET_MISSION_START = 'GET_MISSION_START';
export const GET_MISSION_SUCCESS = 'GET_MISSION_SUCCESS';
export const GET_MISSION_FAIL = 'GET_MISSION_FAIL';

export const GET_CASE_START = 'GET_CASE_START';
export const GET_CASE_SUCCESS = 'GET_CASE_SUCCESS';
export const GET_CASE_FAIL = 'GET_CASE_FAIL';

export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';

export const SET_CONFIGURATION_START = 'SET_CONFIGURATION_START';
export const SET_CONFIGURATION_SUCCESS = 'SET_CONFIGURATION_SUCCESS';
export const SET_CONFIGURATION_FAIL = 'SET_CONFIGURATION_FAIL';
export const UPDATE_POLICIES_CONFIGURATION_SUCCESS =
  'UPDATE_POLICIES_CONFIGURATION_SUCCESS';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_CAMERA = 'SET_CAMERA';

export const SET_SCHEDULE = 'SET_SCHEDULE';
export const CLEAR_SCHEDULES = 'CLEAR_SCHEDULES';

export const START_SUBMIT_APPOINTMENT = 'START_SUBMIT_APPOINTMENT';
export const SUCCESS_SUBMIT_APPOINTMENT = 'SUCCESS_SUBMIT_APPOINTMENT';
export const FAIL_SUBMIT_APPOINTMENT = 'FAIL_SUBMIT_APPOINTMENT';

export const REQUEST_RECAPTCHA = 'REQUEST_RECAPTCHA';
export const SET_RECAPTCHA = 'SET_RECAPTCHA';
export const SET_RECAPTCHA_ERROR = 'SET_RECAPTCHA_ERROR';
export const USE_RECAPTCHA = 'USE_RECAPTCHA';

export const ADD_ALERT = 'ADD_ALERT';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const DISMISS_ALL_ALERTS = 'DISMISS_ALL_ALERTS';
export const TOGGLE_ALERT = 'TOGGLE_ALERT';

export const CONTACT_REQUEST_START = 'CONTACT_REQUEST_START';
export const CONTACT_REQUEST_SUCCESS = 'CONTACT_REQUEST_SUCCESS';
export const CONTACT_REQUEST_FAIL = 'CONTACT_REQUEST_FAIL';

export const ACCEPT_ANALYTICS = 'ACCEPT_ANALYTICS';
export const REFUSE_ANALYTICS = 'REFUSE_ANALYTICS';

export const ACCEPT_PRIVATE_POLICIES = 'ACCEPT_PRIVATE_POLICIES';

export const ANALYTICS_INITIATION_SUCCESS = 'ANALYTICS_INITIATION_SUCCESS';
export const ANALYTICS_DEACTIVATION_SUCCESS = 'ANALYTICS_DEACTIVATION_SUCCESS';

export const UPDATE_APP_MISSION_START = 'UPDATE_APP_MISSION_START';

export const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
export const UPDATE_MAX_STEP = 'UPDATE_MAX_STEP';
export const MOVE_TO_NEXT_STEP = 'MOVE_TO_NEXT_STEP';
export const MOVE_TO_PREVIOUS_STEP = 'MOVE_TO_PREVIOUS_STEP';

export const UPDATE_CASE_CREATION = 'UPDATE_CASE_CREATION';
export const UPDATE_CASE_CREATION_DECISION_TREE =
  'UPDATE_CASE_CREATION_DECISION_TREE';

export const REMOVE_CASE_CREATION = 'REMOVE_CASE_CREATION';

export const ADD_GTM_DATALAYER = 'ADD_GTM_DATALAYER';
export const ADD_GTM_DATALAYER_DONE = 'ADD_GTM_DATALAYER_DONE';
export const ADD_GTM_OPEN_CASE_DATALAYER = 'ADD_GTM_OPEN_CASE_DATALAYER';
export const ADD_GTM_MISSION_PAGEVIEW = 'ADD_GTM_MISSION_PAGEVIEW';
export const ADD_GTM_OPEN_CASE_PAGEVIEW = 'ADD_GTM_OPEN_CASE_PAGEVIEW';
export const ADD_GTM_PAGEVIEW = 'ADD_GTM_PAGEVIEW';
export const ADD_WAITING_PAGEVIEW_GTM = 'ADD_WAITING_PAGEVIEW_GTM';
export const REMOVE_MISSION_DATA_FROM_GTM = 'REMOVE_MISSION_DATA_FROM_GTM';
export const RESET_OPEN_CASE_DATA_FROM_GTM = 'RESET_OPEN_CASE_DATA_FROM_GTM';
export const REMOVE_WAITING_PAGEVIEW_GTM = 'REMOVE_WAITING_PAGEVIEW_GTM';
export const UPDATE_GTM = 'UPDATE_GTM';
export const UPDATE_LAST_PAGEVIEW_GTM = 'UPDATE_LAST_PAGEVIEW_GTM';
export const DISABLE_NOTIFICATION_MODAL = 'DISABLE_NOTIFICATION_MODAL';
export const ENABLE_NOTIFICATION_MODAL = 'ENABLE_NOTIFICATION_MODAL';
export const DISABLE_CHATBOT_NOTIFICATION = 'DISABLE_CHATBOT_NOTIFICATION';
export const ENABLE_CHATBOT_NOTIFICATION = 'ENABLE_CHATBOT_NOTIFICATION';
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const REPLACE_CHAT_MESSAGE = 'REPLACE_CHAT_MESSAGE';
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const ADVANCE_CHAT_STATE = 'ADVANCE_CHAT_STATE';

export const GET_LOCATION_START = 'GET_LOCATION_START';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const GET_PROVIDER_ROUTE_DURATION_START =
  'GET_PROVIDER_ROUTE_DURATION_START';
export const GET_PROVIDER_ROUTE_DURATION_SUCCESS =
  'GET_PROVIDER_ROUTE_DURATION_SUCCESS';
export const GET_PROVIDER_ROUTE_DURATION_FAIL =
  'GET_PROVIDER_ROUTE_DURATION_FAIL';

export const CONTACT_US_START = 'CONTACT_US_START';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL';

export const FOLLOW_MY_CASE_START = 'FOLLOW_MY_CASE_START';
export const FOLLOW_MY_CASE_SUCCESS = 'FOLLOW_MY_CASE_SUCCESS';
export const FOLLOW_MY_CASE_FAIL = 'FOLLOW_MY_CASE_FAIL';

export const CREATE_CASE_START = 'CREATE_CASE_START';
export const CREATE_CASE_SUCCESS = 'CREATE_CASE_SUCCESS';
export const CREATE_CASE_ENABLE_SKIP_IDENTIFICATION =
  'CREATE_CASE_SKIP_IDENTIFICATION';
export const CREATE_CASE_FAIL = 'CREATE_CASE_FAIL';
export const CREATE_CASE_RETRY = 'CREATE_CASE_RETRY';
export const CREATE_CASE_RETRY_RESET_COUNT = 'CREATE_CASE_RETRY_RESET_COUNT';
export const CREATE_CASE_RETRY_COUNT = 'CREATE_CASE_RETRY_COUNT';
export const CREATE_CASE_TOGGLE_EDIT_MODE = 'CREATE_CASE_TOGGLE_EDIT_MODE';
export const CREATE_CASE_INSTANT_DROPOFF_CLEAN =
  'CREATE_CASE_INSTANT_DROPOFF_CLEAN';
export const CREATE_CASE_TOGGLE_VISIBLE_DETAILS =
  'CREATE_CASE_TOGGLE_VISIBLE_DETAILS';
export const CREATE_SYNERGY_CASE = 'CREATE_SYNERGY_CASE';
export const LOAD_DECISION_TREE_START = 'LOAD_DECISION_TREE_START';
export const LOAD_DECISION_TREE_SUCCESS = 'LOAD_DECISION_TREE_SUCCESS';
export const LOAD_DECISION_TREE_FAIL = 'LOAD_DECISION_TREE_FAIL';

export const UPDATE_PASSENGERS = 'UPDATE_PASSENGERS';
export const SET_SKIP_CONTACT_DETAILS = 'SET_SKIP_CONTACT_DETAILS';

export const GLOBAL_EVENT = 'GLOBAL_EVENT';
export const GLOBAL_EVENT_CONSENT_ONETRUST = 'GLOBAL_EVENT_CONSENT_ONETRUST';
export const RETRY_LANGUAGE_SETUP = 'RETRY_LANGUAGE_SETUP';
export const CHANGE_LANGUAGE_START = 'CHANGE_LANGUAGE_START';
export const CHANGE_LANGUAGE_FAIL = 'CHANGE_LANGUAGE_FAIL';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_READY = 'CHANGE_LANGUAGE_READY';

export const GET_HWY_LOCATION_FAIL = 'GET_HWY_LOCATION_FAIL';
export const GET_HWY_LOCATION_START = 'GET_HWY_LOCATION_START';
export const GET_HWY_LOCATION_SUCCESS = 'GET_HWY_LOCATION_SUCCESS';
export const GET_HWY_MAP_ACCESS_FAIL = 'GET_HWY_MAP_ACCESS_FAIL';
export const GET_HWY_MAP_ACCESS_START = 'GET_HWY_MAP_ACCESS_START';
export const GET_HWY_MAP_ACCESS_SUCCESS = 'GET_HWY_MAP_ACCESS_SUCCESS';
export const OPTIMIZE_INIZIALIZED = 'OPTIMIZE_INIZIALIZED';
export const INITIALIZE_OPTIMIZE = 'INITIALIZE_OPTIMIZE';
export const GO_HOME = 'GO_HOME';
export const GET_BROWSER_LOCATION_START = 'GET_BROWSER_LOCATION_START';
export const SET_BROWSER_LOCATION = 'SET_BROWSER_LOCATION';

export const GET_PLATE = 'GET_PLATE';
export const GET_PLATE_UNNECESSARY = 'GET_PLATE_UNNECESSARY';
export const GET_PLATE_SUCCESS = 'GET_PLATE_SUCCESS';
export const GET_PLATE_FAIL = 'GET_PLATE_FAIL';
export const CLEAR_PLATE_READY = 'CLEAR_PLATE_READY';
