export const AlertTypes = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  INFORMATION: 'INFORMATION',
};

export const ConfirmableQuestions = {
  COOKIES: 'cookies',
  PERIODIC_VEHICLE_INSPECTION: 'periodicVehicleInspection',
  PRIVACY_POLICY: 'privacyPolicy',
  SPECIAL_ALERT: 'specialAlert',
};
