import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const CloseMenuIcon = ({ title, theme, onClick, dataAttributes }) => (
  <Icon onClick={onClick} dataAttributes={dataAttributes}>
    <svg
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 30 30"
    >
      <defs>
        <filter
          id="a"
          width="104.7%"
          height="104.5%"
          x="-2.5%"
          y="-1.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={theme.primaryColor}
        strokeLinecap="round"
        strokeWidth="2"
        filter="none"
        transform="translate(-280 -18)"
      >
        <path d="M285 21l6.667 6.667m6.666 6.666L305 41M285 41l20-20" />
      </g>
    </svg>
  </Icon>
);

CloseMenuIcon.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  dataAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  onClick: PropTypes.func.isRequired,
};

CloseMenuIcon.defaultProps = {
  title: 'Close Menu title',
  dataAttributes: [],
};

export default withTheme(CloseMenuIcon);
