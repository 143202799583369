import styled from 'styled-components';

export const LanguageContainer = styled.ul`
  padding: 15px;
  background-color: ${({ theme }) => theme.disabledColor};
`;

export const LanguageWorldContainer = styled.ul`
  position: absolute;
  top: 8px;
  padding: 15px;
  z-index: 2;
`;

export const LanguageBox = styled.ul`
  position: relative;
  top: 8px;
  padding: 0 8px;
  z-index: 2;
  box-shadow: 0 0 8px ${({ theme }) => theme.boxShadow};
  background: ${({ theme }) => theme.specialPopupBackgroundColor};
  display: flex;
  flex-direction: column;
`;

export const LanguageText = styled.span`
  color: ${({ theme, selected }) =>
    selected ? theme.primaryColor : theme.nonActiveBorderColor};
  font-weight: ${({ selected }) => (selected ? 900 : 600)};
`;

export const LanguageItem = styled.li`
  padding-left: 10px;
  padding-right: 14px;
  text-align: left;
  display: inline;
  border-bottom: 1px solid ${({ theme }) => theme.disabledColor};
  text-transform: uppercase;

  &:not(:first-child) {
    border-left: 2px solid ${({ theme }) => theme.nonActiveBorderColor};
  }

  span {
    margin-left: 5px;
  }
`;

export const LanguageWorldItem = styled.li`
  text-align: left;
  display: inline;
  position: relative;
  padding: 8px 0;

  &:not(:last-child):after {
    border-bottom: 1px solid ${({ theme }) => theme.nonActiveBorderColor};
    content: '';
    width: 16px;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:nth-child(2) {
    padding: 0 0 8px;
  }

  span {
  }
`;

export const PointyThing = styled.div`
  width: 10px;
  height: 10px;
  position: relative;
  overflow: hidden;
  top: -10px;
  left: -3px;

  &:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: ${({ theme }) => theme.specialPopupBackgroundColor};
    transform: rotate(45deg);
    top: 7.5px;
    left: 2.5px;
    box-shadow: -1px -1px 10px -2px ${({ theme }) => theme.boxShadow};
  }
`;
