const ClearIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9194 12.3999L6.5 7.98051L2.08058 12.3999L0.600078 10.9194L5.01949 6.5L0.600077 2.08059L2.08058 0.600082L6.5 5.0195L10.9194 0.600082L12.3999 2.08059L7.98051 6.5L12.3999 10.9194L10.9194 12.3999Z"
      fill="none"
    />
  </svg>
);

export default ClearIcon;
