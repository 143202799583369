import Modal from '../Modal';
import { CustomButtonRight } from '../Modal/ModalStyled';
import { useTranslate } from 'hooks/translation-hooks';
import { usePrivatePoliciesModal } from './usePrivatePoliciesModal';
import { BodyMarkdownContent } from './PrivatePoliciesModalStyled';

const PrivatePoliciesModal = () => {
  const t = useTranslate();
  const { accept, privatePoliciesModal, relevantData, shouldShowModal } =
    usePrivatePoliciesModal();

  if (!shouldShowModal || !privatePoliciesModal) {
    return null;
  }
  return (
    <Modal
      type="privatePolicies"
      buttonDirection="ROW"
      title={t('private-policies_popup-title')}
      componentBody={
        <BodyMarkdownContent
          text={t('private-policies_popup-markdown', relevantData)}
        />
      }
      secondaryButtonLabel={t('private-policies_popup-accept_button')}
      onClickSecondaryButton={accept}
      customButtonRight={CustomButtonRight}
      isVisible
    />
  );
};

export default PrivatePoliciesModal;
