import { SHOW_ERROR, HIDE_ERROR } from './types';

export const showError = payload => ({
  type: SHOW_ERROR,
  payload,
});

export const hideError = payload => ({
  type: HIDE_ERROR,
  payload,
});
