import dayjs from 'dayjs';
import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const UPDATE_APPOINTMENT_URL = process.env.REACT_APP_UPDATE_APPOINTMENT_URL;

export const getAuthProps = Authorization =>
  Authorization ? { withCredentials: true, credentials: 'include' } : {};

export const rescheduleAppointmentApi = async ({
  schedules,
  missionOrderId,
  jobReference,
}) => {
  return await fetchApi(UPDATE_APPOINTMENT_URL, {
    body: {
      status: 'reschedule',
      appointment_proposals: schedules.map(scheduleOption => ({
        start_at: dayjs(scheduleOption.start_at).toISOString(),
        end_at: dayjs(scheduleOption.end_at).toISOString(),
      })),
    },
    params: {
      missionOrderId,
      jobReference,
    },
    method: 'PUT',
  }).then(async rs => {
    const data = getProps(rs, 'json') && (await rs.json());
    return { status: rs.status, ok: rs.ok, result: data };
  });
};

export default rescheduleAppointmentApi;
