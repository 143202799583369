import {
  CREATE_CASE_FAIL,
  CREATE_CASE_INSTANT_DROPOFF_CLEAN,
  CREATE_CASE_RETRY,
  CREATE_CASE_RETRY_COUNT,
  CREATE_CASE_RETRY_RESET_COUNT,
  CREATE_CASE_START,
  CREATE_CASE_SUCCESS,
  CREATE_CASE_TOGGLE_EDIT_MODE,
  CREATE_CASE_TOGGLE_VISIBLE_DETAILS,
  CREATE_SYNERGY_CASE,
  LOAD_DECISION_TREE_FAIL,
  LOAD_DECISION_TREE_START,
  UPDATE_CASE_CREATION,
  UPDATE_CASE_CREATION_DECISION_TREE,
  REMOVE_CASE_CREATION,
  CREATE_CASE_ENABLE_SKIP_IDENTIFICATION,
  UPDATE_PASSENGERS,
  SET_SKIP_CONTACT_DETAILS,
} from './types';
import { createAction, getActionList } from './action-utils';
import { Configuration } from 'ts/types/config.types';
import {
  CaseCreationDataModel,
  CaseCreationDTDataModel,
  CaseErrorResponseModel,
  CaseSuccessResponseModel,
  PassengersModel,
} from './caseCreationActions.types';

const actions = {
  removeCaseCreation: (propKey: keyof CaseCreationDataModel) =>
    createAction(REMOVE_CASE_CREATION, { propKey }),
  updateCaseCreation: (caseCreationData: Partial<CaseCreationDataModel>) =>
    createAction(UPDATE_CASE_CREATION, { caseCreationData }),
  updateDecisionTree: (caseCreationData: CaseCreationDTDataModel) =>
    createAction(UPDATE_CASE_CREATION_DECISION_TREE, { caseCreationData }),
  createCaseStart: (caseIdentifier: any) =>
    createAction(CREATE_CASE_START, { caseIdentifier }),
  createCaseSuccess: (caseData: CaseSuccessResponseModel) =>
    createAction(CREATE_CASE_SUCCESS, { caseData }),
  createCaseFail: (error: CaseErrorResponseModel) =>
    createAction(CREATE_CASE_FAIL, { error }),
  createSynergyCase: () => createAction(CREATE_SYNERGY_CASE, {}),
  retryCreateCaseStart: (configuration: Configuration) =>
    createAction(CREATE_CASE_RETRY, { configuration }),
  retryCreateCaseResetCount: (configuration: Configuration) =>
    createAction(CREATE_CASE_RETRY_RESET_COUNT, { configuration }),
  retryCreateCaseCount: () => createAction(CREATE_CASE_RETRY_COUNT, {}),
  toggleCreateCaseIsEditMode: () =>
    createAction(CREATE_CASE_TOGGLE_EDIT_MODE, {}),
  toggleCreateCaseVisibleDetails: () =>
    createAction(CREATE_CASE_TOGGLE_VISIBLE_DETAILS, {}),
  cleanInstantDropOffData: () =>
    createAction(CREATE_CASE_INSTANT_DROPOFF_CLEAN, {}),
  loadDecisionTree: () => createAction(LOAD_DECISION_TREE_START, {}),
  loadDecisionTreeFail: () => createAction(LOAD_DECISION_TREE_FAIL, {}),
  enableSkipIdentification: () =>
    createAction(CREATE_CASE_ENABLE_SKIP_IDENTIFICATION, {}),
  updatePassengers: (passengersData: PassengersModel) =>
    createAction(UPDATE_PASSENGERS, { passengersData }),
  setSkipContactDetails: () => createAction(SET_SKIP_CONTACT_DETAILS, {}),
};

export default actions;

const actionList = getActionList(actions);

export type CaseCreationActionType = typeof actionList[number];
