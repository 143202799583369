import { FullLocation, Coordinates } from './BingMap.types';

class BingMapApi {
  map: any;
  searchManager: any;

  constructor(map: any, searchManager: any) {
    this.map = map;
    this.searchManager = searchManager;
  }

  geocode(query: string): Promise<FullLocation[]> {
    if (!query) {
      return Promise.resolve([]);
    }
    return new Promise((resolve, reject) => {
      const searchRequest = {
        where: query,
        callback: function (res: any) {
          if (res) {
            const transformedResult = res.results.reduce(
              (result: FullLocation[], item: any) => {
                const transformedItem: FullLocation = {
                  address: {
                    formattedAddress: item.address.formattedAddress,
                  },
                  coordinates: {
                    latitude: item.location.latitude,
                    longitude: item.location.longitude,
                  },
                };
                return [...result, transformedItem];
              },
              [],
            );
            resolve(transformedResult);
          }
          reject(new Error('Geocode call failed'));
        },
        errorCallback: reject,
      };
      this.searchManager.geocode(searchRequest);
    });
  }

  reverseGeocode(location: Coordinates): Promise<FullLocation> {
    return new Promise((resolve, reject) => {
      const searchRequest = {
        location,
        includeCountryIso2: true,
        callback: function (res: any) {
          if (res) {
            resolve({
              address: {
                formattedAddress: res.name,
                country: res?.address?.countryRegionISO2,
                postalCode: res?.address?.postalCode,
                locality: res?.address?.locality,
              },
              coordinates: location,
            });
          }
          reject(new Error('Reverse geocode call failed'));
        },
        errorCallback: reject,
      };
      this.searchManager.reverseGeocode(searchRequest);
    });
  }

  center(location: Coordinates | undefined | null): void {
    if (!location) {
      return;
    }
    this.map.setView({
      mapTypeId: Microsoft.Maps.MapTypeId.road,
      center: new Microsoft.Maps.Location(
        location?.latitude,
        location?.longitude,
      ),
      zoom: this.map.getZoom(),
    });
  }
}

export default BingMapApi;
