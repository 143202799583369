import { PushPin } from './BingMap.types';
import pinIcon from './pin.svg';

declare const Microsoft: any;

export const BING_MAP_SCRIPT_URL =
  'https://www.bing.com/maps/sdkrelease/mapcontrol?callback=bingMapsCallback';

export const generateMapId = (): string => {
  return `${new Date().getTime()}-${Math.floor(Math.random() * 10000)}`;
};

export const isBingMapsLoaded = () =>
  document.querySelector(`script[src^="${BING_MAP_SCRIPT_URL}"]`);

export const getBingMapScriptTag = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = BING_MAP_SCRIPT_URL;
  return script;
};

export const getRequestOptions = (Microsoft: any) => ({
  routeMode: Microsoft.Maps.Directions.RouteMode.driving,
  maxRoutes: 1,
  routeDraggable: false,
});

export const getRenderOptions = (credentials: string, zoom: number) => ({
  displayManeuverIcons: false,
  displayPostItineraryItemHints: false,
  displayPreItineraryItemHints: false,
  displayRouteSelector: false,
  displayStepWarnings: false,
  displayDisclaimer: false,
  displayWalkingWarning: false,
  showInputPanel: false,
  showLocateMeButton: false,
  showScalebar: false,
  showZoomButtons: false,
  showMapTypeSelector: false,
  disableStreetside: true,
  credentials,
  zoom,

  firstWaypointPushpinOptions: {
    visible: false,
  },
  lastWaypointPushpinOptions: {
    visible: false,
  },
  waypointPushpinOptions: {
    visible: false,
    draggable: false,
  },
  drivingPolylineOptions: {
    strokeColor: new Microsoft.Maps.Color(68, 75, 84, 1),
    strokeThickness: 6,
    cursor: 'auto',
  },
});

export const clearMapPins = (map: any) => {
  if (map?.entities?.getLength()) {
    const entities = map.entities;
    for (let i = entities.getLength() - 1; i >= 0; i--) {
      var entity = entities.get(i);
      if (entity instanceof Microsoft.Maps.Pushpin) {
        map.entities.removeAt(i);
      }
    }
  }
};

export const addMapPins = (map: any, pushPins: PushPin[]) => {
  pushPins.forEach(pushPin => {
    const currentPushPin = new Microsoft.Maps.Pushpin(pushPin.coordinates, {
      icon: pushPin.options?.icon || pinIcon,
      anchor: new Microsoft.Maps.Point(14, 40),
    });
    map.entities.push(currentPushPin);
  });
};
