import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const CREATE_SYNERGY_CASE_URL = process.env.REACT_APP_CREATE_SYNERGY_CASE_URL;

export const createCase = async ({ body, token, tenantId, moveVersion }) => {
  return fetchApi(CREATE_SYNERGY_CASE_URL, {
    method: 'POST',
    body,
    headers: {
      'content-type': 'application/json; charset=utf-8',
      'x-env': process.env.REACT_APP_ABODE_ENV,
      'x-token': token,
      'x-tenant-id': tenantId,
      'x-provider': moveVersion,
      'x-requested-by': 'synergy',
    },
  }).then(async rs => {
    const data = getProps(rs, 'json') && (await rs.json());
    return { status: rs.status, ok: rs.ok, result: data };
  });
};

export default createCase;
