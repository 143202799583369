import PropTypes from 'prop-types';
import loadable from 'react-loadable';
import Loader from 'components/Loader';

const delay = 300;

export const Loading = ({ pastDelay }) => {
  if (pastDelay) {
    return <Loader force />;
  }
  return null;
};

Loading.propTypes = {
  pastDelay: PropTypes.bool.isRequired,
};

export const CurrentNotification = loadable({
  loading: Loading,
  loader: () => import('containers/CurrentNotification'),
  delay,
});

export const History = loadable({
  loading: Loading,
  loader: () => import('containers/History'),
  delay,
});

export const ReadOnlyHistory = loadable({
  loading: Loading,
  loader: () => import('containers/History/ReadOnlyHistory'),
  delay,
});

export const HelpfulTips = loadable({
  loading: Loading,
  loader: () => import('containers/HelpfulTips'),
  delay,
});

export const FAQPage = loadable({
  loading: Loading,
  loader: () => import('containers/FAQ'),
  delay,
});

export const Case = loadable({
  loading: Loading,
  loader: () => import('containers/Case'),
  delay,
});

export const NotFound = loadable({
  loading: Loading,
  loader: () => import('containers/NotFound'),
  delay,
});

export const Intro = loadable({
  loading: Loading,
  loader: () => import('containers/Intro'),
  delay,
});

export const ScheduleAppointment = loadable({
  loading: Loading,
  loader: () => import('containers/ScheduleAppointment'),
  delay,
});

export const UploadPicturesOpening = loadable({
  loading: Loading,
  loader: () => import('containers/UploadPictures/UploadPicturesOpening'),
  delay,
});

export const UploadPicturesFollowUp = loadable({
  loading: Loading,
  loader: () => import('containers/UploadPictures/UploadPicturesFollowUp'),
  delay,
});

export const Contact = loadable({
  loading: Loading,
  loader: () => import('containers/Contact'),
  delay,
});

export const ChatRoom = loadable({
  loading: Loading,
  loader: () => import('containers/ChatRoom'),
  delay,
});

export const CaseOpening = loadable({
  loading: Loading,
  loader: () => import('containers/CaseOpening'),
  delay,
});

export const CaseAddress = loadable({
  loading: Loading,
  loader: () => import('containers/CaseAddress'),
  delay,
});

export const CaseIdentification = loadable({
  loading: Loading,
  loader: () => import('containers/CaseIdentification'),
  delay,
});

export const CaseIncidentDescription = loadable({
  loading: Loading,
  loader: () => import('containers/CaseIncidentDescription'),
  delay,
});

export const IncidentDescription = loadable({
  loading: Loading,
  loader: () => import('containers/IncidentDescription'),
  delay,
});

export const SynergyIncidentDescription = loadable({
  loading: Loading,
  loader: () => import('containers/SynergyIncidentDescription'),
  delay,
});

export const ContactDetails = loadable({
  loading: Loading,
  loader: () => import('containers/ContactDetails'),
  delay,
});

export const CaseIncidentDate = loadable({
  loading: Loading,
  loader: () => import('containers/CaseIncidentDate/CaseIncidentDate'),
  delay,
});

export const SuccessPage = loadable({
  loading: Loading,
  loader: () => import('containers/Success'),
  delay,
});

export const ErrorPage = loadable({
  loading: Loading,
  loader: () => import('containers/ErrorPage'),
  delay,
});

export const DropOffPage = loadable({
  loading: Loading,
  loader: () => import('containers/DropOff'),
  delay,
});

export const DropOffSuccessPage = loadable({
  loading: Loading,
  loader: () => import('containers/DropOffSuccess'),
  delay,
});

export const SummaryPage = loadable({
  loading: Loading,
  loader: () => import('containers/Summary'),
  delay,
});

export const PreviewPage = loadable({
  loading: Loading,
  loader: () => import('containers/Preview'),
  delay,
});

export const CustomStep = loadable({
  loading: Loading,
  loader: () => import('containers/CaseCustom'),
  delay,
});

export const FlowSelector = loadable({
  loading: Loading,
  loader: () => import('containers/FlowSelector'),
  delay,
});

export const LandingPage = loadable({
  loading: Loading,
  loader: () => import('containers/Landing'),
});

export const CallbackPage = loadable({
  loading: Loading,
  loader: () => import('containers/Callback'),
  delay,
});

export const ConfirmationPage = loadable({
  loading: Loading,
  loader: () => import('containers/Confirmation'),
  delay,
});

export const LocationPage = loadable({
  loading: Loading,
  loader: () => import('containers/Location'),
  delay,
});

export const ContactUsPage = loadable({
  loading: Loading,
  loader: () => import('containers/SynergyError'),
  delay,
});

export const VehicleDetails = loadable({
  loading: Loading,
  loader: () => import('containers/VehicleDetails'),
  delay,
});

export const SynergyPassengers = loadable({
  loading: Loading,
  loader: () => import('containers/SynergyPassengers'),
  delay,
});
