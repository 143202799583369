export const tr =
  (t, module = 'common') =>
  (key, values = {}) => {
    if (!key) {
      return t(key);
    }
    const translationKey = key.includes(':') ? key : `${module}:${key}`;
    const translation = t(translationKey);
    if (translation === translationKey.split(':')[1]) {
      return `#${translation}#`;
    }
    const poTags = Object.keys(values);
    return poTags.reduce((currentResourceTranslated, currentPoTag) => {
      const replaceablePoTag = `{${currentPoTag}}`;
      return currentResourceTranslated.replace(
        replaceablePoTag,
        values[currentPoTag] || '',
      );
    }, translation);
  };
