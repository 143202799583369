import { call, put, select } from 'redux-saga/effects';
import {
  deleteEventFail,
  deleteEventSuccess,
} from 'redux/actions/eventActions';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import { deleteEvent } from 'api/event';
import { fetchCurrentNotification } from 'redux/actions/currentNotificationActions';
import { missionJobReferenceSelector } from 'redux/selectors/mission';

export function* removeEventSaga({ payload: { missionOrderId, type } }) {
  try {
    const jobReference = yield select(missionJobReferenceSelector);
    yield put(startLoad());
    const id = missionOrderId || jobReference;
    const response = yield call(deleteEvent, {
      id,
      type,
    });
    if (!response.status || response.status > 300) {
      throw new Error('Request Failure in delete Event Api');
    }
    const { result } = response;
    yield put(deleteEventSuccess(result));
    yield put(fetchCurrentNotification({ id }));
  } catch (error) {
    yield put(deleteEventFail(error?.message || error));
  } finally {
    yield put(stopLoad());
  }
}
