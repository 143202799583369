import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { AlternativeButton } from 'globalStyles/Containers';

export const MenuContainer = styled.div`
  position: absolute;
  top: 61px;
  width: 100%;
  right: 0;
  background: ${({ theme }) => theme.onPrimaryColor};
  list-style-type: none;
  text-align: center;
  z-index: 2;
  box-shadow: 0 1px 2px 1px rgba(4, 4, 4, 0.3);

  &:after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    z-index: 0;
  }
`;

export const PhotoContainer = styled.div`
  padding: 25px;
`;

export const MenuButton = styled(AlternativeButton)`
  border-width: 2px;
  max-width: 275px;
  font-size: 15px;
  padding: 13px;
  letter-spacing: 0.5px;
  font-weight: 600;
  a,
  & a:focus,
  & a:visited {
    text-decoration: none;
  }
`;

export const MenuItem = styled.li`
  padding: 20px 20px 10px ${({ isButton }) => (isButton ? '20px' : '45px')};
  box-shadow: inset 1px 1px 0px 0px rgba(204, 201, 201, 0.16);
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;

  a,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.primaryColor};
  }
  span {
    margin-left: auto;
    margin-top: -8px;
  }
`;

export const MenuItemLink = styled(Link)`
  padding: 20px 20px 10px 45px;
  box-shadow: inset 1px 1px 0px 0px rgba(204, 201, 201, 0.16);
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;

  a,
  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.primaryColor};
  }
  span {
    margin-left: auto;
    margin-top: -8px;
  }
`;
