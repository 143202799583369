import { call, put } from 'redux-saga/effects';
import {
  getHWYLocationFail,
  getHWYLocationSuccess,
} from 'redux/actions/hereWithYouActions';
import { getLocations } from 'api/hereWithYou';

export function* getHereWithYouLocationSaga(action) {
  try {
    const { trackingIdentifier, hereWithYouToken } = action.payload;
    const response = yield call(getLocations, {
      trackingIdentifier,
      hereWithYouToken,
    });
    const { result, status } = response;
    if (status === 200) {
      yield put(getHWYLocationSuccess(result));
      return;
    }
    yield put(getHWYLocationFail(result));
  } catch (error) {
    yield put(getHWYLocationFail(error));
  }
}
