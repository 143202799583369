import {
  DISABLE_NOTIFICATION_MODAL,
  ENABLE_NOTIFICATION_MODAL,
} from 'redux/actions/types';

const initialState = {
  mayAskForNotification: true,
};

function routeReducer(state = initialState, action) {
  switch (action.type) {
    case ENABLE_NOTIFICATION_MODAL:
      return { ...state, mayAskForNotification: true };
    case DISABLE_NOTIFICATION_MODAL:
      return { ...state, mayAskForNotification: false };
    default:
      return state;
  }
}

export default routeReducer;
