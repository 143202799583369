import {
  put,
  call,
  select,
  cancel,
  fork,
  take,
  race,
} from 'redux-saga/effects';
import {
  cleanPictures,
  markPictureToUpload,
  preparePictureFailed,
} from 'redux/actions/pictureActions';
import { expendRecaptcha } from 'redux/actions/recaptchaActions';
import { maxNumberOfPicturesSelector } from 'redux/selectors/config';
import { postPictureToCMX } from 'redux/sagas/picture/postPictureToCMX';
import { requestRecaptchaToken } from 'redux/sagas/recaptcha/requestRecaptcha';
import { uploadedPicturesSelector } from 'redux/selectors/picture';
import { dataSelector } from 'redux/selectors/common';
import { SET_RECAPTCHA, SET_RECAPTCHA_ERROR } from 'redux/actions/types';
import { addErrorAlert, addSuccessAlert } from 'redux/actions/alertActions';
import { noop } from 'utils/global-utils';

const getNumberOfPictures = ({
  maximum_number_of_pictures,
  numberOfPicturesUploaded,
}) => {
  const numberOfPictures =
    maximum_number_of_pictures - numberOfPicturesUploaded;
  return numberOfPictures >= 0 ? numberOfPictures : 0;
};
export const getFileSize = number => (number / 1048576).toFixed(1);

export function* uploadPicturesToCMX({
  payload: {
    pictures,
    errorTitle,
    errorMessage,
    successMessage,
    refresher = noop,
    refresh,
  },
}) {
  try {
    yield put(cleanPictures());

    const alreadyUploadedPictures = yield select(uploadedPicturesSelector);
    const { numberOfPicturesUploaded = 0 } = yield select(dataSelector);
    const maximum_number_of_pictures = yield select(
      maxNumberOfPicturesSelector,
    );
    const maxNumberOfPictures = getNumberOfPictures({
      maximum_number_of_pictures,
      numberOfPicturesUploaded,
    });

    if (pictures.length > 0) {
      const numberOfAlreadyUploadedPictures = alreadyUploadedPictures.filter(
        ({ status }) => status === 'LOADED',
      ).length;
      const picturesToUpload = Array.from(pictures).slice(
        0,
        maxNumberOfPictures - numberOfAlreadyUploadedPictures,
      );
      for (const picture of picturesToUpload) {
        yield call(prepareEveryCall, {
          picture,
          refresher,
          refresh,
          errorMessage,
          errorTitle,
          successMessage,
        });
      }
    }
  } catch (err) {
    return yield put(preparePictureFailed(err));
  }
}

export function* prepareEveryCall({
  picture,
  refresh,
  refresher,
  errorMessage,
  errorTitle,
  successMessage,
}) {
  const task = yield fork(requestRecaptchaToken);

  const { token, error } = yield race({
    token: take(SET_RECAPTCHA),
    error: take(SET_RECAPTCHA_ERROR),
  });

  if (error) {
    yield cancel(task);
    return;
  }

  yield put(expendRecaptcha());
  yield put(markPictureToUpload({ picture }));
  const { isPictureSent } = yield call(postPictureToCMX, {
    picture,
    recaptchaToken: token.payload,
    refresh,
    refresher,
  });

  if (isPictureSent) {
    yield successMessage && put(addSuccessAlert(successMessage));
  } else {
    yield errorMessage && put(addErrorAlert(errorMessage, errorTitle));
  }
}
