import { call, delay, put, select } from 'redux-saga/effects';
import { selectProvider } from 'redux/selectors/config';
import { providerToCountry } from 'mappers/providers';
import {
  getLocationDurationFail,
  getLocationFail,
  getLocationDurationSuccess,
  updateLocation,
} from 'redux/actions/locationActions';
import getLocation from 'api/location';
import { getDurationByCoords } from 'api/location/location';

export function* getSuggestedLocation(action) {
  try {
    const provider = yield select(selectProvider);
    const country = providerToCountry[provider];
    const { addressLine, coords } = action.payload;
    if (!addressLine) {
      yield put(updateLocation({}));
    }
    yield delay(300);
    const response = yield call(getLocation, {
      addressLine,
      countryRegion: country,
      coords,
    });
    yield put(updateLocation(response.result));
  } catch (error) {
    yield put(getLocationFail(error));
  }
}

export function* getLocationDuration({ payload }) {
  try {
    const response = yield call(getDurationByCoords, payload);
    yield put(getLocationDurationSuccess(response.result.travelDuration));
  } catch (error) {
    yield put(getLocationDurationFail(error));
  }
}
