import styled from 'styled-components';

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: ${({ shouldDisplayHeader }) =>
    `calc(${window['--real100vh']} - ${shouldDisplayHeader ? '61px' : '0px'})`};
  justify-content: flex-end;
`;

export const Scrollable = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
`;
