import { put, call } from 'redux-saga/effects';
import {
  updateNotificationSuccess,
  updateNotificationFail,
} from 'redux/actions/currentNotificationActions';
import { fetchMission } from '../mission/mission-saga';
import updateNotificationApi from 'api/notification';
import getCurrentNotification from './get-current-notification-saga';
import { getCaseStart } from 'redux/actions/caseActions';

export default function* updateCurrentNotification({
  payload: {
    missionOrderId,
    deviceId,
    customerNotificationOption,
    caseGuid,
    preferredLanguage,
  },
}) {
  try {
    const response = yield call(updateNotificationApi, {
      missionOrderId,
      deviceId,
      caseGuid,
      customerNotificationOption,
      preferredLanguage: preferredLanguage.substring(0, 2),
    });
    const { result } = response;
    yield put(updateNotificationSuccess(result));
    if (missionOrderId) {
      yield call(fetchMission, {
        payload: { id: missionOrderId },
      });
      yield call(getCurrentNotification, {
        payload: { id: missionOrderId },
      });
    }
    if (caseGuid) {
      yield put(getCaseStart({ id: caseGuid }));
    }
  } catch (error) {
    yield put(updateNotificationFail(error.message));
  }
}
