export const Spacing = {
  none: 0,
  xs: '4px',
  sm: '8px',
  md: '12px',
  lg: '16px',
  xl: '24px',
  auto: 'auto',
};

type SpacingType = keyof typeof Spacing;

export default SpacingType;
