import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const LOCATIONS_URL = process.env.REACT_APP_LOCATIONS_URL;
const AUTO_SUGGEST_URL = process.env.REACT_APP_AUTO_SUGGEST_URL;
const BING_MAPS_KEY = process.env.REACT_APP_BING_MAPS_KEY;
const ROUTE_DURATION_URL = process.env.REACT_APP_ROUTE_DURATION_URL;
export const getLocation = async ({ addressLine, countryRegion, coords }) => {
  const rs = await (addressLine
    ? getLocationByAddress({ addressLine, countryRegion })
    : getLocationByCoords({ coords }));
  const data = getProps(rs, 'json') && (await rs.json());
  const result = addressLine
    ? data?.resourceSets?.[0]?.resources?.[0]?.value.map(
        resultObject => resultObject.address,
      )
    : [data?.resourceSets?.[0]?.resources?.[0]?.address];
  return { status: rs.status, ok: rs.ok, result: result || [{}] };
};

const getLocationByAddress = ({ addressLine, countryRegion }) =>
  fetchApi(AUTO_SUGGEST_URL, {
    method: 'GET',
    headers: {
      accept: '*/*',
      'sec-fetch-dest': 'empty',
      'sec-fetch-site': 'cross-site',
    },
    params: {
      key: BING_MAPS_KEY,
      query: addressLine,
      countryFilter: countryRegion,
      includeEntityTypes: 'Address',
      maxResults: 3,
    },
  });

const getLocationByCoords = ({ coords }) =>
  fetchApi(`${LOCATIONS_URL}/${coords.latitude},${coords.longitude}`, {
    method: 'GET',
    params: {
      key: BING_MAPS_KEY,
    },
  });

export const getDurationByCoords = async ({ origins, destinations }) => {
  const rs = await fetchApi(ROUTE_DURATION_URL, {
    method: 'POST',
    headers: {
      accept: '*/*',
    },
    params: {
      key: BING_MAPS_KEY,
    },
    body: {
      origins,
      destinations,
      travelMode: 'Driving',
      timeUnit: 'minute',
    },
  });
  const data = getProps(rs, 'json') && (await rs.json());
  return {
    status: rs.status,
    ok: rs.ok,
    result: data.resourceSets[0].resources[0].results[0],
  };
};

export default getLocation;
