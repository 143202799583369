import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconStyledType, IconType } from './types';

export const IconStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: ${(props: IconStyledType) =>
    props.isClickable ? 'pointer' : 'inherit'};

  svg,
  img {
    margin: 5px;
    margin-right: 10px;
  }

  ${(props: IconStyledType) =>
    props.isFloating &&
    `
    svg {
      margin: 0 8px;
      margin-top: -2px;
    }
  `}
`;

export const Icon = ({ onClick, dataAttributes, children }: IconType) => {
  const dataAttributesProps: { [key: string]: string } = {};
  dataAttributes &&
    dataAttributes.forEach(({ key, value }) => {
      dataAttributesProps[`data-${key}`] = value;
    });
  return (
    <IconStyled
      onClick={onClick}
      {...dataAttributesProps}
      isClickable={!!onClick}
    >
      {children}
    </IconStyled>
  );
};

Icon.propTypes = {
  dataAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Icon.defaultProps = {
  dataAttributes: [],
  onClick: () => false,
};
