import {
  GET_HWY_LOCATION_FAIL,
  GET_HWY_LOCATION_START,
  GET_HWY_LOCATION_SUCCESS,
  GET_HWY_MAP_ACCESS_FAIL,
  GET_HWY_MAP_ACCESS_START,
  GET_HWY_MAP_ACCESS_SUCCESS,
} from './types';

export const getHWYLocationStart = payload => ({
  type: GET_HWY_LOCATION_START,
  payload,
});

export const getHWYLocationSuccess = payload => ({
  type: GET_HWY_LOCATION_SUCCESS,
  payload,
});

export const getHWYLocationFail = payload => ({
  type: GET_HWY_LOCATION_FAIL,
  payload,
});

export const getHWYMapAccessStart = payload => ({
  type: GET_HWY_MAP_ACCESS_START,
  payload,
});

export const getHWYMapAccessSuccess = payload => ({
  type: GET_HWY_MAP_ACCESS_SUCCESS,
  payload,
});

export const getHWYMapAccessFail = payload => ({
  type: GET_HWY_MAP_ACCESS_FAIL,
  payload,
});
