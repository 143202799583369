import { call, put, select } from 'redux-saga/effects';
import carDetailsActions from 'redux/actions/carDetails';
import {
  selectTenantId,
  selectMoveVersion,
  selectPartnerReference,
} from 'redux/selectors/config';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import getCarDetails from 'api/carDetails';
import { getSynergySessionToken } from 'utils/sagas-utils';

const { getPlateSuccess, getPlateFail } = carDetailsActions;

export function* getCarDetailsSaga(action) {
  const { plateNumber } = action.payload;

  yield put(startLoad());

  const partnerReference = yield select(selectPartnerReference);
  const tenantId = yield select(selectTenantId);
  const moveVersion = yield select(selectMoveVersion);

  try {
    const sessionToken = yield getSynergySessionToken();

    const response = yield call(getCarDetails, {
      plateNumber,
      partnerReference,
      tenantId,
      moveVersion,
      sessionToken,
    });

    if (!response.ok && response.status !== 404) {
      throw new Error('Car details api error:', response.result);
    }

    let carDetailsData = {
      plateNumber,
    };

    if (response.status !== 404) {
      const [{ model, brand, vehicle_details }] = response.result;
      carDetailsData = {
        ...carDetailsData,
        type: vehicle_details,
        model,
        brand,
      };
    }

    yield put(getPlateSuccess(carDetailsData));
  } catch (error) {
    yield put(getPlateFail(error));
  }

  yield put(stopLoad());
}
