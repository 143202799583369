import {
  GET_HISTORY_START,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAIL,
  RESET_HISTORY,
} from './types';

export const getHistoryStart = payload => ({
  type: GET_HISTORY_START,
  payload,
});

export const resetHistory = () => ({
  type: RESET_HISTORY,
});

export const getHistorySuccess = history => ({
  type: GET_HISTORY_SUCCESS,
  payload: history,
});

export const getHistoryFail = error => ({
  type: GET_HISTORY_FAIL,
  payload: error,
});
