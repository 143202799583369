import { memo, FC } from 'react';
import {
  TextOptionStyled,
  IconOptionStyled,
  TextSquareOptionStyled,
  IconLabelStyled,
  RadioButtonStyled,
} from './OptionStyled';
import { OptionProps, OptionTypes } from './Option.types';

const TextOption: FC<OptionProps> = memo(
  ({
    onClick,
    text,
    highlighted,
    id,
    shouldHighlightText,
    shouldDowngradeNotHighlightedTextTone,
  }) => (
    <TextOptionStyled
      onClick={onClick}
      highlighted={highlighted}
      shouldHighlightText={shouldHighlightText}
      shouldDowngradeNotHighlightedTextTone={
        shouldDowngradeNotHighlightedTextTone
      }
      data-testid={`option-${id}`}
    >
      <RadioButtonStyled highlighted={highlighted} />
      {text}
    </TextOptionStyled>
  ),
);

const IconOption: FC<OptionProps> = memo(
  ({ onClick, text, highlighted, id, icon: Icon }) => (
    <IconOptionStyled
      onClick={onClick}
      highlighted={highlighted}
      data-testid={`option-icon-${id}`}
    >
      <Icon />
      <IconLabelStyled>{text}</IconLabelStyled>
    </IconOptionStyled>
  ),
);

const TextSquareOption: FC<OptionProps> = memo(
  ({ onClick, text, highlighted, id, icon: Icon }) => (
    <TextSquareOptionStyled
      onClick={onClick}
      highlighted={highlighted}
      data-testid={`option-text-square-${id}`}
    >
      <IconLabelStyled>{text}</IconLabelStyled>
    </TextSquareOptionStyled>
  ),
);

const Option: FC<OptionProps> = ({
  onClick,
  text,
  icon,
  highlighted,
  id,
  type = OptionTypes.TEXT_SQUARE,
  shouldHighlightText = true,
  shouldDowngradeNotHighlightedTextTone = false,
}) => {
  const props = {
    onClick,
    text,
    highlighted,
    id,
  };

  if (type === OptionTypes.ICON) {
    return <IconOption {...props} icon={icon} />;
  }

  if (type === OptionTypes.TEXT) {
    return (
      <TextOption
        {...props}
        shouldHighlightText={shouldHighlightText}
        shouldDowngradeNotHighlightedTextTone={
          shouldDowngradeNotHighlightedTextTone
        }
      />
    );
  }

  return <TextSquareOption {...props} />;
};

export default Option;
