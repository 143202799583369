import { event } from 'analytics';
import { useSelector, useDispatch } from 'react-redux';
import { selectModals } from 'redux/selectors/modals';
import {
  selectPrivatePoliciesUrl,
  selectShowPrivacyPolicyModal,
  selectTermsAndConditionsPoliciesUrl,
} from 'redux/selectors/config';
import { acceptPrivatePolicies } from 'redux/actions/privatePoliciesActions';
import { hideModal } from 'redux/actions/modalActions';

export const usePrivatePoliciesModal = () => {
  const modals = useSelector(selectModals);
  const privatePoliciesUrl = useSelector(selectPrivatePoliciesUrl);
  const terms_and_conditions_policies_url = useSelector(
    selectTermsAndConditionsPoliciesUrl,
  );
  const relevantData = {
    terms_and_conditions_policies_url,
    private_policies_url: privatePoliciesUrl,
  };
  const shouldShowModal = useSelector(selectShowPrivacyPolicyModal);
  const dispatch = useDispatch();
  const privatePoliciesModal = modals.find(
    ({ type }) => type === 'privatePolicies',
  );

  const accept = () => {
    dispatch(acceptPrivatePolicies());
    dispatch(hideModal({ type: 'privatePolicies' }));
    event('accept', 'privacy_policy');
  };

  const moreInformation = () => {
    window.open(privatePoliciesUrl, '_blank');
    event('more_info', 'privatePolicies');
  };
  return {
    accept,
    privatePoliciesModal,
    moreInformation,
    relevantData,
    shouldShowModal,
  };
};
