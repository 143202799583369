import styled, { css } from 'styled-components';

const isStaticStyles = css`
  display: flex;
  justify-content: center;
  margin: auto;
  position: relative;
  width: 100%;

  div {
    flex-direction: inherit;
  }

  svg {
    position: absolute;
    top: 70px;
    width: 80px;
    height: 80px;
  }
`;

const fullSizedLoaderStyles = opacity => css`
  align-items: center;
  background: rgba(255, 255, 255, ${opacity});
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;

  svg {
    width: 80px;
    height: 80px;
  }
`;

const Loader = styled.div`
  ${({ opacity, isStatic }) =>
    isStatic ? isStaticStyles : fullSizedLoaderStyles(opacity)};
`;

export default Loader;
