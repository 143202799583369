import { put, call } from 'redux-saga/effects';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import {
  contactRequestSuccess,
  contactRequestFail,
} from 'redux/actions/contactActions';
import { addSuccessAlert, addErrorAlert } from 'redux/actions/alertActions';
import contactRequestApi from 'api/contact/requestContact';
import { goHome } from 'redux/actions/routeActions';

export function* contactRequest({
  case_guid,
  reason,
  description,
  preferred_touch_point,
  translator,
}) {
  try {
    yield put(startLoad());

    const response = yield call(contactRequestApi, {
      case_guid,
      reason,
      description,
      preferred_touch_point,
    });

    yield put(contactRequestSuccess(response));
    yield put(addSuccessAlert(translator('comments_alert-success_message')));
    yield put(goHome());
  } catch (error) {
    yield put(contactRequestFail());
    yield put(addErrorAlert(translator('comments_alert-error_message')));
  } finally {
    yield put(stopLoad());
  }
}
