import { all, fork } from 'redux-saga/effects';
import analyticsSagas from 'redux/sagas/analytics';
import appointmentSagas from 'redux/sagas/appointment';
import caseSagas from 'redux/sagas/case';
import chatSagas from 'redux/sagas/chat';
import createCaseSagas from 'redux/sagas/createCase';
import configSagas from 'redux/sagas/config';
import contactSagas from 'redux/sagas/contact';
import currentNotificationSagas from 'redux/sagas/currentNotification';
import eventSagas from 'redux/sagas/event';
import historySagas from 'redux/sagas/history';
import missionSagas from 'redux/sagas/mission';
import routeSagas from 'redux/sagas/route';
import refreshSagas from 'redux/sagas/refresh';
import pictureSagas from 'redux/sagas/picture';
import stepSagas from 'redux/sagas/step';
import updateAppMissionSagas from 'redux/sagas/updateAppMission';
import locationSagas from 'redux/sagas/location';
import cookiesSagas from 'redux/sagas/cookies';
import hereWithYouSagas from 'redux/sagas/hwy';
import optimizeSagas from 'redux/sagas/optimize';
import browserLocationSagas from 'redux/sagas/browserLocation';
import carDetailsSagas from 'redux/sagas/carDetails';

export default function* rootSaga() {
  yield all([
    fork(configSagas),
    fork(analyticsSagas),
    fork(appointmentSagas),
    fork(caseSagas),
    fork(chatSagas),
    fork(createCaseSagas),
    fork(contactSagas),
    fork(currentNotificationSagas),
    fork(eventSagas),
    fork(historySagas),
    fork(locationSagas),
    fork(missionSagas),
    fork(refreshSagas),
    fork(routeSagas),
    fork(pictureSagas),
    fork(stepSagas),
    fork(updateAppMissionSagas),
    fork(cookiesSagas),
    fork(hereWithYouSagas),
    fork(optimizeSagas),
    fork(browserLocationSagas),
    fork(carDetailsSagas),
  ]);
}
