import PropTypes from 'prop-types';
import ErrorIcon from 'components/Icons/ErrorIcon';
import GenericAlert from '../GenericAlert';
import { AlertTypes } from 'constants/alerts';

const AlertError = props => {
  return (
    <GenericAlert {...props} type={AlertTypes.ERROR}>
      <ErrorIcon data-testid="error-tick-icon" />
    </GenericAlert>
  );
};

AlertError.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};

AlertError.defaultProps = {
  id: '',
  message: '',
  title: '',
};

export default AlertError;
