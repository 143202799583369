import { put, delay } from 'redux-saga/effects';
import {
  optimizeInitialized,
  initializeOptimize,
} from 'redux/actions/optimizeActions';

export function* handleOptimizeLoaded() {
  if (!window.google_optimize) {
    yield delay(10000);
    yield put(initializeOptimize());
  } else {
    yield put(optimizeInitialized());
  }
}
