import { ACCEPT_PRIVATE_POLICIES } from 'redux/actions/types';

const defaultState = {
  accepted: false,
};

function privatePoliciesReducer(state = defaultState, action) {
  switch (action.type) {
    case ACCEPT_PRIVATE_POLICIES:
      return {
        accepted: true,
      };
    default:
      return state;
  }
}

export default privatePoliciesReducer;
