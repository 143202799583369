import {
  CREATE_CASE_SUCCESS,
  UPDATE_CURRENT_STEP,
  UPDATE_MAX_STEP,
} from 'redux/actions/types';

function routeReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_CURRENT_STEP:
      return { ...state, current: action.payload };
    case UPDATE_MAX_STEP:
      return { ...state, max: action.payload };
    case CREATE_CASE_SUCCESS:
      return {};
    default:
      return state;
  }
}

export default routeReducer;
