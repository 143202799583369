import styled from 'styled-components';
import { OutlinedButton } from 'globalStyles/Buttons';
import ModalPortal from './ModalPortal';

export const ModalStyled = styled(ModalPortal)``;

export const Title = styled.h3`
  margin-top: 20px;
  color: ${({ theme }) => theme.onPrimaryColor};
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: start;
`;

export const BodyContainer = styled.div`
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: normal;
    text-align: left;
    font-style: normal;
    font-stretch: normal;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.onPrimaryColor};
  }
`;

export const Row = styled.div`
  flex-direction: row;
  justify-content: stretch;
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 5px;
  margin-left: -8px;
  margin-right: -8px;
`;

export const Column = styled(Row)`
  display: grid;

  button {
    margin: 0;
    margin-bottom: 10px;
    max-width: 300px;
    font-weight: 500;
  }
`;

export const Button = styled(OutlinedButton)`
  max-width: 300px;
  border: solid 1px ${({ theme }) => theme.onPrimaryColor};
  display: flex;
  flex: 140px;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: center;
  color: ${({ theme }) => theme.onPrimaryColor};
  background-color: transparent;
  align-items: center;
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;
  border-width: 2px;
  padding: 12px;
  border-radius: 0;

  &:hover {
    border: solid 2px ${({ theme }) => theme.onPrimaryColor};
  }
`;

export const ButtonRight = styled(Button)`
  border-width: 2px;
`;

export const CornerIcon = styled.div`
  position: absolute;
  right: 10px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.onPrimaryColor};
  text-decoration: 'underline';
`;

export const BodyContent = styled.p`
  color: ${({ theme }) => theme.onPrimaryColor};
`;

export const CustomButtonRight = styled(ButtonRight)`
  background-color: ${({ theme }) => theme.onDarkColor};
  color: ${({ theme }) => theme.darkColor};
`;

export const ModalViewPort = styled.div`
  max-width: 360px;
  flex-grow: 1;
`;
