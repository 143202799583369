import { missionCompletionSteps } from 'constants/mission';

export const caseNamespaceSelector = state => state.case;

export const caseSelector = state => caseNamespaceSelector(state).data;

export const caseErrorSelector = state => caseNamespaceSelector(state).error;

export const caseShortUrlSelector = state => caseSelector(state)?.shortURL;
export const caseGuidSelector = state => caseSelector(state)?.caseGuid;

export const embeddedCaseDataSelector = state =>
  caseSelector(state) && caseSelector(state).embedded;

export const caseMissionsSelector = state =>
  embeddedCaseDataSelector(state) &&
  embeddedCaseDataSelector(state).missionOrders
    ? embeddedCaseDataSelector(state).missionOrders
    : [];

export const completedCaseMissionsSelector = state =>
  caseMissionsSelector(state).filter(({ step }) =>
    missionCompletionSteps.includes(step),
  );

export const pendingCaseMissionsSelector = state =>
  caseMissionsSelector(state).filter(
    ({ step }) => !missionCompletionSteps.includes(step),
  );

export const countPendingCaseMissionsSelector = state =>
  pendingCaseMissionsSelector(state).length;

export const countCompletedCaseMissionsSelector = state =>
  completedCaseMissionsSelector(state).length;
