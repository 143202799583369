import {
  SET_RECAPTCHA,
  SET_RECAPTCHA_ERROR,
  USE_RECAPTCHA,
  REQUEST_RECAPTCHA,
} from './types';

export const setRecaptcha = ({ token }) => ({
  type: SET_RECAPTCHA,
  payload: token,
});

export const setRecaptchaError = ({ error }) => ({
  type: SET_RECAPTCHA_ERROR,
  payload: error,
});

export const expendRecaptcha = () => ({
  type: USE_RECAPTCHA,
});

export const requestRecaptcha = () => ({
  type: REQUEST_RECAPTCHA,
});
