import styled from 'styled-components';
import { InputStyledSize } from '../InputStyled/InputStyled.types';
import {
  AutocompleteBaseTheme,
  AutocompleteOptionStyledProps,
} from './Autocomplete.types';

export const StyledAutocomplete = styled.div`
  position: relative;
  & input {
    margin: 0;
    padding-right: 44px;
  }
`;

export const StyledOptionsList = styled.ul`
  position: absolute;
  top: 100%;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
`;

export const StyledOption = styled.li<AutocompleteOptionStyledProps>`
  list-style: none;
  background: white;
  padding: ${({ size }) =>
    AutocompleteBaseTheme.size[size as InputStyledSize].padding};
  font-size: ${({ size }) =>
    AutocompleteBaseTheme.size[size as InputStyledSize].fontSize};
  &:not(:last-child) {
    border-bottom: 1px solid;
  }
`;

export const StyledClearButton = styled.button`
  background: #fff;
  border: none;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  & svg {
    width: 15px;
    height: 15px;
    & path {
      fill: ${(props: any) => props.theme.primaryColor};
    }
  }
`;
