import { v4 as uuidv4 } from 'uuid';
import {
  ADD_ALERT,
  DISMISS_ALERT,
  DISMISS_ALL_ALERTS,
  TOGGLE_ALERT,
} from 'redux/actions/types';

const initialState = {
  alert: [],
  banner: false,
  hasBeenBannerActivated: false,
  special: false,
  hasBeenSpecialActivated: false,
};

function alertsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT:
      return {
        ...state,
        alert: [...state.alert, { id: uuidv4(), ...action.payload }],
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: state.alert.filter(alert => alert.id !== action.payload),
      };
    case DISMISS_ALL_ALERTS:
      return {
        ...state,
        alert: [],
      };
    case TOGGLE_ALERT:
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    default:
      return state;
  }
}

export default alertsReducer;
