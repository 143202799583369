import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { dismissAlert } from 'redux/actions/alertActions';
import { alertTimeoutSelector } from 'redux/selectors/config';
import SuccessAlert from './SuccessAlert';
import AlertError from './ErrorAlert';

export const Alert = ({ title, message, type, id, shouldVanish }) => {
  const timeout = useSelector(alertTimeoutSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (shouldVanish) {
      setTimeout(() => {
        dispatch(dismissAlert(id));
      }, timeout * 1000);
    }
  }, [id, shouldVanish, timeout, dispatch]);

  switch (type) {
    case 'ERROR':
      return <AlertError title={title} message={message} id={id} />;
    case 'SUCCESS':
    default:
      return <SuccessAlert title={title} message={message} id={id} />;
  }
};

Alert.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  shouldVanish: PropTypes.bool,
  dismissAlert: PropTypes.func,
};

Alert.defaultProps = {
  id: '',
  message: '',
  type: '',
  title: '',
  shouldVanish: false,
};

export default Alert;
