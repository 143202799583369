import {
  GET_HWY_MAP_ACCESS_SUCCESS,
  GET_HWY_LOCATION_SUCCESS,
  GET_HWY_LOCATION_FAIL,
  GET_HWY_MAP_ACCESS_FAIL,
  REMOVE_MISSION_DATA_FROM_GTM,
} from 'redux/actions/types';

const initialState = {
  location: {},
  mapAccess: {},
  error: {
    location: {},
    mapAccess: {},
  },
};

function hereWithYouReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HWY_MAP_ACCESS_SUCCESS:
      return {
        ...state,
        mapAccess: action.payload,
        error: {
          ...state.error,
          mapAccess: {},
        },
      };
    case GET_HWY_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.payload,
        error: {
          ...state.error,
          location: {},
        },
      };
    case GET_HWY_LOCATION_FAIL:
      return {
        ...state,
        location: {},
        error: {
          ...state.error,
          location: action.payload,
        },
      };
    case GET_HWY_MAP_ACCESS_FAIL:
      return {
        ...state,
        mapAccess: {},
        error: {
          ...state.error,
          mapAccess: action.payload,
        },
      };
    case REMOVE_MISSION_DATA_FROM_GTM:
      return initialState;
    default:
      return state;
  }
}

export default hereWithYouReducer;
