import styled from 'styled-components';
import { OptionProps } from './Option.types';

export const TextOptionStyled: any = styled.div`
  margin: 0 8px 8px;
  padding: 14px 16px 14px 16px;
  width: 304px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
  border-radius: 4px;
  font-size: 16px;
  font-weight: ${({ highlighted, shouldHighlightText }: OptionProps) =>
    highlighted && shouldHighlightText ? 'bold' : 'normal'};
  box-shadow: ${({ highlighted }: OptionProps) =>
    highlighted
      ? '0px 0px 0px 3px rgba(0, 0, 143, 0.5)'
      : '0px 0px 4px 0px rgba(0, 0, 0, 0.2)'};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.8px;
  color: ${({
    theme,
    highlighted,
    shouldHighlightText,
    shouldDowngradeNotHighlightedTextTone,
  }: OptionProps) =>
    highlighted
      ? shouldHighlightText
        ? theme.primaryColor
        : theme.historyProviderColor
      : shouldDowngradeNotHighlightedTextTone
      ? '#757575'
      : theme.historyProviderColor};
  ${({ theme, highlighted }: OptionProps) =>
    highlighted && `border: solid 1px ${theme.primaryColor};`}
  cursor: pointer;
  display: flex;
  align-items: flex-start;
`;

export const IconOptionStyled: any = styled(TextOptionStyled)`
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  margin: 0 8px 16px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 18px;
  overflow: hidden;
  width: 140px;
  height: 130px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  text-align: center;
  letter-spacing: 0.8px;
  ${({ theme, highlighted }: OptionProps) =>
    highlighted && `border: solid 1px ${theme.primaryColor};`}
`;

export const TextSquareOptionStyled: any = styled(IconOptionStyled)`
  justify-content: center;
`;

export const IconLabelStyled = styled.div`
  font-size: 14px;
  max-height: 36px;
  overflow: hidden;
`;

export const RadioButtonStyled: any = styled.div`
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 8px;
  border-radius: 100%;
  border: 1px solid
    ${({ theme, highlighted }: OptionProps) =>
      highlighted ? theme.primaryColor : '#ccc'};
  background-color: ${({ theme, highlighted }: OptionProps) =>
    highlighted ? theme.primaryColor : 'white'};
  box-shadow: ${({ theme, highlighted }: OptionProps) =>
    highlighted ? 'inset 0 0 0 2px white' : 'none'};
`;
