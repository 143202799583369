import encodeRequestToHtml from 'utils/encoder';

const whitelist = [
  'policy_number',
  'international_prefix',
  'number',
  'occurred_at',
  'attachments',
  'person_registration',
];

export const migrateData = ({
  caseCreationData,
  decisionTree,
  partner_reference,
  country,
  partner_name,
  preferred_language,
}) => {
  const {
    additionalAddress: additional_information,
    address: street_address,
    city: locality,
    firstNameContact: first_name,
    incidentDate: occurred_at,
    incidentDetails,
    lastNameContact: last_name,
    emailContact: email,
    phoneCountryCodeContact: international_prefix,
    phoneNumberContact: number,
    policyNumber: policy_number,
    isPropertyOwner: is_property_owner,
    postalCode: postal_code,
    allowCallback: allow_callback,
    incidentRepudiationDetails: incident_repudiation_details,
    isPropertyOccupancy: is_property_occupancy,
    pictures,
    ...custom_data
  } = caseCreationData;
  const {
    result: {
      additionalInformation,
      code,
      description,
      dropout,
      priority,
      shortDescription: short_description,
    },
    questionsAndAnswers,
  } = decisionTree;
  const body = {
    incident: {
      cause: {
        code,
        description,
        short_description,
        additional_information: additionalInformation,
        questions_and_answers: questionsAndAnswers,
        priority,
      },
      dropoff: dropout,
      location: {
        address: {
          country,
          locality,
          postal_code,
          street_address,
        },
        additional_information,
      },
      occurred_at,
    },
    policy_number,
    partner_reference,
    partner_name,
    contact: {
      first_name,
      last_name,
      preferred_language,
      phone: {
        international_prefix,
        number,
      },
      email,
    },
    policy_holder: {
      first_name,
      last_name,
      address_postal_code: postal_code,
      is_property_owner,
    },
    repudiation_info: {
      allow_callback,
      incident_repudiation_details,
      is_property_occupancy,
    },
    attachments: pictures && pictures.map(({ id }) => id),
    custom_data,
  };
  if (incidentDetails) {
    body.incident.description = incidentDetails;
  }
  return encodeRequestToHtml(body, whitelist);
};
