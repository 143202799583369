import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const GENERATE_SYNERGY_TOKEN_URL =
  process.env.REACT_APP_GENERATE_SYNERGY_TOKEN_URL;

const SYNERGY_RECAPTCHA_KEY = process.env.REACT_APP_SYNERGY_RECAPTCHA_KEY;

const generateToken = async ({ body }) => {
  const response = await fetchApi(
    `${GENERATE_SYNERGY_TOKEN_URL}?key=${SYNERGY_RECAPTCHA_KEY}`,
    {
      method: 'POST',
      body,
      headers: {
        'content-type': 'application/json; charset=utf-8',
        env: process.env.REACT_APP_ABODE_ENV,
        'x-requested-by': 'synergy',
      },
    },
  );

  const data = getProps(response, 'json') && (await response.json());

  return { status: response.status, ok: response.ok, result: data };
};

export default generateToken;
