import { ApplicationState } from 'redux/reducers';
import { CaseCreationData } from 'ts/types/case-creation.types';
import { Location } from 'ts/types/location.types';
import {
  CaseSuccessResponseModel,
  DecisionTreeDataResponseModel,
  DecisionTreeDataResultModel,
} from 'redux/actions/caseCreationActions.types';
import { identificationOptionsSelector } from './config';

export const caseCreationSelector = (
  state: ApplicationState,
): CaseCreationData => state.caseCreation.data;
export const caseCreationEditModeSelector = (
  state: ApplicationState,
): boolean => state.caseCreation.isEditMode;
export const caseCreationRetryAttemptsSelector = (
  state: ApplicationState,
): number => state.caseCreation.retryAttempts;
export const addressOptionsSelector = (state: ApplicationState): Location[] =>
  state.location || [];
export const policyNumberSelector = (state: ApplicationState) =>
  caseCreationSelector(state).policyNumber;
export const isCheckUpSelector = (state: ApplicationState) =>
  caseCreationSelector(state)?.isCheckUp;
export const isPropertyOwnerSelector = (state: ApplicationState) =>
  caseCreationSelector(state).isPropertyOwner;
export const isPropertyOccupancySelector = (state: ApplicationState) =>
  caseCreationSelector(state).isPropertyOccupancy;
export const currentLocationSelector = (state: ApplicationState) =>
  caseCreationSelector(state).currentLocation;
export const isPropertyOwnerLabelSelector = (state: ApplicationState) =>
  caseCreationSelector(state).isPropertyOwner ? 'option-yes' : 'option-no';
export const decisionTreeSelector = (
  state: ApplicationState,
): DecisionTreeDataResponseModel => state.caseCreation.decisionTree;
export const decisionTreeResultSelector = (
  state: ApplicationState,
): DecisionTreeDataResultModel => decisionTreeSelector(state).result;
export const decisionTreeUnavailabilityPeriodSelector = (
  state: ApplicationState,
): string | undefined =>
  decisionTreeResultSelector(state)?.unavailabilityPeriod;
export const decisionTreeUnavailabilityDescriptionSelector = (
  state: ApplicationState,
): string | undefined =>
  decisionTreeResultSelector(state)?.unavailabilityDescription;
export const decisionTreeHasBeenClosedAvailabilityBannerSelector = (
  state: ApplicationState,
): boolean =>
  decisionTreeResultSelector(state)?.hasBeenClosedAvailabilityBanner;
export const decisionTreeDropOffSelector = (
  state: ApplicationState,
): boolean | undefined => decisionTreeResultSelector(state)?.dropout;
export const decisionTreeDropOffContentSelector = (
  state: ApplicationState,
): string | undefined => decisionTreeResultSelector(state)?.content;
export const decisionTreeShortDescriptionSelector = (
  state: ApplicationState,
): string | undefined => decisionTreeResultSelector(state)?.shortDescription;
export const decisionTreeOptionPathSelector = (
  state: ApplicationState,
): string[] => decisionTreeSelector(state).optionPath || [];
export const incidentDateSelector = (state: ApplicationState) =>
  caseCreationSelector(state).incidentDate;
export const incidentDetailsSelector = (state: ApplicationState) =>
  caseCreationSelector(state).incidentDetails;
export const phoneCountryCodeContactSelector = (state: ApplicationState) =>
  caseCreationSelector(state).phoneCountryCodeContact;
export const phoneNumberContactSelector = (state: ApplicationState) =>
  caseCreationSelector(state).phoneNumberContact;
export const lastNameContactSelector = (state: ApplicationState) =>
  caseCreationSelector(state).lastNameContact;
export const firstNameContactSelector = (state: ApplicationState) =>
  caseCreationSelector(state).firstNameContact;
export const caseCreatedSelector = (
  state: ApplicationState,
): CaseSuccessResponseModel => state.caseCreation.response;
export const caseGuidCreatedSelector = (
  state: ApplicationState,
): string | undefined => caseCreatedSelector(state)?.caseGuid;
export const caseReferenceCreatedSelector = (
  state: ApplicationState,
): string | undefined => caseCreatedSelector(state)?.caseReference;
export const savedPicturesSelector = (state: ApplicationState) =>
  caseCreationSelector(state).pictures || [];
export const areDetailsVisibleSelector = (
  state: ApplicationState,
): boolean | undefined => state.caseCreation.areDetailsVisible;
export const identificationStepSelector = (state: ApplicationState) => {
  const caseCreationData = caseCreationSelector(state);
  return Object.values(identificationOptionsSelector(state))
    .flatMap(el => el)
    .map(({ element }) => caseCreationData[element]);
};
export const skipContactDetailsSelector = (state: ApplicationState) =>
  caseCreationSelector(state).skipContactDetails;
