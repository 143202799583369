import { AppInsights } from 'applicationinsights-js';
import TagManager from 'react-gtm-module';
import { noop } from 'utils/global-utils';
import { getRouteData } from './gtm-helper';

const applicationinsightsKey = 'AI';
const loggerKey = 'LOG';
const gtmKey = 'GTM';

export const DATALAYER_NAME = 'gtm';

export const getPathForEVent = path => {
  if (path) {
    const listOfDomain = path.split('/');
    return `/${listOfDomain[listOfDomain.length - 1]}`;
  }
  return 'N/A';
};

const colorBlue = 'color: blue';
const colorGreen = 'color: green';
const colorRed = 'color: red';

export const initLog = hasGtmId => ({
  name: loggerKey,
  dataLayer: hasGtmId
    ? action =>
        console.log(
          `%c [GTM] %c ${JSON.stringify(action)}`,
          colorBlue,
          colorGreen,
        )
    : noop,
  event: ({
    eventaction,
    eventcategory,
    eventlabel,
    shouldLogEventOnConsole = true,
  }) => {
    shouldLogEventOnConsole &&
      console.log(
        `%c [${eventcategory}] %c ${eventaction} %c ${eventlabel}`,
        colorBlue,
        colorGreen,
        colorRed,
      );
  },
  pageview: path =>
    console.info(
      `%c pageview: %c ${getPathForEVent(path)}`,
      colorBlue,
      colorGreen,
    ),
});

const initGTM = (gtm, dataLayerName, customPageViewDataLayers) => {
  TagManager.initialize({
    ...gtm,
    dataLayerName,
  });
  return {
    name: gtmKey,
    dataLayer: tagManagerEvent => {
      TagManager.dataLayer({
        dataLayer: tagManagerEvent,
        dataLayerName: DATALAYER_NAME,
      });
    },
    event: ({ eventaction, eventcategory, eventlabel = [] }) => {
      eventlabel.forEach(label => {
        if (eventaction) {
          TagManager.dataLayer({
            dataLayer: {
              eventaction,
              eventcategory,
              event: 'uaevent',
              eventlabel: label,
            },
            dataLayerName: DATALAYER_NAME,
          });
        }
      });
    },
    pageview: (path, overwrite) => {
      return TagManager.dataLayer({
        dataLayer: {
          ...getRouteData(path, customPageViewDataLayers),
          ...overwrite,
          event: 'virtualpageview',
        },
        dataLayerName: DATALAYER_NAME,
      });
    },
  };
};

const initAI = instrumentationKey => {
  AppInsights.downloadAndSetup({ instrumentationKey });
  return {
    name: applicationinsightsKey,
    dataLayer: noop,
    event: ({ eventaction, eventcategory, payload }) =>
      AppInsights.trackEvent(eventaction, {
        eventcategory,
        url: window.location.origin,
        payload: JSON.stringify(payload),
      }),
    pageview: path => AppInsights.trackPageView(path),
    isInternalTrack: true,
  };
};

window.providers = {
  obligatory: [],
  optional: [],
};
window.obligatory = {
  queueEvents: [],
  pageViewsQueue: [],
  dataLayersQueue: [],
};
window.optional = {
  queueEvents: [],
  pageViewsQueue: [],
  dataLayersQueue: [],
};

const sendPendingEvents = accessLevel => {
  Object.keys(eventTypes).forEach(eventType => {
    if (window[accessLevel]?.[eventType].length > 0) {
      window[accessLevel][eventType].forEach(
        ({
          accessLevel,
          eventaction,
          eventcategory,
          eventlabel,
          tagManagerEvent,
          dataLayerName,
          path,
          payload,
          shouldLogEventOnConsole,
          onlyInternalTrackers,
        }) => {
          const sendFunction = eventTypes[eventType].func;
          sendFunction({
            accessLevel,
            eventaction,
            eventcategory,
            eventlabel,
            tagManagerEvent,
            dataLayerName,
            path,
            payload,
            shouldLogEventOnConsole,
            onlyInternalTrackers,
          });
        },
      );
      window[accessLevel][eventType] = [];
    } else if (eventType === 'pageViewsQueue' && window.lastPageView) {
      const sendFunction = eventTypes[eventType].func;
      sendFunction({ ...window.lastPageView, accessLevel });
    } else if (eventType === 'dataLayersQueue' && window.lastDataLayer) {
      const sendFunction = eventTypes[eventType].func;
      sendFunction({ ...window.lastDataLayer, accessLevel });
    }
  });
};

const getAccessLevel = {
  [loggerKey]: 'obligatory',
  [applicationinsightsKey]: 'obligatory',
  [gtmKey]: 'optional',
};

const isInitialized = providerName => {
  const accessLevel = getAccessLevel[providerName];
  return window.providers[accessLevel].find(
    ({ name }) => name === providerName,
  );
};

export function init({
  instrumentationKey,
  logger,
  gtm,
  customPageViewDataLayers,
}) {
  if (logger && !isInitialized(loggerKey)) {
    window.providers.obligatory.push(initLog(gtm?.gtmId));
  }
  if (instrumentationKey && !isInitialized(applicationinsightsKey)) {
    window.providers.obligatory.push(initAI(instrumentationKey));
  }
  if (gtm?.gtmId && !isInitialized(gtmKey)) {
    window.providers.optional.push(
      initGTM(gtm, DATALAYER_NAME, customPageViewDataLayers),
    );
    sendPendingEvents('optional');
  }
  sendPendingEvents('obligatory');
}

export function disableAnalytics() {
  window.providers.optional = window.providers.optional.filter(
    ({ name }) => name !== gtmKey,
  );
  delete window.optional;
}

export const isProviderInitialized = () =>
  Object.values(window.providers).flat().length > 0;

const sendEvent = ({
  accessLevel,
  eventaction,
  eventcategory,
  eventlabel,
  payload,
  shouldLogEventOnConsole,
  onlyInternalTrackers,
}) =>
  window.providers[accessLevel].forEach(p => {
    if (onlyInternalTrackers) {
      if (p.isInternalTrack) {
        p.event({
          eventaction,
          eventcategory,
          eventlabel,
          payload,
          shouldLogEventOnConsole,
        });
      }
      return;
    }
    p.event({
      eventaction,
      eventcategory,
      eventlabel,
      payload,
      shouldLogEventOnConsole,
    });
  });

const sendPageView = ({ accessLevel, path, overwrite }) =>
  window.providers[accessLevel].forEach(p => p.pageview(path, overwrite));

const sendDataLayer = ({ accessLevel, tagManagerEvent, dataLayerName }) => {
  window.providers[accessLevel].forEach(p =>
    p.dataLayer(tagManagerEvent, dataLayerName),
  );
};
const eventTypes = {
  dataLayersQueue: {
    func: sendDataLayer,
  },
  queueEvents: {
    func: sendEvent,
  },
  pageViewsQueue: {
    func: sendPageView,
  },
};

const handleQueue =
  type =>
  (
    func,
    {
      eventaction,
      eventcategory,
      eventlabel,
      tagManagerEvent,
      dataLayerName,
      path,
      overwrite,
      payload,
      shouldLogEventOnConsole,
      onlyInternalTrackers,
    },
  ) => {
    ['obligatory', 'optional'].forEach(accessLevel => {
      if (window.providers[accessLevel].length > 0) {
        func({
          accessLevel,
          eventaction,
          eventcategory,
          eventlabel,
          tagManagerEvent,
          dataLayerName,
          path,
          overwrite,
          payload,
          shouldLogEventOnConsole,
          onlyInternalTrackers,
        });
      } else {
        if (window[accessLevel]) {
          window[accessLevel][type].push({
            accessLevel,
            eventaction,
            eventcategory,
            eventlabel,
            tagManagerEvent,
            dataLayerName,
            path,
            overwrite,
            payload,
            shouldLogEventOnConsole,
            onlyInternalTrackers,
          });
        }
        if (type === 'pageViewsQueue') {
          window.lastPageView = {
            path,
            overwrite,
          };
        }
        if (type === 'dataLayersQueue') {
          window.lastDataLayer = {
            tagManagerEvent,
            dataLayerName,
          };
        }
      }
    });
  };

export function event(
  eventaction,
  eventcategory = 'EVENT',
  eventlabel = [''],
  payload = {},
  shouldLogEventOnConsole = true,
  onlyInternalTrackers = false,
) {
  handleQueue('queueEvents')(sendEvent, {
    eventaction,
    eventcategory,
    eventlabel,
    payload,
    shouldLogEventOnConsole,
    onlyInternalTrackers,
  });
}

export function pageview(path, overwrite) {
  handleQueue('pageViewsQueue')(sendPageView, { path, overwrite });
}

export function dataLayer(tagManagerEvent, dataLayerName) {
  handleQueue('dataLayersQueue')(sendDataLayer, {
    tagManagerEvent,
    dataLayerName,
  });
}
