import { GET_BROWSER_LOCATION_START, SET_BROWSER_LOCATION } from './types';

export const getBrowserLocation = () => ({
  type: GET_BROWSER_LOCATION_START,
});

export const setBrowserLocation = payload => ({
  type: SET_BROWSER_LOCATION,
  payload,
});
