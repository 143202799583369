import { SET_SCHEDULE, CLEAR_SCHEDULES } from 'redux/actions/types';

const initState = {
  schedules: [
    { id: 0, start_at: null, end_at: null },
    { id: 1, start_at: null, end_at: null },
    { id: 2, start_at: null, end_at: null },
  ],
};

const updateSchedule = (schedules, payload) => {
  return schedules.map(schedule => {
    if (schedule.id === payload.id) {
      return payload;
    }
    return schedule;
  });
};

function appointmentReducer(state = initState, action) {
  switch (action.type) {
    case SET_SCHEDULE:
      return {
        ...state,
        schedules: [...updateSchedule(state.schedules, action.payload)],
      };
    case CLEAR_SCHEDULES:
      return { ...initState };
    default:
      return state;
  }
}

export default appointmentReducer;
