import { call, put, select, take } from 'redux-saga/effects';
import { dataSelector } from 'redux/selectors/common';
import firebase from 'firebase/app';
import 'firebase/messaging';
import { eventChannel } from 'redux-saga';
import { initializeFCM } from 'utils/firebaseService';
import actions from 'redux/actions/missionActions';
const { updateAppMission } = actions;

export function* getRefreshSaga() {
  const caseOrder = yield select(dataSelector);
  const { customerNotificationOption } = caseOrder;

  if (customerNotificationOption === 'FCM') {
    try {
      let messaging;
      if (!firebase.apps.length) {
        ({ messaging } = yield call(initializeFCM));
      } else {
        messaging = yield call(firebase.messaging);
      }
      const fcmChannel = yield call(subscribeToChannel, messaging);
      while (true) {
        yield take(fcmChannel);
        yield put(updateAppMission({ immediate: true }));
      }
    } catch (error) {
      console.log('FCM not available: ', error);
    }
  }
  yield put(updateAppMission({}));
}

const subscribeToChannel = messaging =>
  eventChannel(emitter => {
    let unsubscribe;
    try {
      unsubscribe = messaging.onMessage(emitter);
    } catch (error) {
      console.log('SUBSCRIBE ERROR:', error);
      throw error;
    }
    return unsubscribe;
  });
