import {
  stepOrderSelector,
  shouldDisplayLandingPageWithPlateNumberSelector,
} from 'redux/selectors/config';
import { call, put, select } from 'redux-saga/effects';
import {
  caseCreationEditModeSelector,
  decisionTreeResultSelector,
} from 'redux/selectors/caseCreation';
import { currentStepSelector, maxStepSelector } from 'redux/selectors/step';
import { push } from 'connected-react-router';
import { updateMaxStep } from 'redux/actions/stepActions';
import routes from 'Routes/routesConfig';
import caseCreationActions from 'redux/actions/caseCreationActions';
import { resetOpenCaseDataFromGtm } from 'redux/actions/gtmActions';
import { getStepsAndSubSteps } from './step-saga-helper';
const { removeCaseCreation, toggleCreateCaseIsEditMode } = caseCreationActions;

const shouldGoToSubStep = ({ subStep, orders, step }) =>
  subStep >= 0 && subStep < orders[step].length - 1;
const isTheLastStep = ({ step, orders }) => step === orders.length;
const isTheFirstStep = ({ currentStep, currentSubStep }) =>
  currentStep === 0 && (currentSubStep === 0 || currentSubStep === undefined);

function* getPreviousStep(step) {
  if (step) {
    if (typeof step !== 'string') {
      return step[step.length - 1];
    } else {
      return step;
    }
  }
  const shouldDisplayLandingPageWithPlateNumber = yield select(
    shouldDisplayLandingPageWithPlateNumberSelector,
  );
  return shouldDisplayLandingPageWithPlateNumber ? '/' : 1;
}

function* removeCheckUp(checkUp) {
  if (!checkUp) {
    yield put(removeCaseCreation('isCheckUp'));
  }
}

export function* moveToNextStep() {
  const { checkUp } = yield select(decisionTreeResultSelector);
  const orders = getStepsAndSubSteps(yield select(stepOrderSelector), checkUp);
  const { step, subStep } = yield select(currentStepSelector);
  yield call(removeCheckUp, checkUp);

  let nextStep = '';
  let currentStep;
  let currentSubStep;

  if (isTheLastStep({ step, orders })) {
    return;
  }

  if (shouldGoToSubStep({ subStep, orders, step })) {
    currentStep = step;
    currentSubStep = subStep + 1;
    nextStep = orders[currentStep][currentSubStep];
  } else {
    currentStep = typeof step === 'number' ? step + 1 : 0;
    if (typeof orders[currentStep] !== 'string') {
      currentSubStep = 0;
    }
    nextStep =
      typeof orders[currentStep] === 'object'
        ? orders[currentStep][currentSubStep]
        : orders[currentStep];
  }
  const nextStepIndex = orders.flat().findIndex(step => step === nextStep);
  const { index } = yield select(maxStepSelector);
  if ((index || 0) < nextStepIndex) {
    yield put(updateMaxStep({ index: nextStepIndex, step: nextStep }));
  }
  if (isTheFirstStep({ currentStep, currentSubStep })) {
    yield put(resetOpenCaseDataFromGtm());
  }
  yield put(push(nextStep));
}

export function* moveToPreviousStep() {
  const { checkUp } = yield select(decisionTreeResultSelector);
  const order = getStepsAndSubSteps(yield select(stepOrderSelector), checkUp);
  const isEditMode = yield select(caseCreationEditModeSelector);
  const { step, subStep } = yield select(currentStepSelector);
  let previousStep = routes.SUMMARY;
  if (isEditMode) {
    yield put(toggleCreateCaseIsEditMode());
  } else {
    previousStep =
      subStep > 0
        ? order[step][subStep - 1]
        : yield getPreviousStep(order[step - 1]);
  }
  yield put(push(previousStep));
}
