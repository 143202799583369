import { OPTIMIZE_INIZIALIZED } from 'redux/actions/types';

const defaultState = {
  initiated: false,
};

function analyticsReducer(state = defaultState, action) {
  switch (action.type) {
    case OPTIMIZE_INIZIALIZED:
      return {
        ...state,
        initiated: true,
      };
    default:
      return state;
  }
}

export default analyticsReducer;
