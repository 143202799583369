import { put, call, select } from 'redux-saga/effects';
import caseCreationActions from 'redux/actions/caseCreationActions';
import applicationActions from 'redux/actions/applicationActions';
import {
  currentLanguageSelector,
  decisionTreeFileNameSelector,
} from 'redux/selectors/config';
import { getDecisionTree } from 'api/config';
const { loadDecisionTreeFail } = caseCreationActions;
const { loadDecisionTreeSuccess } = applicationActions;

export default function* loadDecisionTree() {
  try {
    const language = yield select(currentLanguageSelector);
    const decisionTreeFileName = yield select(
      decisionTreeFileNameSelector(language),
    );
    const decisionTree = yield call(getDecisionTree, decisionTreeFileName);
    yield put(loadDecisionTreeSuccess({ decisionTree, language }));
  } catch (error) {
    yield put(loadDecisionTreeFail(error));
  }
}
