import createCase from 'api/createCase';
import { push } from 'connected-react-router';
import { call, put, select } from 'redux-saga/effects';
import caseCreationActions from 'redux/actions/caseCreationActions';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import {
  caseCreationSelector,
  decisionTreeResultSelector,
} from 'redux/selectors/caseCreation';
import {
  selectTenantId,
  selectMoveVersion,
  selectPartnerReference,
} from 'redux/selectors/config';
import { routes } from 'Routes/routesConfig';
import { getSynergySessionToken } from 'utils/sagas-utils';

const { createCaseSuccess, createCaseFail } = caseCreationActions;

const DEFAULT_PLACE_TYPE = 'OTHER';

export const createSynergyCaseSaga = function* () {
  try {
    yield put(startLoad());

    const tenantId = yield select(selectTenantId);
    const moveVersion = yield select(selectMoveVersion);
    const partnerReference = yield select(selectPartnerReference);
    const {
      personName,
      personSurname,
      plateNumber,
      currentLocation,
      phoneNumberContact,
    } = yield select(caseCreationSelector);
    const { code, description } = yield select(decisionTreeResultSelector);

    const caseData = {
      cause: code ? { code, description } : undefined,
      vehicle: {
        registration_number: plateNumber,
      },
      customer: {
        first_name: personName,
        last_name: personSurname,
        contact_phone: {
          number: phoneNumberContact,
        },
      },
      current_location: {
        address: {
          street_address: currentLocation?.address?.formattedAddress,
          country: currentLocation?.address?.country,
          postal_code: currentLocation?.address?.postalCode,
          locality: currentLocation?.address?.locality,
        },
        coordinates: currentLocation?.coordinates,
        place_type: DEFAULT_PLACE_TYPE,
      },
      partner_reference: partnerReference,
    };

    const sessionToken = yield getSynergySessionToken();

    const {
      result: { case_guid: caseGuid, case_id: caseReference, ...result },
      status,
    } = yield call(createCase, {
      body: caseData,
      token: sessionToken,
      tenantId,
      moveVersion,
    });

    if ([200, 201, 202].includes(status)) {
      yield put(createCaseSuccess({ caseGuid, caseReference }));
      yield put(push(status === 201 ? routes.CONFIRMATION : routes.CALLBACK));
    } else {
      yield put(createCaseFail(result));
      yield put(push(routes.CONTACT_US));
    }
  } catch (error) {
    yield put(createCaseFail(error?.message || error));
    yield put(push(routes.CONTACT_US));
  } finally {
    yield put(stopLoad());
  }
};
