import {
  GET_PLATE,
  GET_PLATE_UNNECESSARY,
  GET_PLATE_SUCCESS,
  GET_PLATE_FAIL,
  CLEAR_PLATE_READY,
} from './types';
import { CarDetailsData } from 'ts/types/car-details.types';
import { createAction } from './action-utils';

const actions = {
  getPlate: (plateNumber: string) => createAction(GET_PLATE, { plateNumber }),
  getPlateUnnecessary: () => createAction(GET_PLATE_UNNECESSARY, {}),
  getPlateSuccess: (carDetailsData: CarDetailsData) =>
    createAction(GET_PLATE_SUCCESS, carDetailsData),
  getPlateFail: (error: Error) => createAction(GET_PLATE_FAIL, error),
  clearPlateReady: () => createAction(CLEAR_PLATE_READY, {}),
};

export default actions;

const actionList = Object.values(actions).map(action => {
  return {} as ReturnType<typeof action>;
});
export type CarDetailsActionType = typeof actionList[number];
