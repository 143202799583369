import {
  ANALYTICS_INITIATION_SUCCESS,
  ACCEPT_ANALYTICS,
  REFUSE_ANALYTICS,
} from 'redux/actions/types';

const defaultState = {
  initiated: false,
  accepted: false,
};

function analyticsReducer(state = defaultState, action) {
  switch (action.type) {
    case ANALYTICS_INITIATION_SUCCESS:
      return {
        ...state,
        initiated: true,
      };
    case ACCEPT_ANALYTICS:
      return {
        ...state,
        accepted: true,
      };
    case REFUSE_ANALYTICS:
      return {
        ...state,
        accepted: false,
      };
    default:
      return state;
  }
}

export default analyticsReducer;
