import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const ErrorRoundIcon = ({ title, theme, isFloating }) => (
  <Icon isFloating={isFloating}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      title={title}
    >
      <title>{title}</title>
      <g fill="none" fillRule="nonzero" transform="translate(3 3)">
        <circle
          cx="9"
          cy="9"
          r="10.5"
          fill={theme.errorColor}
          stroke={theme.onErrorColor}
          strokeWidth="3"
        />
        <path
          fill={theme.onErrorColor}
          d="M4.419 12.125h9.162L9 4.206l-4.581 7.919zm5-1.25H8.58v-.831h.838v.831zm0-1.669H8.58V7.544h.838v1.662z"
        />
      </g>
    </svg>
  </Icon>
);

ErrorRoundIcon.propTypes = {
  title: PropTypes.string,
  isFloating: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
};

ErrorRoundIcon.defaultProps = {
  title: 'Error title',
  isFloating: false,
};

export default withTheme(ErrorRoundIcon);
