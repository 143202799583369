export const getBrowserLocation = () =>
  new Promise((resolve, rejected) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) =>
          resolve({ latitude, longitude }),
        err => rejected(err),
      );
    } else {
      rejected(new Error('geolocation not supported by this browser'));
    }
  });
