import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideError } from 'redux/actions/errorActions';
import { errorSelector } from 'redux/selectors/error';
import { useTranslate } from 'hooks/translation-hooks';
import Modal from '../Modal';

const ErrorAlert = () => {
  const { isModalVisible, title, body, button } = useSelector(errorSelector);
  const dispatch = useDispatch();
  const t = useTranslate();

  const handleClose = useCallback(() => dispatch(hideError()), [dispatch]);

  useEffect(() => {
    document
      .getElementById('modal-errors')
      .addEventListener('click', handleClose);

    return () => {
      document
        .getElementById('modal-errors')
        .removeEventListener('click', handleClose);
    };
  }, [dispatch, handleClose]);

  const titleLabel = title ? t(title) : t('error_popup-title');
  const bodyLabel = body ? t(body) : t('error_popup-text_1');
  const buttonLabel = button ? t(button) : t('error_popup-button_1');

  return (
    <>
      {isModalVisible && (
        <div>
          <Modal
            type="error"
            status="ERROR"
            title={titleLabel}
            body={bodyLabel}
            primaryButtonLabel={buttonLabel}
            onClickPrimaryButton={handleClose}
            isVisible
          />
        </div>
      )}
    </>
  );
};

export default ErrorAlert;
