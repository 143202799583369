import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const REACT_APP_PICTURE_CMX_URL = process.env.REACT_APP_PICTURE_CMX_URL;
const PICTURE_URL = process.env.REACT_APP_PICTURE_URL;

export const postPicture = async ({ caseGuid, missionOrderId, picture }) => {
  const { id, type } = picture;
  return await fetchApi(PICTURE_URL, {
    params: {
      caseGuid,
      missionOrderId,
    },
    body: {
      documentId: id,
      fileExtension: type,
    },
    method: 'POST',
  }).then(async rs => {
    const data = getProps(rs, 'json') && (await rs.json());
    return { status: rs.status, ok: rs.ok, result: data };
  });
};

export const uploadPictureToCMX = async ({ picture, recaptchaToken }) => {
  const { name, type, currentFile } = picture;
  return await fetchApi(REACT_APP_PICTURE_CMX_URL, {
    headers: {
      'content-disposition': `attachment; filename="${name}"`,
      'content-type': type,
      'g-recaptcha': recaptchaToken,
    },
    body: new Blob([currentFile], { type }),
    method: 'POST',
    hasToStringifyBody: false,
  }).then(async rs => {
    const data = getProps(rs, 'json') && (await rs.json());
    return { status: rs.status, ok: rs.ok, result: data?.id };
  });
};

const pictureDefaultActions = { postPicture, uploadPictureToCMX };

export default pictureDefaultActions;
