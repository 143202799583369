import { LOAD_START, LOAD_STOP } from 'redux/actions/types';

function loadingReducer(state = false, action) {
  switch (action.type) {
    case LOAD_START:
      return true;
    case LOAD_STOP:
      return false;
    default:
      return state;
  }
}

export default loadingReducer;
