import { call, put } from 'redux-saga/effects';
import {
  getHistorySuccess,
  getHistoryFail,
} from 'redux/actions/historyActions';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import getHistory from 'api/history';

export const parseHistory = ({ history }) => {
  return history
    .filter(({ type }) => type !== 'NOTIFICATION_SENT')
    .map(event => {
      if (event.type === 'PICTURE_UPLOADED') {
        return {
          type: 'PICTURE_UPLOADED',
          pictures: event.links.map(link => ({
            file_name: link,
          })),
          id: event.id,
          rawEventDate: event.rawEventDate,
        };
      }
      return event;
    });
};

const addStepCardsOnHistoryCaseLevel = ({ historyCase }) => {
  const historyWithSteps = [];
  historyCase.forEach(historyPerMission =>
    historyWithSteps.push(parseHistory({ history: historyPerMission })),
  );
  return historyWithSteps
    .flat()
    .sort(({ rawDate: A }, { rawDate: B }) => new Date(B) - new Date(A));
};

const getHistoryEventWithSteps = ({ params, history }) => {
  let historyWithSteps;
  if (params.caseGuid) {
    historyWithSteps = addStepCardsOnHistoryCaseLevel({ historyCase: history });
  } else {
    historyWithSteps = parseHistory({ history });
  }
  return historyWithSteps;
};

export function* getHistorySaga({ payload: { params } }) {
  try {
    yield put(startLoad());
    const response = yield call(getHistory, { params });
    const {
      result: { history },
    } = response;
    const historyWithSteps = getHistoryEventWithSteps({ params, history });
    yield put(getHistorySuccess(historyWithSteps));
  } catch (error) {
    yield put(getHistoryFail(error?.message || error));
  } finally {
    yield put(stopLoad());
  }
}
