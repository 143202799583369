import RedirectionCapability from 'hoc/RedirectionCapability';
import {
  CurrentNotification,
  FAQPage,
  HelpfulTips,
  History,
  ReadOnlyHistory,
  ScheduleAppointment,
  Case,
  Intro,
  NotFound,
  UploadPicturesFollowUp,
  UploadPicturesOpening,
  Contact,
  CaseAddress,
  CaseOpening,
  CaseIdentification,
  CaseIncidentDescription,
  IncidentDescription,
  CaseIncidentDate,
  ContactDetails,
  SuccessPage,
  ErrorPage,
  DropOffPage,
  DropOffSuccessPage,
  SummaryPage,
  PreviewPage,
  CustomStep,
  LandingPage,
  FlowSelector,
  CallbackPage,
  ConfirmationPage,
  LocationPage,
  SynergyIncidentDescription,
  ContactUsPage,
  ChatRoom,
  SynergyPassengers,
  VehicleDetails,
} from './LoadableComponents';
import { BusinessCategory } from 'constants/synergy';

export const routes = {
  NOTIFICATION_LEGACY: '/:id/notification',
  INTRO_LEGACY: '/:id/intro',
  HISTORY_LEGACY: '/:id/history',
  CASE_LEGACY: '/:id/case',
  UPLOAD_PICTURES_LEGACY: '/:id/upload-pictures',
  HISTORY_DISABLED_LEGACY: '/:id/history-readonly',
  HISTORY_CASE_LEGACY: '/:id/history-case',
  FAQ_LEGACY: '/:id?/faq',
  SCHEDULE_APPOINTMENT_LEGACY: '/:id/schedule-appointment',
  HELPFUL_TIPS_LEGACY: '/:id?/helpful_tips',
  NOT_FOUND: '/not-found',
  NOT_FOUND_WITH_ID: '/:id/not-found',
  CONTACT_LEGACY: '/:id/contact',
  CASE_OPENING: '/',
  CALLBACK: '/callback',
  CONFIRMATION: '/confirmation',
  INCIDENT_DESCRIPTION: '/incident/description',
  INCIDENT_DATE: '/incident/date',
  INCIDENT_MORE: '/incident/more',
  INCIDENT_PHOTOS: '/incident/photos',
  IDENTIFICATION: '/identification',
  ADDRESS: '/address',
  CONTACT_DETAILS: '/contact_details',
  SUMMARY: '/summary',
  SUCCESS: '/success',
  ERROR: '/error',
  DROPOFF: '/dropoff',
  DROPOFF_SUCCESS: '/dropoff_success',
  PREVIEW: '/preview',
  GENERAL_PAGE: '/:id/:page',
  FLOW_SELECTOR: '/flow-selector',
  LOCATION: '/map',
  CONTACT_US: '/contact_us',
  CHATBOT_LEGACY: '/:id/message-us',
  PASSENGERS: '/passengers',
  CASE: '/case/:id',
  CHATBOT: '/message-us/:id',
  CONTACT: '/contact/:id',
  FAQ: '/faq/:id',
  HELPFUL_TIPS: '/helpful_tips/:id',
  HISTORY: '/history/:id',
  HISTORY_CASE: '/history-case/:id',
  HISTORY_DISABLED: '/history-readonly/:id',
  INTRO: '/intro/:id',
  NOTIFICATION: '/notification/:id',
  SCHEDULE_APPOINTMENT: '/schedule-appointment/:id',
  UPLOAD_PICTURES: '/upload-pictures/:id',
  VEHICLE_DETAILS: '/vehicle-details',
  FOLLOW_MY_CASE: '/follow-my-case',
};

export const commonRoutes = [
  {
    id: routes.FAQ,
    path: routes.FAQ,
    component: FAQPage,
    exact: true,
    name: 'faq',
  },
  {
    id: routes.HELPFUL_TIPS,
    path: routes.HELPFUL_TIPS,
    component: HelpfulTips,
    exact: true,
    name: 'helpful_tips',
  },
  {
    id: routes.FAQ_LEGACY,
    path: routes.FAQ_LEGACY,
    component: FAQPage,
    exact: true,
    name: 'faq',
  },
  {
    id: routes.HELPFUL_TIPS_LEGACY,
    path: routes.HELPFUL_TIPS_LEGACY,
    component: HelpfulTips,
    exact: true,
    name: 'helpful_tips',
  },
];

const HISTORY = 'history';
const followUpRoutes = [
  {
    id: routes.NOTIFICATION,
    path: routes.NOTIFICATION,
    component: CurrentNotification,
    exact: true,
    name: 'notification',
  },
  {
    id: routes.NOTIFICATION_LEGACY,
    path: routes.NOTIFICATION_LEGACY,
    component: CurrentNotification,
    exact: true,
    name: 'notification',
  },
  {
    id: routes.HISTORY,
    path: routes.HISTORY,
    component: History,
    exact: true,
    name: HISTORY,
  },
  {
    id: routes.HISTORY_LEGACY,
    path: routes.HISTORY_LEGACY,
    component: History,
    exact: true,
    name: HISTORY,
  },
  {
    id: routes.HISTORY_DISABLED,
    path: routes.HISTORY_DISABLED,
    component: ReadOnlyHistory,
    exact: true,
    name: HISTORY,
  },
  {
    id: routes.HISTORY_DISABLED_LEGACY,
    path: routes.HISTORY_DISABLED_LEGACY,
    component: ReadOnlyHistory,
    exact: true,
    name: HISTORY,
  },
  {
    id: routes.HISTORY_CASE,
    path: routes.HISTORY_CASE,
    component: History,
    exact: true,
    name: 'history-case',
  },
  {
    id: routes.HISTORY_CASE_LEGACY,
    path: routes.HISTORY_CASE_LEGACY,
    component: History,
    exact: true,
    name: 'history-case',
  },
  {
    id: routes.NOT_FOUND,
    path: routes.NOT_FOUND,
    component: NotFound,
    exact: true,
  },
  {
    id: routes.SCHEDULE_APPOINTMENT,
    path: routes.SCHEDULE_APPOINTMENT,
    component: ScheduleAppointment,
    exact: true,
    name: 'schedule-appointment',
  },
  {
    id: routes.SCHEDULE_APPOINTMENT_LEGACY,
    path: routes.SCHEDULE_APPOINTMENT_LEGACY,
    component: ScheduleAppointment,
    exact: true,
    name: 'schedule-appointment',
  },
  {
    id: routes.UPLOAD_PICTURES,
    path: routes.UPLOAD_PICTURES,
    component: UploadPicturesFollowUp,
    exact: true,
    name: 'notification',
  },
  {
    id: routes.UPLOAD_PICTURES_LEGACY,
    path: routes.UPLOAD_PICTURES_LEGACY,
    component: UploadPicturesFollowUp,
    exact: true,
  },
  {
    id: routes.PREVIEW,
    path: routes.PREVIEW,
    component: PreviewPage,
  },
];

export const caseOpeningRoutes = [
  {
    id: routes.CASE_OPENING,
    path: routes.CASE_OPENING,
    component: CaseOpening,
    exact: true,
  },
  {
    id: routes.CONTACT_DETAILS,
    path: routes.CONTACT_DETAILS,
    component: ContactDetails,
    exact: true,
  },
  {
    id: routes.INCIDENT_DESCRIPTION,
    path: routes.INCIDENT_DESCRIPTION,
    component: IncidentDescription,
    exact: true,
  },
  {
    id: routes.INCIDENT_DATE,
    path: routes.INCIDENT_DATE,
    component: CaseIncidentDate,
    exact: true,
  },
  {
    id: routes.INCIDENT_MORE,
    path: routes.INCIDENT_MORE,
    component: CaseIncidentDescription,
    exact: true,
  },
  {
    id: routes.INCIDENT_PHOTOS,
    path: routes.INCIDENT_PHOTOS,
    component: UploadPicturesOpening,
    exact: true,
  },
  {
    id: routes.IDENTIFICATION,
    path: routes.IDENTIFICATION,
    component: CaseIdentification,
    exact: true,
  },
  {
    id: routes.ADDRESS,
    path: routes.ADDRESS,
    component: CaseAddress,
    exact: true,
  },
  {
    id: routes.SUMMARY,
    path: routes.SUMMARY,
    component: SummaryPage,
    exact: true,
  },
  {
    id: routes.SUCCESS,
    path: routes.SUCCESS,
    component: SuccessPage,
    exact: true,
  },
  {
    id: routes.ERROR,
    path: routes.ERROR,
    component: ErrorPage,
    exact: true,
  },
  {
    id: routes.DROPOFF,
    path: routes.DROPOFF,
    component: DropOffPage,
    exact: true,
  },
  {
    id: routes.DROPOFF_SUCCESS,
    path: routes.DROPOFF_SUCCESS,
    component: DropOffSuccessPage,
    exact: true,
  },
];

const synergyRoutes = [
  {
    id: routes.CASE_OPENING,
    path: routes.CASE_OPENING,
    component: LandingPage,
    exact: true,
  },
  {
    id: routes.LOCATION,
    path: routes.LOCATION,
    component: LocationPage,
    exact: true,
  },
  {
    id: routes.CALLBACK,
    path: routes.CALLBACK,
    component: CallbackPage,
    exact: true,
  },
  {
    id: routes.SUMMARY,
    path: routes.SUMMARY,
    component: SummaryPage,
  },
  {
    id: routes.CONFIRMATION,
    path: routes.CONFIRMATION,
    component: ConfirmationPage,
    exact: true,
  },
  {
    id: routes.FLOW_SELECTOR,
    path: routes.FLOW_SELECTOR,
    component: FlowSelector,
  },
  {
    id: routes.INCIDENT_DESCRIPTION,
    path: routes.INCIDENT_DESCRIPTION,
    component: SynergyIncidentDescription,
  },
  {
    id: routes.CONTACT_US,
    path: routes.CONTACT_US,
    component: ContactUsPage,
    exact: true,
  },
  {
    id: routes.PASSENGERS,
    path: routes.PASSENGERS,
    component: SynergyPassengers,
  },
  {
    id: routes.VEHICLE_DETAILS,
    path: routes.VEHICLE_DETAILS,
    component: VehicleDetails,
  },
  {
    id: routes.FOLLOW_MY_CASE,
    path: routes.FOLLOW_MY_CASE,
    component: null,
  },
];

export const routesConfig = ({
  customCaseCreationSteps,
  showCaseCreationPage,
  showCasePage,
  showIntro,
  showContactPage,
  showChatBotPage,
  businessCategory,
}) => {
  const routesFromConfig = [...commonRoutes, ...followUpRoutes];

  showIntro &&
    routesFromConfig.push(
      {
        id: routes.INTRO,
        path: routes.INTRO,
        component: RedirectionCapability(Intro, showIntro),
        exact: true,
      },
      {
        id: routes.INTRO_LEGACY,
        path: routes.INTRO_LEGACY,
        component: RedirectionCapability(Intro, showIntro),
        exact: true,
      },
    );

  showCasePage &&
    routesFromConfig.push(
      {
        id: routes.CASE,
        path: routes.CASE,
        component: RedirectionCapability(Case, showCasePage),
        exact: true,
      },
      {
        id: routes.CASE_LEGACY,
        path: routes.CASE_LEGACY,
        component: RedirectionCapability(Case, showCasePage),
        exact: true,
      },
    );
  showContactPage &&
    routesFromConfig.push(
      {
        id: routes.CONTACT,
        path: routes.CONTACT,
        component: Contact,
        exact: true,
        name: 'contact',
      },
      {
        id: routes.CONTACT_LEGACY,
        path: routes.CONTACT_LEGACY,
        component: Contact,
        exact: true,
        name: 'contact',
      },
    );
  showChatBotPage &&
    routesFromConfig.push(
      {
        id: routes.CHATBOT,
        path: routes.CHATBOT,
        component: ChatRoom,
        exact: true,
      },
      {
        id: routes.CHATBOT_LEGACY,
        path: routes.CHATBOT_LEGACY,
        component: ChatRoom,
        exact: true,
      },
    );
  if (showCaseCreationPage) {
    const customRoutes = customCaseCreationSteps.map(({ path }) => ({
      id: path,
      path,
      component: CustomStep,
      exact: true,
    }));
    caseOpeningRoutes.unshift(...customRoutes);
    synergyRoutes.unshift(...customRoutes);
    businessCategory === BusinessCategory.MOTOR
      ? routesFromConfig.push.apply(routesFromConfig, synergyRoutes)
      : routesFromConfig.push.apply(routesFromConfig, caseOpeningRoutes);
  }
  routesFromConfig.push(DEFAULT_HANDLER_ROUTE);
  return routesFromConfig;
};

const DEFAULT_HANDLER_ROUTE = {
  path: '*',
  id: '*',
  component: NotFound,
};

export default routes;
