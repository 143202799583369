import {
  ANALYTICS_INITIATION_SUCCESS,
  ANALYTICS_DEACTIVATION_SUCCESS,
  ACCEPT_ANALYTICS,
  REFUSE_ANALYTICS,
  GLOBAL_EVENT_CONSENT_ONETRUST,
} from './types';

export const analyticsInitiationSuccess = () => ({
  type: ANALYTICS_INITIATION_SUCCESS,
});

export const analyticsDeactivationSuccess = () => ({
  type: ANALYTICS_DEACTIVATION_SUCCESS,
});

export const acceptAnalytics = payload => ({
  type: ACCEPT_ANALYTICS,
  payload,
});

export const refuseAnalytics = payload => ({
  type: REFUSE_ANALYTICS,
  payload,
});

export const oneTrustEvents = payload => ({
  type: GLOBAL_EVENT_CONSENT_ONETRUST,
  payload,
});
