import { select, put, take } from 'redux-saga/effects';
import {
  addGtmOpenCaseDataLayer,
  addGtmPageView as pageview,
} from 'redux/actions/gtmActions';
import { notTrackedPages, getNextStep } from './route-saga-helper';
import { stepOrderSelector } from 'redux/selectors/config';
import { updateCurrentStep, updateMaxStep } from 'redux/actions/stepActions';
import { SET_CONFIGURATION_SUCCESS } from 'redux/actions/types';
import { push } from 'connected-react-router';
import { maxStepSelector } from 'redux/selectors/step';
import routes from 'Routes/routesConfig';
import {
  decisionTreeSelector,
  decisionTreeResultSelector,
} from 'redux/selectors/caseCreation';
import caseCreationActions from 'redux/actions/caseCreationActions';
import { getStepsAndSubSteps } from 'redux/sagas/step/step-saga-helper';
import {
  businessCategorySelector,
  customStepsSelector,
  showCaseCreationPageSelector,
  showCasePageSelector,
  showContactPageSelector,
  showChatBotPageSelector,
  showIntroSelector,
} from 'redux/selectors/config';
import { dataSelector } from 'redux/selectors/common';
import { routesConfig } from 'Routes/routesConfig';
import { getCurrentPath } from 'hooks/routes-hooks';
import { searchSelector } from 'redux/selectors/router';
const { cleanInstantDropOffData, enableSkipIdentification } =
  caseCreationActions;
const skipIdentificationPageCampaigns = ['personal_space'];

function* trackPageView(nextPage) {
  const pageNodes = nextPage.split('/').slice(-2);
  if (
    nextPage &&
    !notTrackedPages.some(notTrackedPage => pageNodes.includes(notTrackedPage))
  ) {
    yield put(pageview(nextPage));
  }
}

export function* trackPageViewOnRouteChange(action) {
  const { location } = action.payload;
  const nextPage = location?.pathname;
  yield trackPageView(nextPage);
}

function* skipIdentificationPageIfNeeded() {
  const search = yield select(searchSelector);
  if (
    skipIdentificationPageCampaigns.some(campaign =>
      search.includes(`utm_campaign=${campaign}`),
    )
  ) {
    yield put(enableSkipIdentification());
  }
}

export function* moveCurrentStep(action) {
  const { location } = action.payload;
  const nextPage = location?.pathname;
  yield skipIdentificationPageIfNeeded();
  let orders = yield select(stepOrderSelector);
  if (!orders) {
    yield take(SET_CONFIGURATION_SUCCESS);
    orders = yield select(stepOrderSelector);
  }
  const decisionTreeResult = yield select(decisionTreeResultSelector);
  orders = getStepsAndSubSteps(orders, decisionTreeResult?.checkUp);
  const flattenOrders = orders.flat();
  const nextPageIndex = flattenOrders.findIndex(step => step === nextPage);
  const decisionTreeIndex = flattenOrders.findIndex(
    step => step === routes.INCIDENT_DESCRIPTION,
  );
  const { index, step } = yield select(maxStepSelector);
  const { result } = yield select(decisionTreeSelector);

  if (nextPage === routes.DROPOFF && result?.dropout === 'instant') {
    yield put(cleanInstantDropOffData());
    if (decisionTreeIndex < index) {
      yield put(
        updateMaxStep({
          index: decisionTreeIndex,
          step: routes.INCIDENT_DESCRIPTION,
        }),
      );
    }
  }
  if (nextPageIndex >= 0) {
    if ((index || 0) < nextPageIndex) {
      yield put(push(step || routes.CASE_OPENING));
    }
    const nextStep = getNextStep(orders, nextPage);
    const funnel_step = nextStep.step + 1;
    if (funnel_step) {
      yield put(
        addGtmOpenCaseDataLayer({
          funnel_step,
        }),
      );
    }
    yield put(updateCurrentStep(nextStep));
  }
}

export function* goHome() {
  const showCasePage = yield select(showCasePageSelector);
  const caseOrder = yield select(dataSelector);
  const showCaseCreationPage = yield select(showCaseCreationPageSelector);
  const businessCategory = yield select(businessCategorySelector);
  const search = yield select(searchSelector);
  const showIntro = yield select(showIntroSelector);
  const showContactPage = yield select(showContactPageSelector);
  const showChatBotPage = yield select(showChatBotPageSelector);
  const customCaseCreationSteps = yield select(customStepsSelector);

  const currentRoutes = routesConfig({
    showCaseCreationPage,
    showCasePage,
    showIntro,
    showContactPage,
    showChatBotPage,
    customCaseCreationSteps,
    businessCategory,
  }).map(({ path }) => path);
  const currentRoute = getCurrentPath(currentRoutes);

  const isFollowUp = currentRoute.params['id'];

  if (isFollowUp) {
    if (showCasePage) {
      yield put(
        push(
          routes.CASE.replace(':id', caseOrder.caseGuid || isFollowUp) + search,
        ),
      );
    } else {
      yield put(
        push(
          routes.NOTIFICATION.replace(
            ':id',
            caseOrder.missionOrderId || isFollowUp,
          ) + search,
        ),
      );
    }
  } else {
    yield put(push(routes.CASE_OPENING + search));
  }
}
