import { routeToPageLayer } from 'mappers/routes';
import { specialWaitings } from 'constants/mission';

export const getRouteData = (route, customRoutePageLayer) => {
  const allRoutesToPageLayer = { ...routeToPageLayer, ...customRoutePageLayer };
  const [page_name, page_type, virtual_page_url] =
    allRoutesToPageLayer[route] || routeToPageLayer['not-found'];
  return {
    page_name,
    page_type,
    virtual_page_url,
  };
};

const ACCEPTABLE_DESCRIPTIONS = ['WAITING', ...specialWaitings];

export const getEventCategory = (type, description) => {
  const route =
    type === 'WAITING' && ACCEPTABLE_DESCRIPTIONS.includes(description)
      ? description || type
      : type;
  const [eventcategory] =
    routeToPageLayer[route] || routeToPageLayer['not-found'];
  return eventcategory;
};
