import PropTypes from 'prop-types';
import CheckMarkIcon from '../CheckMarkIcon';
import SuccessIcon from '../SuccessIcon';
import WaitingIcon from '../WaitingIcon';
import ErrorIcon from '../ErrorIcon';
import ErrorRoundIcon from '../ErrorRoundIcon';
import DropOutIcon from '../DropOutIcon/DropOutIcon';

export const StatusIcon = props => {
  const { status } = props;
  switch (status) {
    case 'LOADED':
    case 'SENT':
      return <SuccessIcon {...props} />;
    case 'SAVED':
      return <CheckMarkIcon {...props} />;
    case 'ERROR':
      return <ErrorIcon {...props} />;
    case 'DELAYED':
      return <ErrorIcon {...props} />;
    case 'NOT_SAVED':
      return <ErrorRoundIcon {...props} />;
    case 'WAITING':
      return <WaitingIcon {...props} />;
    case 'DROPOUT':
      return <DropOutIcon {...props} />;
    default:
      return null;
  }
};

StatusIcon.propTypes = {
  status: PropTypes.string,
};

export default StatusIcon;
