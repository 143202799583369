import { providerToCountry } from 'mappers/providers';

export const getConfigData = config => {
  const {
    keys: {
      config_file,
      partner_name,
      business_category,
      global_digital_asset,
      journey_type,
      provider,
    },
  } = config;
  return {
    country: providerToCountry[provider],
    partner_id: config_file,
    partner_name,
    journey_type,
    global_digital_asset,
    business_line: business_category,
    main_linked_system: provider,
    instance_environment: process.env.REACT_APP_ABODE_ENV.toUpperCase(),
  };
};

export const getCaseAndMissionDataFromCase = (data, id) => ({
  case_reference: data.caseReference || '',
  hashed_case_id: data.caseGuid || id,
  funnel_step: '',
});

export const getCaseAndMissionDataFromMission = (data, id) => ({
  incident_category: data.providerType || '',
  incident_subcategory: data.jobDescription || '',
  case_reference: data.caseId || '',
  mission_numbers: 1,
  mission_id: data.missionOrderId || id,
  hashed_case_id: data.caseGuid || '',
  provider_name: data.providerName || '',
  funnel_step: data.step || '',
});
