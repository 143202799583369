import {
  GET_PROVIDER_ROUTE_DURATION_FAIL,
  GET_PROVIDER_ROUTE_DURATION_START,
  GET_PROVIDER_ROUTE_DURATION_SUCCESS,
  GET_LOCATION_FAIL,
  GET_LOCATION_START,
  GET_LOCATION_SUCCESS,
  UPDATE_LOCATION,
} from './types';

export const getLocationStart = payload => ({
  type: GET_LOCATION_START,
  payload,
});

export const getLocationSuccess = payload => ({
  type: GET_LOCATION_SUCCESS,
  payload,
});

export const getLocationFail = payload => ({
  type: GET_LOCATION_FAIL,
  payload,
});

export const updateLocation = payload => ({
  type: UPDATE_LOCATION,
  payload,
});

export const getLocationDurationStart = payload => ({
  type: GET_PROVIDER_ROUTE_DURATION_START,
  payload,
});

export const getLocationDurationSuccess = payload => ({
  type: GET_PROVIDER_ROUTE_DURATION_SUCCESS,
  payload,
});

export const getLocationDurationFail = payload => ({
  type: GET_PROVIDER_ROUTE_DURATION_FAIL,
  payload,
});
