export function listenToWindowEvent(
  dispatch,
  name,
  mapEventToAction,
  filter = () => true,
) {
  const handleEvent = e => {
    if (e.target?.origin) {
      const { origin } = e.target;
      const validOrigins = [
        'localhost',
        '.local',
        'abode-global.com',
        'mihogarfacil.es',
        'axa.es',
        'minegociofacil.es',
        'habitation.clicassist.fr',
        'homemanager.link',
      ];
      if (
        validOrigins.some(validOrigin => origin.includes(validOrigin)) &&
        filter(e)
      ) {
        dispatch(mapEventToAction(e));
      }
    }
  };
  window.addEventListener(name, handleEvent);
  return () => window.removeEventListener(name, handleEvent);
}
