import { SET_CAMERA } from 'redux/actions/types';

const defaultState = {
  isCameraOpen: false,
};

function cameraReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_CAMERA:
      return {
        isCameraOpen: action.payload,
      };
    default:
      return state;
  }
}

export default cameraReducer;
