import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { tr } from 'utils/translation-utils';
import { businessCategorySelector } from 'redux/selectors/config';
import { BusinessCategory, TranslationDirectory } from 'constants/synergy';

export const useTranslate = (module?: string | undefined): Function => {
  const { t } = useTranslation();
  return tr(t, module);
};

export const useTranslateByProject = (): Function => {
  const businessCategoryName = useSelector(businessCategorySelector);
  const projectName =
    businessCategoryName === BusinessCategory.MOTOR
      ? TranslationDirectory.MOTOR
      : TranslationDirectory.HOME;
  return useTranslate(projectName);
};
