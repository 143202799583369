import type { FC } from 'react';
import type { CounterorProps } from './Counteror.types';
import { Container, Button } from './styled';

const Counteror: FC<CounterorProps> = ({
  testId,
  current,
  handleAdd,
  handleRemove,
}) => (
  <Container>
    <Button onClick={handleRemove} data-testid={`${testId}-removeBtn`}>
      -
    </Button>
    <span data-testid={`${testId}-content`}>{current}</span>
    <Button onClick={handleAdd} data-testid={`${testId}-addBtn`}>
      +
    </Button>
  </Container>
);

export default Counteror;
