import { ApplicationState } from 'redux/reducers';
import {
  Configuration,
  ConfigurationKeys,
  DecisionTreeNode,
  GtmPerEnv,
  IdentificationPage,
  MenuLink,
  PoliciesData,
  Reason,
  RegexData,
  StepOrderType,
  SummaryItemData,
  Theme,
  TimeSlotData,
} from 'ts/types/config.types';
import { StringMap } from 'ts/types/common.types';

const configSelector = (state: ApplicationState): Configuration =>
  state.application.configuration;

export const configKeySelector = (state: ApplicationState): ConfigurationKeys =>
  configSelector(state).keys || {};

export const partnerSelector = (state: ApplicationState): string =>
  configKeySelector(state).config_file;

export const partnerNameSelector = (state: ApplicationState): string =>
  configKeySelector(state).partner_name;

export const selectTheme = (state: ApplicationState): Theme =>
  configSelector(state)?.theme;

export const selectFavIcon = (state: ApplicationState): string =>
  configKeySelector(state).favicon;

export const selectBrandImage = (state: ApplicationState): string =>
  configKeySelector(state).brand_image;

export const selectGlobalHeaderTitle = (state: ApplicationState): string =>
  configKeySelector(state).global_header_title;

export const selectBrand = (state: ApplicationState): string =>
  configKeySelector(state).brand;

export const selectMetadata = (state: ApplicationState): string | undefined =>
  configKeySelector(state).metas;

export const selectShowPrivacyPolicyModal = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_private_policy_modal;

export const shouldDisplayTitleOnSummaryPageSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_title_on_summary_page;

export const shouldDisplayProblemTitleOnSummaryPageSelector = (
  state: ApplicationState,
): boolean =>
  configKeySelector(state).should_display_problem_title_on_summary_page;

export const shouldDisplaySummaryDetailsSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_summary_details;

export const isSummaryBlackTitleModeSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).is_summary_black_title_mode;
export const shouldDisplayStepHeaderSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_step_header;

export const selectShowDisplayBrandHeader = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_brand_header;

export const selectMenuLinks = (state: ApplicationState): MenuLink[] =>
  configSelector(state)?.menu_links || [];

export const selectShowPropertyOccupancy = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_property_occupancy;

export const selectShowUnreachableCallUsButton = (
  state: ApplicationState,
): boolean =>
  configKeySelector(state).should_display_unreachable_call_us_button;

export const selectPolicies = (state: ApplicationState): PoliciesData =>
  configSelector(state).policies;

export const currentLanguageSelector = (
  state: ApplicationState,
): string | undefined => state.application.currentLanguage;

export const selectLanguage = (state: ApplicationState): string =>
  configKeySelector(state).default_language;

export const selectLanguages = (state: ApplicationState): string[] =>
  configSelector(state).supported_languages;

export const selectProvider = (state: ApplicationState): string =>
  configKeySelector(state).provider;

export const selectCookiesUrlPolicies = (
  state: ApplicationState,
): string | undefined => selectPolicies(state)?.cookies_policy?.url;

export const selectDateFormat = (state: ApplicationState): string =>
  configKeySelector(state).date_format;
export const selectLongDateFormat = (state: ApplicationState): string =>
  configKeySelector(state).long_date_format;
export const shortDateFormatSelector = (state: ApplicationState): string =>
  configKeySelector(state).short_date_format;

export const selectPrivatePoliciesUrl = (
  state: ApplicationState,
): string | undefined => selectPolicies(state)?.privacy_policy?.url;

export const selectTermsAndConditionsPoliciesUrl = (
  state: ApplicationState,
): string | undefined =>
  selectPolicies(state)?.terms_and_conditions_policy?.url;

export const availableRescheduleDaysSelector = (
  state: ApplicationState,
): boolean[] => [
  configKeySelector(state).should_enable_sunday,
  configKeySelector(state).should_enable_monday,
  configKeySelector(state).should_enable_tuesday,
  configKeySelector(state).should_enable_wednesday,
  configKeySelector(state).should_enable_thursday,
  configKeySelector(state).should_enable_friday,
  configKeySelector(state).should_enable_saturday,
];

export const showAddCalendarConfigSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_add_calendar;

export const calendarDefaultHoursSelector = (state: ApplicationState): number =>
  configKeySelector(state).calendar_default_hours;

export const availabilityHoursSelector = (
  state: ApplicationState,
): { start_hours: string[]; end_hours: string[] } => ({
  start_hours: configSelector(state).appointment_reschedule_start_hours,
  end_hours: configSelector(state).appointment_reschedule_end_hours,
});

export const timeSlotOptionsSelector = (
  state: ApplicationState,
): TimeSlotData[] => configSelector(state).time_slot_options;

export const showStepsSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_step_header_steps;

export const showNameSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_step_header_name;

export const showCaseCreationPageSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_creation_page;

export const showCaseReferenceSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_case_reference;

export const showCasePageSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_case_page;

export const showLookingForProviderSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_looking_for_provider;

export const alertTimeoutSelector = (state: ApplicationState): number =>
  configKeySelector(state).alert_timeout;

export const alertSpecialSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_special_alert;

export const alertBannerSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_banner_alert;

export const copyrightLabelSelector = (state: ApplicationState): string =>
  configKeySelector(state).copyright_label;

export const contactPagePhoneSelector = (state: ApplicationState): string =>
  configKeySelector(state).contact_phone;

export const showContactPageSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_contact_page;

export const showChatBotPageSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_chat_bot_page;

export const shouldShowReasonSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_contact_reasons;

export const reasonsSelector = (state: ApplicationState): Reason[] =>
  configSelector(state).contact_reasons;

export const shouldInitiateClaritySelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).is_clarity_enabled;

export const contactPhoneSelector = (state: ApplicationState): string =>
  configKeySelector(state).default_dropout_phone;

export const stepOrderSelector = (state: ApplicationState): StepOrderType => {
  const stepOrder = configSelector(state).step_order;
  return state.caseCreation.skipIdentification && stepOrder
    ? configSelector(state)
        .step_order.filter(path => path !== '/identification')
        .map(step => {
          if (typeof step !== 'string') {
            return step.filter(path => path !== '/identification');
          }
          return step;
        })
    : stepOrder;
};

export const identificationOptionsSelector = (
  state: ApplicationState,
): IdentificationPage => configSelector(state).identification;

export const summaryConfigSelector = (
  state: ApplicationState,
): SummaryItemData[] => configSelector(state).summary_config;

export const showNextStepsCaseCreationSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_next_steps;

export const zipCodePatternSelector = (state: ApplicationState): string =>
  configKeySelector(state).zip_code_pattern;

export const decisionTreeSelector =
  (language: string) =>
  (state: ApplicationState): DecisionTreeNode =>
    configSelector(state).decisionTree?.[language];

export const decisionTreeFileNameSelector =
  (language: string) =>
  (state: ApplicationState): string =>
    configSelector(state).decision_tree?.[language];

export const decisionTreeTextToHideSelector = (
  state: ApplicationState,
): string[] => configSelector(state).decision_tree_text_to_hide;

export const maxNumberOfPicturesSelector = (state: ApplicationState): number =>
  configKeySelector(state).maximum_number_of_pictures;

export const backgroundHomeUrlSelector = (state: ApplicationState): string =>
  configKeySelector(state).background_home_url;

export const showLegalDisclaimerSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_legal_disclaimer;

export const showTipsSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_tips;

export const oneTrustSelector = (state: ApplicationState): StringMap =>
  configSelector(state).one_trust_env;

export const dataDomainScriptIdSelector = (state: ApplicationState): string =>
  oneTrustSelector(state) &&
  oneTrustSelector(state)[process.env.REACT_APP_ABODE_ENV?.toLowerCase() || 0];

export const showIntroSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_intro_page;
export const showSurveysSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_show_surveys;

export const siteInterceptSurveysSelector = (
  state: ApplicationState,
): StringMap => configSelector(state).site_intercept_surveys;
export const optimizeIDSelector = (state: ApplicationState): string =>
  configKeySelector(state).optimize_id;
export const optimizeExperimentsSelector = (state: ApplicationState): any =>
  configSelector(state).optimize_experiments_list;
export const shouldShowContinueModalSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_pictures_continue_modal;
export const enablePicturesSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_enable_pictures;
export const notificationsModalIntervalTimeSelector = (
  state: ApplicationState,
): number => configKeySelector(state).notifications_modal_interval_time;

export const shouldShowEmailSelector = (state: ApplicationState): boolean =>
  configKeySelector(state).should_display_email;
export const regexSelectors = (
  state: ApplicationState,
): { [_: string]: RegexData } => configSelector(state)?.regex;

export const buttonBehaviourIfSuccessSelector = (
  state: ApplicationState,
): string => configKeySelector(state).button_behaviour_if_success;
export const buttonBehaviourIfDropOffSelector = (
  state: ApplicationState,
): string => configKeySelector(state).button_behaviour_if_dropoff;
export const buttonBehaviourIfErrorSelector = (
  state: ApplicationState,
): string => configKeySelector(state).button_behaviour_if_error;

export const customStepsSelector = (state: ApplicationState) =>
  configSelector(state).custom_steps;

export const shouldDisplayRequestNotificationsPopupSelector = (
  state: ApplicationState,
): boolean =>
  configKeySelector(state).should_display_request_notifications_popup;
export const notificationAutoRefreshTimeSecSelector = (
  state: ApplicationState,
): number => configKeySelector(state).notification_auto_refresh_time_sec;
export const numberOfOccupancyDaysSelector = (
  state: ApplicationState,
): number => configKeySelector(state).occupancy_days;
export const caseCreationMaxRetriesSelector = (
  state: ApplicationState,
): string => configKeySelector(state).case_creation_max_retries;
export const missionIdentifierSelector = (state: ApplicationState): string =>
  configKeySelector(state).mission_identifier;
export const missionDescriptionSelector = (state: ApplicationState): string =>
  configKeySelector(state).mission_description;
export const isMissionIdentifierUpperCaseSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).is_mission_identifier_upper_case;
export const shouldDisplayMissionHistoryLinkSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_mission_history_link;
export const shouldDisplayFAQButtonSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_faq_button;
export const maximumMbSizeOfPicturesSelector = (
  state: ApplicationState,
): number => configKeySelector(state).maximum_mb_size_of_picture;
export const gtmEnvSelector = (state: ApplicationState): GtmPerEnv =>
  configSelector(state).gtm_env;
export const gtmIdSelector = (state: ApplicationState): string =>
  configKeySelector(state).gtm_id;

export const businessCategorySelector = (state: ApplicationState): string =>
  configKeySelector(state).business_category;

export const pagesWithoutHeaderSelector = (state: ApplicationState): string[] =>
  configSelector(state).pages_without_header;

export const pagesWithoutFooterSelector = (state: ApplicationState): string[] =>
  configSelector(state).pages_without_footer;

export const shouldDisplayRepudiationCallbackSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_repudiation_callback;

export const shouldDisplayConfirmationButton = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_confirmation_button;

export const selectLandingImage = (state: ApplicationState): string =>
  configKeySelector(state).landing_image;

export const selectTenantId = (state: ApplicationState): string =>
  configKeySelector(state).tenant_id;

export const selectMoveVersion = (state: ApplicationState): string =>
  configKeySelector(state).move_version;

export const selectPartnerReference = (state: ApplicationState): number =>
  configKeySelector(state).partner_reference;

export const contactUsImage = (state: ApplicationState): string =>
  configKeySelector(state).contact_us_image;

export const numberOfFAQCaseOpeningQuestionsSelector = (
  state: ApplicationState,
): number => configKeySelector(state).number_of_faq_case_opening_questions;

export const numberOfFAQQuestionsSelector = (state: ApplicationState): number =>
  configKeySelector(state).number_of_faq_questions;

export const numberOfHelpfulTipsCaseOpeningSelector = (
  state: ApplicationState,
): number => configKeySelector(state).number_of_case_opening_helpful_tips;

export const numberOfHelpfulTipsSelector = (state: ApplicationState): number =>
  configKeySelector(state).number_of_helpful_tips;

export const selectShouldDisplayDecisionTreeIcons = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_display_decision_tree_icons;

export const selectShouldHighlightLetterInDecisionTree = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_highlight_letter_in_decision_tree;

export const selectShouldShowDecisionTreeOptionsNotSelectedInLightGreyColor = (
  state: ApplicationState,
): boolean =>
  configKeySelector(state)
    .should_show_decision_tree_options_not_selected_in_light_grey_color;

export const shouldAppointmentInteractWithCmsSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_appointment_interact_with_cms;

export const shouldDisplayLandingPageWithPlateNumberSelector = (
  state: ApplicationState,
): boolean =>
  configKeySelector(state).should_display_landing_page_with_plate_number;

export const shouldDisplayProviderDetailsSelector = (
  state: ApplicationState,
): boolean => configKeySelector(state).should_provider_details_be_expanded;

export const eventsWithProviderDetailsSelector = (
  state: ApplicationState,
): string[] => configSelector(state).events_with_provider_details;

export const selectPassengerAdultOptions = (
  state: ApplicationState,
): { option: string }[] => configSelector(state).passenger_adult_options;

export const selectPassengerChildOptions = (
  state: ApplicationState,
): { option: string }[] => configSelector(state).passenger_children_options;
