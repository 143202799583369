import {
  BrowSerLocationState,
  BrowSerLocationAction,
} from 'ts/types/browser-location.types';
import { SET_BROWSER_LOCATION } from 'redux/actions/types';

const initState: BrowSerLocationState = {
  coordinates: undefined,
};

function applicationReducer(
  state: BrowSerLocationState = initState,
  action: BrowSerLocationAction,
) {
  switch (action.type) {
    case SET_BROWSER_LOCATION:
      return {
        coordinates: action.payload,
      };
    default:
      return state;
  }
}

export default applicationReducer;
