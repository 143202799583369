const historyDataLayer = [
  'full_case_history',
  'service_follow_up',
  '/full_case_history',
];

const BOOKING_APPOINTMENT = 'booking_appointment';
export const routeToPageLayer = {
  case: ['home', 'homepage', '/home'],
  CASE_REVIEW: ['case_review', 'case_review', '/case_review'],
  contact: ['contact_us', 'help', '/contact_us'],
  faq: ['faq', 'help', '/faq'],
  helpful_tips: ['helpful_tips', 'help', '/helpful_tips'],
  history: historyDataLayer,
  'history-case': historyDataLayer,
  'history-readonly': historyDataLayer,
  LOOKING_FOR_PROVIDER: [
    'looking_for_provider',
    'looking_for_provider',
    '/looking_for_provider',
  ],
  'not-found': ['not_found', 'dropoff', '/not_found'],
  'schedule-appointment': [
    'appointment_options',
    BOOKING_APPOINTMENT,
    '/appointment_options',
  ],
  'schedule-appointment-slot1': [
    'appointment_option_1',
    BOOKING_APPOINTMENT,
    '/appointment_option_1',
  ],
  'schedule-appointment-slot2': [
    'appointment_option_2',
    BOOKING_APPOINTMENT,
    '/appointment_option_2',
  ],
  'schedule-appointment-slot3': [
    'appointment_option_3',
    BOOKING_APPOINTMENT,
    '/appointment_option_3',
  ],
  tutorial_step_1: ['onboarding_step1', 'onboarding', '/onboarding_step1'],
  tutorial_step_2: ['onboarding_step2', 'onboarding', '/onboarding_step2'],
  tutorial_step_3: ['onboarding_step3', 'onboarding', '/onboarding_step3'],
  '/': ['home', 'homepage', '/home'],
  '/address': [
    'incident_location',
    'incident_risk-location',
    '/incident_location',
  ],
  '/contact_details': [
    'personal_details',
    'identification',
    '/personal_details',
  ],
  '/dropoff': [
    'request_confirmation_failed',
    'drop_off',
    '/request_confirmation_failed',
  ],
  '/error': [
    'request_confirmation_error',
    'error_page',
    '/request_confirmation_error',
  ],
  '/identification': [
    'contract_registration',
    'identification',
    '/contract_registration',
  ],
  '/incident/date': ['incident_date', 'incident_details', '/incident_date'],
  '/incident/description': [
    'diagnosis_incident',
    'incident_diagnosis',
    '/diagnosis_incident',
  ],
  '/incident/more': [
    'diagnosis_details',
    'incident_diagnosis',
    '/diagnosis_details',
  ],
  '/success': [
    'request_confirmation',
    'confirmation_page',
    '/request_confirmation',
  ],
  '/summary': ['request_summary', 'summary_page', '/request_summary'],
  AFFECTED_REQUEST: [
    'affected_request',
    'service_standby',
    '/affected_request',
  ],
  AFFECTED_UNREACHABLE: [
    'affected_unreachable',
    'not_reached_requester',
    '/affected_unreachable',
  ],
  APPOINTMENT_CONFIRMATION_REQUIRED: [
    'appointment_confirmation_required',
    BOOKING_APPOINTMENT,
    '/appointment_confirmation_required',
  ],
  APPOINTMENT_REFUSED: [
    'appointment_declined',
    BOOKING_APPOINTMENT,
    '/appointment_declined',
  ],
  APPOINTMENT_WITH_RESCHEDULE: [
    'appointment_confirmed',
    'appointment_scheduled',
    '/appointment_confirmed',
  ],
  APPOINTMENT_CONFIRMED: [
    'appointment_confirmed',
    'appointment_scheduled',
    '/appointment_confirmed',
  ],
  APPOINTMENT_REMINDER: [
    'contractor_appointment_confirmed',
    'appointment_scheduled ',
    '/contractor_appointment_confirmed',
  ],
  CONTRACTOR_LEAVES: [
    'waiting_for_contractor_information',
    'fixing_the_problem',
    '/waiting_for_contractor_information',
  ],
  DELAY_OF_ARRIVAL_TIME: [
    'service_delay',
    'fixing_the_problem',
    '/service_delay',
  ],
  EN_ROUTE: [
    'contractor_on_the_way',
    'fixing_the_problem',
    '/contractor_on_the_way',
  ],
  EN_ROUTE_WITH_TRACKING: [
    'contractor_on_the_way_with_provider',
    'fixing_the_problem',
    '/contractor_on_the_way_with_provider',
  ],
  EXPERT_REQUEST: ['expert_request', 'service_standby', '/expert_request'],
  EXTRA_ACTIONS_REQUIRED: [
    'extra_actions_required',
    'claim_closed',
    '/extra_actions_required',
  ],
  INSURED_REQUEST: ['insured_request', 'service_standby', '/insured_request'],
  INSURED_UNREACHABLE: [
    'insured_unreachable',
    'not_reached_requester',
    '/insured_unreachable',
  ],
  JOURNEY_FINISHED_ABNORMALLY: [
    'journey_finished_abnormally',
    'dropoff',
    '/journey_finished_abnormally',
  ],
  MISSING_PARTS: [
    'service_on_hold_material',
    'fixing_the_problem',
    '/service_on_hold_material',
  ],
  MISSION_CANCELLED: [
    'mission_canceled',
    BOOKING_APPOINTMENT,
    '/mission_canceled',
  ],
  'MISSION_CANCELLED-CANT_ATTEMPT_WITHIN_SLA_TIME': [
    'mission_cancelled-cant_attempt_within_sla_time',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-cant_attempt_within_sla_time',
  ],
  'MISSION_CANCELLED-DONT_HAVE_RIGHT_LICENCE': [
    'mission_cancelled-dont_have_right_licence',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-dont_have_right_licence',
  ],
  'MISSION_CANCELLED-EQUIPMENT_NOT_AVAILABLE': [
    'mission_cancelled-equipment_not_available',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-equipment_not_available',
  ],
  'MISSION_CANCELLED-NO_REASON': [
    'mission_cancelled-no_reason',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-no_reason',
  ],
  'MISSION_CANCELLED-SOFT_FIX_BY_CUSTOMER': [
    'mission_cancelled-soft_fix_by_customer',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-soft_fix_by_customer',
  ],
  'MISSION_CANCELLED-CUSTOMER-CANT_ATTEMPT_WITHIN_SLA_TIME': [
    'mission_cancelled-customer-cant_attempt_within_sla_time',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-customer-cant_attempt_within_sla_time',
  ],
  'MISSION_CANCELLED-CUSTOMER-DONT_HAVE_RIGHT_LICENCE': [
    'mission_cancelled-customer-dont_have_right_licence',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-customer-dont_have_right_licence',
  ],
  'MISSION_CANCELLED-CUSTOMER-EQUIPMENT_NOT_AVAILABLE': [
    'mission_cancelled-customer-equipment_not_available',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-customer-equipment_not_available',
  ],
  'MISSION_CANCELLED-CUSTOMER-NO_REASON': [
    'mission_cancelled-customer-no_reason',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-customer-no_reason',
  ],
  'MISSION_CANCELLED-CUSTOMER-SOFT_FIX_BY_CUSTOMER': [
    'mission_cancelled-customer-soft_fix_by_customer',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-customer-soft_fix_by_customer',
  ],
  'MISSION_CANCELLED-PROVIDER-CANT_ATTEMPT_WITHIN_SLA_TIME': [
    'mission_cancelled-provider-cant_attempt_within_sla_time',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-provider-cant_attempt_within_sla_time',
  ],
  'MISSION_CANCELLED-PROVIDER-DONT_HAVE_RIGHT_LICENCE': [
    'mission_cancelled-provider-dont_have_right_licence',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-provider-dont_have_right_licence',
  ],
  'MISSION_CANCELLED-PROVIDER-EQUIPMENT_NOT_AVAILABLE': [
    'mission_cancelled-provider-equipment_not_available',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-provider-equipment_not_available',
  ],
  'MISSION_CANCELLED-PROVIDER-NO_REASON': [
    'mission_cancelled-provider-no_reason',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-provider-no_reason',
  ],
  'MISSION_CANCELLED-PROVIDER-SOFT_FIX_BY_CUSTOMER': [
    'mission_cancelled-provider-soft_fix_by_customer',
    BOOKING_APPOINTMENT,
    '/mission_cancelled-provider-soft_fix_by_customer',
  ],
  MISSION_EXPIRED: ['service_expired', 'claim_expired', '/service_expired'],
  ON_SCENE: [
    'service_has_started',
    'fixing_the_problem',
    '/service_has_started',
  ],
  '/incident/photos': [
    'take_photos_incident',
    'claim_received',
    '/take_photos_incident',
  ],
  PICTURES_UPLOAD_REQUIRED: [
    'take_photos_incident',
    'claim_received',
    '/take_photos_incident',
  ],
  PICTURES_UPLOAD_SUCCESS: [
    'photos_incident_uploaded',
    'claim_received',
    '/photos_incident_uploaded',
  ],
  QUOTE_ACCEPTED: ['quote_approved', 'quote_process', '/quote_approved'],
  QUOTE_REFUSED: ['quote_rejected', 'quote_process', '/quote_rejected'],
  WORK_COMPLETED: ['service_finished', 'claim_closed', '/service_finished'],
  WAITING: ['waiting_update', 'claim_received', '/waiting_update'],
  WAITING_APPOINTMENT: [
    'waiting_appointment_confirmation',
    BOOKING_APPOINTMENT,
    '/waiting_appointment_confirmation',
  ],
  WAITING_DRYING: [
    'service_on_hold_drying',
    'fixing_the_problem',
    '/service_on_hold',
  ],
  WAITING_QUOTE_APPROVAL: [
    'waiting_quote_approval',
    'quote_process',
    '/waiting_quote_approval',
  ],
  QUOTE_ON_PAUSE: ['quote_on_pause', 'quote_process', '/quote_on_pause'],
  'message-us': ['chat', 'chat', '/chat'],
};
