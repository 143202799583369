import { useSelector } from 'react-redux';

import {
  alertsSelector,
  selectSpecialAlertStatus,
} from 'redux/selectors/alerts';
import isEmpty from 'utils/is-empty';

import Alert from './Alerts/Alert';
import SpecialAlert from './Alerts/SpecialAlert';
import { AlertManagerGrid, AlertManagerContainer } from './AlertManagerStyled';

const AlertManager = () => {
  const alerts = useSelector(alertsSelector);
  const specialAlert = useSelector(selectSpecialAlertStatus);
  const getAlertItem = alert => <Alert key={alert.id} {...alert} />;
  return (
    <>
      {!isEmpty(specialAlert) && <SpecialAlert />}
      <AlertManagerGrid>
        <AlertManagerContainer>
          {alerts.map(getAlertItem)}
        </AlertManagerContainer>
      </AlertManagerGrid>
    </>
  );
};

export default AlertManager;
