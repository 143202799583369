import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

export const AlertIcon = ({ theme }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="17"
    viewBox="0 0 20 17"
  >
    <path
      fill={theme.specialPopupIconColor}
      fillRule="evenodd"
      d="M.833 16.5h18.334L10 .667.833 16.5zm10-2.5H9.167v-1.667h1.666V14zm0-3.333H9.167V7.333h1.666v3.334z"
    />
  </svg>
);

AlertIcon.propTypes = {
  theme: PropTypes.shape({ errorColor: PropTypes.string }).isRequired,
};

export default withTheme(AlertIcon);
