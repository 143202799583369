export const AFFECTED_REQUEST = 'AFFECTED_REQUEST';
export const AFFECTED_UNREACHABLE = 'AFFECTED_UNREACHABLE';
export const EN_ROUTE = 'EN_ROUTE';
export const EN_ROUTE_WITH_TRACKING = 'EN_ROUTE_WITH_TRACKING';
export const EXPERT_REQUEST = 'EXPERT_REQUEST';
export const EXTRA_ACTIONS_REQUIRED = 'EXTRA_ACTIONS_REQUIRED';
export const INSURED_REQUEST = 'INSURED_REQUEST';
export const INSURED_UNREACHABLE = 'INSURED_UNREACHABLE';
export const MISSION_EXPIRED = 'MISSION_EXPIRED';
export const MISSING_PARTS = 'MISSING_PARTS';
export const WAITING = 'WAITING';
export const WAITING_APPOINTMENT = 'WAITING_APPOINTMENT';
export const WAITING_DRYING = 'WAITING_DRYING';
export const WAITING_QUOTE_APPROVAL = 'WAITING_QUOTE_APPROVAL';
export const WORK_COMPLETED = 'WORK_COMPLETED';
export const QUOTE_ACCEPTED = 'QUOTE_ACCEPTED';
export const QUOTE_REFUSED = 'QUOTE_REFUSED';
export const QUOTE_ON_PAUSE = 'QUOTE_ON_PAUSE';
export const APPOINTMENT_REFUSED = 'APPOINTMENT_REFUSED';
export const CONTRACTOR_LEAVES = 'CONTRACTOR_LEAVES';
export const DELAY_OF_ARRIVAL_TIME = 'DELAY_OF_ARRIVAL_TIME';
export const JOURNEY_FINISHED_ABNORMALLY = 'JOURNEY_FINISHED_ABNORMALLY';
export const MISSION_CREATED = 'MISSION_CREATED';
export const NOTIFICATION_SENT = 'NOTIFICATION_SENT';
export const ON_SCENE = 'ON_SCENE';
export const PICTURES_UPLOAD_REQUIRED = 'PICTURES_UPLOAD_REQUIRED';
export const APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED';
export const APPOINTMENT_CONFIRMATION_REQUIRED =
  'APPOINTMENT_CONFIRMATION_REQUIRED';
export const APPOINTMENT_WITH_RESCHEDULE = 'APPOINTMENT_WITH_RESCHEDULE';
export const APPOINTMENT_REMINDER = 'APPOINTMENT_REMINDER';
export const NO_REASON = 'NO_REASON';
export const MISSION_CANCELLED = 'MISSION_CANCELLED';
export const MISSION_CANCELLED_CANT_ATTEMPT_WITHIN_SLA_TIME =
  'MISSION_CANCELLED-CANT_ATTEMPT_WITHIN_SLA_TIME';
export const MISSION_CANCELLED_DONT_HAVE_RIGHT_LICENCE =
  'MISSION_CANCELLED-DONT_HAVE_RIGHT_LICENCE';
export const MISSION_CANCELLED_EQUIPMENT_NOT_AVAILABLE =
  'MISSION_CANCELLED-EQUIPMENT_NOT_AVAILABLE';
export const MISSION_CANCELLED_NO_REASON = 'MISSION_CANCELLED-NO_REASON';
export const MISSION_CANCELLED_SOFT_FIX_BY_CUSTOMER =
  'MISSION_CANCELLED-SOFT_FIX_BY_CUSTOMER';
export const MISSION_CANCELLED_CUSTOMER_CANT_ATTEMPT_WITHIN_SLA_TIME =
  'MISSION_CANCELLED-CUSTOMER-CANT_ATTEMPT_WITHIN_SLA_TIME';
export const MISSION_CANCELLED_CUSTOMER_DONT_HAVE_RIGHT_LICENCE =
  'MISSION_CANCELLED-CUSTOMER-DONT_HAVE_RIGHT_LICENCE';
export const MISSION_CANCELLED_CUSTOMER_EQUIPMENT_NOT_AVAILABLE =
  'MISSION_CANCELLED-CUSTOMER-EQUIPMENT_NOT_AVAILABLE';
export const MISSION_CANCELLED_CUSTOMER_NO_REASON =
  'MISSION_CANCELLED-CUSTOMER-NO_REASON';
export const MISSION_CANCELLED_CUSTOMER_SOFT_FIX_BY_CUSTOMER =
  'MISSION_CANCELLED-CUSTOMER-SOFT_FIX_BY_CUSTOMER';
export const MISSION_CANCELLED_PROVIDER_CANT_ATTEMPT_WITHIN_SLA_TIME =
  'MISSION_CANCELLED-PROVIDER-CANT_ATTEMPT_WITHIN_SLA_TIME';
export const MISSION_CANCELLED_PROVIDER_DONT_HAVE_RIGHT_LICENCE =
  'MISSION_CANCELLED-PROVIDER-DONT_HAVE_RIGHT_LICENCE';
export const MISSION_CANCELLED_PROVIDER_EQUIPMENT_NOT_AVAILABLE =
  'MISSION_CANCELLED-PROVIDER-EQUIPMENT_NOT_AVAILABLE';
export const MISSION_CANCELLED_PROVIDER_NO_REASON =
  'MISSION_CANCELLED-PROVIDER-NO_REASON';
export const MISSION_CANCELLED_PROVIDER_SOFT_FIX_BY_CUSTOMER =
  'MISSION_CANCELLED-PROVIDER-SOFT_FIX_BY_CUSTOMER';
export const REIMBURSEMENT_CREATED = 'REIMBURSEMENT_CREATED';
export const REIMBURSEMENT_COMPLETED = 'REIMBURSEMENT_COMPLETED';
export const REIMBURSEMENT_COMPLETELY_APPROVED =
  'REIMBURSEMENT_COMPLETELY_APPROVED';
export const REIMBURSEMENT_DOCUMENTS_VALIDATED =
  'REIMBURSEMENT_DOCUMENTS_VALIDATED';
export const REIMBURSEMENT_PARTIALLY_APPROVED =
  'REIMBURSEMENT_PARTIALLY_APPROVED';
export const REIMBURSEMENT_REFUSED = 'REIMBURSEMENT_REFUSED';

export const specialWaitings = [
  AFFECTED_REQUEST,
  AFFECTED_UNREACHABLE,
  EXPERT_REQUEST,
  INSURED_REQUEST,
  INSURED_UNREACHABLE,
  MISSING_PARTS,
  QUOTE_ON_PAUSE,
  WAITING_APPOINTMENT,
  WAITING_DRYING,
  WAITING_QUOTE_APPROVAL,
];

export const missionCompletionSteps = [5, 8];

export const allEventTypes = {
  AFFECTED_REQUEST,
  AFFECTED_UNREACHABLE,
  EN_ROUTE,
  EN_ROUTE_WITH_TRACKING,
  EXPERT_REQUEST,
  EXTRA_ACTIONS_REQUIRED,
  INSURED_REQUEST,
  INSURED_UNREACHABLE,
  MISSION_EXPIRED,
  MISSING_PARTS,
  WAITING,
  WAITING_APPOINTMENT,
  WAITING_DRYING,
  WAITING_QUOTE_APPROVAL,
  WORK_COMPLETED,
  APPOINTMENT_REFUSED,
  CONTRACTOR_LEAVES,
  DELAY_OF_ARRIVAL_TIME,
  JOURNEY_FINISHED_ABNORMALLY,
  MISSION_CREATED,
  NOTIFICATION_SENT,
  ON_SCENE,
  PICTURES_UPLOAD_REQUIRED,
  QUOTE_ACCEPTED,
  QUOTE_REFUSED,
  QUOTE_ON_PAUSE,
  APPOINTMENT_CONFIRMED,
  APPOINTMENT_CONFIRMATION_REQUIRED,
  APPOINTMENT_WITH_RESCHEDULE,
  APPOINTMENT_REMINDER,
  MISSION_CANCELLED,
  MISSION_CANCELLED_CANT_ATTEMPT_WITHIN_SLA_TIME,
  MISSION_CANCELLED_DONT_HAVE_RIGHT_LICENCE,
  MISSION_CANCELLED_EQUIPMENT_NOT_AVAILABLE,
  MISSION_CANCELLED_NO_REASON,
  MISSION_CANCELLED_SOFT_FIX_BY_CUSTOMER,
  MISSION_CANCELLED_CUSTOMER_CANT_ATTEMPT_WITHIN_SLA_TIME,
  MISSION_CANCELLED_CUSTOMER_DONT_HAVE_RIGHT_LICENCE,
  MISSION_CANCELLED_CUSTOMER_EQUIPMENT_NOT_AVAILABLE,
  MISSION_CANCELLED_CUSTOMER_NO_REASON,
  MISSION_CANCELLED_CUSTOMER_SOFT_FIX_BY_CUSTOMER,
  MISSION_CANCELLED_PROVIDER_CANT_ATTEMPT_WITHIN_SLA_TIME,
  MISSION_CANCELLED_PROVIDER_DONT_HAVE_RIGHT_LICENCE,
  MISSION_CANCELLED_PROVIDER_EQUIPMENT_NOT_AVAILABLE,
  MISSION_CANCELLED_PROVIDER_NO_REASON,
  MISSION_CANCELLED_PROVIDER_SOFT_FIX_BY_CUSTOMER,
  REIMBURSEMENT_COMPLETED,
  REIMBURSEMENT_COMPLETELY_APPROVED,
  REIMBURSEMENT_CREATED,
  REIMBURSEMENT_DOCUMENTS_VALIDATED,
  REIMBURSEMENT_PARTIALLY_APPROVED,
  REIMBURSEMENT_REFUSED,
};
