import { takeLatest } from 'redux-saga/effects';
import {
  CREATE_CASE_START,
  CREATE_SYNERGY_CASE,
  CREATE_CASE_RETRY,
  LOAD_DECISION_TREE_START,
  UPDATE_CASE_CREATION,
  RESET_OPEN_CASE_DATA_FROM_GTM,
  UPDATE_CASE_CREATION_DECISION_TREE,
} from 'redux/actions/types';
import { createCaseSaga } from './create-case-saga';
import { createSynergyCaseSaga } from './create-synergy-case-saga';
import {
  updateCaseSaga,
  resetOpenCaseGtmDataLayer,
  updateCaseDTSaga,
} from './update-case-saga';
import { caseCreationRetrySaga } from './case-creation-retry';
import loadDecisionTree from './load-decision-tree-saga';

export default function* createCaseSagas() {
  yield takeLatest(CREATE_CASE_START, createCaseSaga);
  yield takeLatest(CREATE_SYNERGY_CASE, createSynergyCaseSaga);
  yield takeLatest(CREATE_CASE_RETRY, caseCreationRetrySaga);
  yield takeLatest(UPDATE_CASE_CREATION, updateCaseSaga);
  yield takeLatest(UPDATE_CASE_CREATION_DECISION_TREE, updateCaseDTSaga);
  yield takeLatest(RESET_OPEN_CASE_DATA_FROM_GTM, resetOpenCaseGtmDataLayer);
  yield takeLatest(LOAD_DECISION_TREE_START, loadDecisionTree);
}
