/* eslint-disable no-unused-vars */
import {
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
} from 'react';

export enum InputStyledSize {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

type size = {
  height: string;
  fontSize: string;
  padding: string;
};

export interface ThemeProps {
  size: {
    [InputStyledSize.SMALL]: size;
    [InputStyledSize.LARGE]: size;
  };
}

export const InputStyledBaseTheme: ThemeProps = {
  size: {
    [InputStyledSize.SMALL]: {
      height: '48px',
      fontSize: '16px',
      padding: '12px',
    },
    [InputStyledSize.LARGE]: {
      height: '52px',
      fontSize: '20px',
      padding: '12px',
    },
  },
};

export type InputStyledProps = {
  value?: any;
  dataTestId?: string;
  inputSize?: InputStyledSize | keyof typeof InputStyledSize;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  autoComplete?: string;
  isError?: boolean | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
};

export type StyledComponentInput = {
  inputSize: InputStyledSize | keyof typeof InputStyledSize;
  theme: { [key: string]: any };
  isError?: boolean | undefined;
};
