const loadClarity = () => {
  const headTag = document.head;
  window.clarity =
    window.clarity ||
    function () {
      (window.clarity.q = window.clarity.q || []).push(arguments);
    };
  const clarityScript = document.createElement('script');
  clarityScript.async = 1;
  clarityScript.src =
    'https://www.clarity.ms/tag/' + process.env.REACT_APP_CLARITY_KEY;
  headTag.appendChild(clarityScript);
};

export default loadClarity;
