import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import applicationActions from 'redux/actions/applicationActions';
const { setConfigurationStart } = applicationActions;

export const useApplication = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setConfigurationStart());
  }, [dispatch]);
};
