import styled from 'styled-components';

import { Title } from 'globalStyles/Containers';

export const ToolbarContainer = styled.section`
  display: flex;
  margin-left: auto;
`;

export const ToolbarTitle = styled(Title)`
  color: ${({ theme }) => theme.headerTitleColor || theme.headerItemColor};
  font-size: 16px;
  font-weight: 550;
  line-height: 30px;
  margin-left: auto;
  margin-right: 32px;
`;

export const ToolbarItem = styled.div`
  border-right: 1px solid ${({ theme }) => theme.onSurfaceColor};
  margin-right: 8px;
  margin-top: 3px;
  padding: 4px 16px;
  color: ${({ theme }) => theme.headerItemColor};
  font-weight: bold;
`;
