import { LOCATION_CHANGE } from 'connected-react-router';

function routeReducer(state = {}, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const { current } = state;
      const { payload } = action;
      const newPath = payload.location?.pathname || payload;
      if (current === newPath) {
        return state;
      }
      return { prev: state.current, current: newPath };
    default:
      return state;
  }
}

export default routeReducer;
