import {
  CHANGE_LANGUAGE_FAIL,
  CHANGE_LANGUAGE_READY,
  CHANGE_LANGUAGE_START,
  CHANGE_LANGUAGE_SUCCESS,
  GLOBAL_EVENT,
  LOAD_DECISION_TREE_SUCCESS,
  RETRY_LANGUAGE_SETUP,
  SET_CONFIGURATION_FAIL,
  SET_CONFIGURATION_START,
  SET_CONFIGURATION_SUCCESS,
  UPDATE_POLICIES_CONFIGURATION_SUCCESS,
} from './types';
import { GlobalActionModel } from './applicationActions.types';
import {
  Configuration,
  DecisionTree,
  PoliciesData,
} from 'ts/types/config.types';
import { createAction, getActionList } from './action-utils';

export const globalEvent = (payload: any): GlobalActionModel => ({
  type: payload?.type
    ? `${GLOBAL_EVENT}_${payload.type.replace('.', '_').toUpperCase()}`
    : GLOBAL_EVENT,
  payload,
});

const actions = {
  setConfigurationStart: () => createAction(SET_CONFIGURATION_START, {}),
  setConfigurationSuccess: (configuration: Configuration) =>
    createAction(SET_CONFIGURATION_SUCCESS, { configuration }),
  updatePoliciesConfigurationSuccess: (configuration: PoliciesData) =>
    createAction(UPDATE_POLICIES_CONFIGURATION_SUCCESS, { configuration }),
  setConfigurationFail: (error: any) =>
    createAction(SET_CONFIGURATION_FAIL, { error }),
  changeLanguageStart: (language: string) =>
    createAction(CHANGE_LANGUAGE_START, { language }),
  changeLanguageFail: (error: any) =>
    createAction(CHANGE_LANGUAGE_FAIL, { error }),
  changeLanguageSuccess: (language: string) =>
    createAction(CHANGE_LANGUAGE_SUCCESS, { language }),
  changeLanguageReady: (language: string) =>
    createAction(CHANGE_LANGUAGE_READY, { language }),
  retryLanguageSetup: (language: string) =>
    createAction(RETRY_LANGUAGE_SETUP, { language }),
  loadDecisionTreeSuccess: ({
    language,
    decisionTree,
  }: {
    language: string;
    decisionTree: DecisionTree;
  }) =>
    createAction(LOAD_DECISION_TREE_SUCCESS, {
      decisionTree: { language, decisionTree },
    }),
};

export default actions;

const actionList = getActionList(actions);

export type ApplicationActionType = typeof actionList[number];
