import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const BurgerMenuIcon = ({ title, theme, onClick, dataAttributes }) => (
  <Icon onClick={onClick} dataAttributes={dataAttributes}>
    <svg
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={theme.headerItemColor || theme.primaryColor}
        strokeLinecap="round"
        strokeWidth="2"
      >
        <path d="M1 2h20M1 20h20M1 11h20" />
      </g>
    </svg>
  </Icon>
);

BurgerMenuIcon.propTypes = {
  title: PropTypes.string,
  dataAttributes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  theme: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func.isRequired,
};

BurgerMenuIcon.defaultProps = {
  title: 'BurgerMenu title',
  dataAttributes: [],
};

export default withTheme(BurgerMenuIcon);
