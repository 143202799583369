import { defaultFinalCast, defaultPrismicReducer } from './reducers';
import { clean } from './utils';

const Prismic = require('@prismicio/client');

const accessToken = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;
export const prismicEndpoint = process.env.REACT_APP_PRISMIC_ENDPOINT;
const options = {};

let client: any;

const createClient = () => {
  client = Prismic.default.client(prismicEndpoint, { accessToken });
};

export const fetchDataByTag = async (
  tags: string[],
  reducer = defaultPrismicReducer,
  finalCast = defaultFinalCast,
): Promise<any> => {
  let config;
  if (!client) {
    createClient();
  }
  await client.query(
    Prismic.default.Predicates.any('document.tags', tags),
    options,
    function (_: any, response: { results: any[] }) {
      config = finalCast(
        response.results
          .sort((a, b) => tags.indexOf(b.tags[0]) - tags.indexOf(a.tags[0]))
          .map(config => config?.data)
          .map(data => clean(data, false))
          .reduce(reducer, {}),
      );
    },
  );
  return config;
};
