/* eslint-disable react/no-danger */
import { FC } from 'react';
import { LinkStyled, IconContainer } from './Link.styled';
import { LinkProps } from './Link.types';

const Link: FC<LinkProps> = ({
  size,
  dataTestId,
  onClick,
  icon,
  text,
  iconPosition = 'left',
  linkType = 'primary',
  marginBottom = 'none',
  marginLeft = 'none',
  marginRight = 'none',
  marginTop = 'none',
  fontWeight = 300,
  textAlign = 'center',
  ...props
}) => (
  <LinkStyled
    size={size}
    onClick={onClick}
    linkType={linkType}
    marginBottom={marginBottom}
    marginLeft={marginLeft}
    marginRight={marginRight}
    marginTop={marginTop}
    fontWeight={fontWeight}
    textAlign={textAlign}
    data-testid={dataTestId}
    {...props}
  >
    {icon && iconPosition === 'left' && (
      <IconContainer iconPosition={iconPosition}>{icon}</IconContainer>
    )}
    {text}
    {icon && iconPosition === 'right' && (
      <IconContainer iconPosition={iconPosition}>{icon}</IconContainer>
    )}
  </LinkStyled>
);

export default Link;
