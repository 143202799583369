import { useSelector } from 'react-redux';
import { routesConfig } from 'Routes/routesConfig';
import {
  businessCategorySelector,
  customStepsSelector,
  showCaseCreationPageSelector,
  showCasePageSelector,
  showContactPageSelector,
  showChatBotPageSelector,
  showIntroSelector,
} from 'redux/selectors/config';
import { matchPath } from 'react-router';
import Route from 'ts/types/route.type';
import { dataSelector } from '../redux/selectors/common';
import { useLocation } from 'react-router-dom';

export const useSimpleRoutes = () => {
  const showCaseCreationPage = useSelector(showCaseCreationPageSelector);
  const showCasePage = useSelector(showCasePageSelector);
  const showIntro = useSelector(showIntroSelector);
  const showContactPage = useSelector(showContactPageSelector);
  const showChatBotPage = useSelector(showChatBotPageSelector);
  const customCaseCreationSteps = useSelector(customStepsSelector);
  const businessCategory = useSelector(businessCategorySelector);
  const routes: Route[] = routesConfig({
    showCaseCreationPage,
    showCasePage,
    showIntro,
    showContactPage,
    showChatBotPage,
    customCaseCreationSteps,
    businessCategory,
  });
  return { routes };
};

export const useRoutes = () => {
  const { routes } = useSimpleRoutes();
  const showCasePage = useSelector(showCasePageSelector);
  const { caseGuid, missionOrderId } = useSelector(dataSelector);
  const { search } = useLocation();
  const currentPath = getCurrentPath(routes.map(({ path }) => path));

  const pageName = routes.find(({ path }) => path === currentPath?.path)?.name;
  const getLinkPage = ({
    destination,
    shouldMaintainId,
  }: {
    destination: string;
    shouldMaintainId?: boolean;
  }) =>
    destination.replace(
      ':id',
      (!shouldMaintainId && ((showCasePage && caseGuid) || missionOrderId)) ||
        currentPath?.params?.id ||
        '',
    ) + search;

  return {
    currentPath,
    getLinkPage,
    pageName,
    routes,
  };
};

export const getCurrentPath = (routesConfig: string[]) =>
  routesConfig
    .map(path =>
      matchPath<{ id?: string }>(window.location.pathname, {
        path,
      }),
    )
    .find(result => result?.isExact);
