import styled from 'styled-components';

export const BannerContainer = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.onPrimaryColor};
  object-fit: contain;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 12.5px 15px;
  border-top: 2px solid ${({ theme }) => theme.specialBannerBorderTop};
  border-bottom: 3px solid ${({ theme }) => theme.specialBannerBorderBottom};
`;

export const BannerIconContainer = styled.div`
  align-self: flex-start;
  margin-right: 10px;
`;

export const BannerTextContainer = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-right: 15px;
  flex-grow: 2;
`;
