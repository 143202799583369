import {
  MenuContainer,
  PhotoContainer,
  MenuButton,
  MenuItem,
} from './MenuStyled';
import { Button } from 'components/alexandria-ui/components';
import { LanguagesList } from 'components/Language';
import routes from 'Routes/routesConfig';
import ArrowMenuIcon from 'components/Icons/ArrowMenuIcon';
import { useTranslate } from 'hooks/translation-hooks';
import { useMenu } from './menu-hooks';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { optimizeExperimentsSelector } from 'redux/selectors/config';
import { isOptimizeInitizializedSelector } from 'redux/selectors/optimize';
import { getExperimentVariant } from 'utils/optimize-utils';

const UploadPhotoLink = ({ isPictureUploadDisabled, redirection }) => {
  const t = useTranslate();
  return (
    <MenuButton
      data-testid="photo_button-click"
      data-action="photo_button-click"
      disabled={isPictureUploadDisabled}
      onClick={redirection}
    >
      <span data-testid="global_menu-upload_photos_disabled">
        {t('global_menu-upload_photos_button')}
      </span>
    </MenuButton>
  );
};

const MenuLink = ({ goToItem, reference, menuItemProps }) => {
  const t = useTranslate();
  const { url, key, action, isButton } = menuItemProps;
  const urlToRedirect = url.replace('/:id', reference ? `/${reference}` : '');
  const text = t(`global-${key.toLowerCase()}_link`);
  return (
    <MenuItem
      key={key}
      data-testid={`menu-item-${key}`}
      onClick={goToItem(urlToRedirect, action)}
      isButton={isButton}
    >
      {isButton ? (
        <MenuButtonItem text={text} />
      ) : (
        <MenuSpanItem text={text} icon={<ArrowMenuIcon />} />
      )}
    </MenuItem>
  );
};

const Menu = ({ toggleMenu, id = 'menu' }) => {
  const {
    menuItems,
    route,
    shouldEnablePictures,
    redirection,
    isPictureUploadDisabled,
    goToItem,
    reference,
    caseGuid,
  } = useMenu({
    toggleMenu,
  });
  const { id: experimentID } =
    useSelector(optimizeExperimentsSelector)?.chatBotImprovement || {};
  const isOptimizeAccepted = useSelector(isOptimizeInitizializedSelector);
  const [variant, setVariant] = useState(0);
  useEffect(() => {
    setVariant(getExperimentVariant(experimentID));
  }, [isOptimizeAccepted, experimentID]);

  return (
    <MenuContainer id={id} data-category="menu">
      {route !== routes.NOT_FOUND && shouldEnablePictures && caseGuid && (
        <PhotoContainer>
          <UploadPhotoLink
            redirection={redirection}
            isPictureUploadDisabled={isPictureUploadDisabled}
          />
        </PhotoContainer>
      )}
      <ul>
        {menuItems
          .filter(({ key }) => key !== 'chat' || variant)
          .map((menuItemProps, key) => (
            <MenuLink
              key={key}
              goToItem={goToItem}
              reference={reference}
              menuItemProps={menuItemProps}
            />
          ))}
      </ul>
      <LanguagesList />
    </MenuContainer>
  );
};

const MenuButtonItem = ({ text }) => {
  return (
    <Button variant="outlined" dataTestId="menu-item-button">
      {text}
    </Button>
  );
};

const MenuSpanItem = ({ text, icon }) => {
  return (
    <>
      {text}
      <span>{icon}</span>
    </>
  );
};

export default Menu;
