import {
  FOLLOW_MY_CASE_FAIL,
  FOLLOW_MY_CASE_START,
  FOLLOW_MY_CASE_SUCCESS,
  GET_CASE_FAIL,
  GET_CASE_START,
  GET_CASE_SUCCESS,
} from './types';

export const getCaseStart = payload => ({
  type: GET_CASE_START,
  payload,
});

export const getCaseSuccess = payload => ({
  type: GET_CASE_SUCCESS,
  payload,
});

export const getCaseFail = error => ({
  type: GET_CASE_FAIL,
  payload: error,
});

export const followMyCaseSuccess = payload => ({
  type: FOLLOW_MY_CASE_SUCCESS,
  payload,
});

export const followMyCaseStart = payload => ({
  type: FOLLOW_MY_CASE_START,
  payload,
});

export const followMyCaseFail = payload => ({
  type: FOLLOW_MY_CASE_FAIL,
  payload,
});
