import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const CURRENT_NOTIFICATION_URL = process.env.REACT_APP_CURRENT_NOTIFICATION_URL;

export const getCurrentNotification = async ({ id }) => {
  const rs = await fetchApi(CURRENT_NOTIFICATION_URL, {
    params: {
      id,
    },
  });
  const data = getProps(rs, 'json') && (await rs.json());
  return { status: rs.status, ok: rs.ok, result: data };
};

export default getCurrentNotification;
