import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { onLoad } from 'utils/recaptcha-utils';
import {
  setRecaptcha,
  setRecaptchaError,
} from 'redux/actions/recaptchaActions';
import { isRecaptchaRequestedSelector } from 'redux/selectors/recaptcha';
import { isTheSameEnvironmentVariable } from 'utils/app-utils';
const RECAPTCHA_PUBLIC_KEY = process.env.REACT_APP_RECAPTCHA_PUBLIC_SECRET;

export const Recaptcha = () => {
  const [recaptchaRef, setRecaptchaRef] = useState(null);
  const isRecaptchaRequested = useSelector(isRecaptchaRequestedSelector);
  const dispatch = useDispatch();
  const isRecaptchaEnable = !isTheSameEnvironmentVariable(
    RECAPTCHA_PUBLIC_KEY,
    'RECAPTCHA_DISABLE',
  );
  useEffect(() => {
    if (isRecaptchaEnable) {
      loadReCaptcha(RECAPTCHA_PUBLIC_KEY, onLoad);
    }
  }, [isRecaptchaEnable]);

  useEffect(() => {
    if (isRecaptchaEnable && isRecaptchaRequested && recaptchaRef) {
      try {
        recaptchaRef.execute();
      } catch (error) {
        dispatch(setRecaptchaError({ error }));
      }
    }
  }, [isRecaptchaRequested, recaptchaRef, dispatch, isRecaptchaEnable]);

  const verifyCallback = token => {
    dispatch(setRecaptcha({ token }));
  };

  if (!isRecaptchaEnable) {
    return null;
  }
  return (
    <ReCaptcha
      ref={ref => setRecaptchaRef(ref)}
      sitekey={RECAPTCHA_PUBLIC_KEY}
      action="action_name"
      verifyCallback={verifyCallback}
    />
  );
};

export default Recaptcha;
