import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const ArrowMenuIcon = ({ title, theme: { primaryColor } }) => (
  <Icon>
    <svg
      title={title}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="22"
      viewBox="0 0 16 22"
    >
      <defs>
        <filter
          id="a"
          width="104.7%"
          height="104.5%"
          x="-2.5%"
          y="-1.6%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={primaryColor}
        strokeLinecap="round"
        strokeWidth="2"
        d="M283.5 19l5.217 5.75-5.217 5.75"
        filter="none"
        transform="translate(-278 -16)"
      />
    </svg>
  </Icon>
);

ArrowMenuIcon.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
};

ArrowMenuIcon.defaultProps = {
  theme: { primaryColor: '#00008f' },
  title: 'Arrow Menu title',
};

export default withTheme(ArrowMenuIcon);
