import { isChildDescendant } from '../global-utils';

export const shouldHideTheMenu = ({ displayMenu, child, stem }) =>
  displayMenu &&
  !isChildDescendant({
    child,
    parentId: stem,
  });

export const getCategory = () => {
  let category = 'current_notification';
  if (window.location.pathname === '/history') {
    category = 'history';
  }
  return category;
};
