import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';

const CASE_URL = process.env.REACT_APP_CASE_URL;

export const getCase = async params => {
  let caseUrl;
  switch (Object.keys(params).filter(key => params[key])[0]) {
    case 'id':
      caseUrl = `${CASE_URL}?case_guid=${params.id}`;
      break;
    case 'caseId':
      caseUrl = `${CASE_URL}?case_id=${params.caseId}`;
      break;
    case 'caseReference':
      caseUrl = `${CASE_URL}?case_reference=${params.caseReference}&partner_reference=${params.config_file}`;
      break;
    default:
      caseUrl = CASE_URL;
      break;
  }
  const rs = await fetchApi(caseUrl, { method: 'GET' });
  const data = getProps(rs, 'json') && (await rs.json());
  return { status: rs.status, ok: rs.ok, result: data };
};

export default getCase;
