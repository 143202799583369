export const TOGGLE_TUTORIAL = 'TOGGLE_TUTORIAL';
export const ADD_CASE_TUTORIAL = 'ADD_CASE_TUTORIAL';

export const toggleTutorial = () => ({
  type: TOGGLE_TUTORIAL,
});

export const addCaseGuidTutorial = caseGuid => ({
  type: ADD_CASE_TUTORIAL,
  caseGuid,
});
