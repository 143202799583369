const defaultTheme = {
  primaryColor: '#00008f',
  onPrimaryColor: '#fff',
  darkPrimaryColor: 'rgb(51, 51, 51)',
  secondaryColor: '#ef4d29',
  onSecondaryColor: '#fff',
  darkSecondaryColor: '#dd5734',
  colorButtonDisabled: '#ccc',
  backGroundColorButtonDisabled: '#ccc',
  onColorButtonDisabled: '#fff',
  textColor: '#333333',
  summaryFieldTitleColor: '#7f7f7f',
  statusColor: '#f07662',
  specialBannerBorderBottom: '#ec4d33',
};

export default defaultTheme;
