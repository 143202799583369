import { default as AlexandriaButton } from '../Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 117px;
`;

export const Button = styled(AlexandriaButton)`
  flex-basis: 40px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.primaryColor};
  padding: 0;
  display: block;
  background: none;
  width: 40px;
  height: 40px;
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.primaryColor};
  border-radius: 4px;
`;
