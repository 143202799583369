import {
  CREATE_PICTURE_START,
  CREATE_PICTURE_FAIL,
  CREATE_PICTURE_SUCCESS,
  REMOVE_PICTURE,
  PREPARE_PICTURE,
  PREPARE_PICTURE_SUCCESS,
  UPDATE_PREPARE_PICTURE,
  CLEAN_PICTURES,
  RESET_PICTURES,
  UPLOAD_PICTURE_SUCCESS,
  UPLOAD_PICTURE,
  IS_PICTURE_LOADING,
  PREPARE_PICTURE_FAILED,
  ENTER_PICTURE_SECTION,
  EXIT_PICTURE_SECTION,
  MARK_PICTURE_TO_UPLOAD,
  GET_HISTORY_SUCCESS,
} from 'redux/actions/types';

const initialState = {
  pictures: [],
  preparedToUpload: [],
  uploadingPictures: [],
  error: null,
  isLoading: 0,
  isInPictureSection: false,
};

function pictureReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PICTURE_START:
      return {
        ...state,
        isLoading: state.isLoading + 1,
        error: null,
      };
    case IS_PICTURE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CREATE_PICTURE_SUCCESS:
      return {
        ...state,
        isLoading: Math.max(0, state.isLoading - 1),
      };
    case CREATE_PICTURE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PREPARE_PICTURE_SUCCESS:
      return {
        ...state,
        preparedToUpload: [...state.preparedToUpload, action.payload],
      };
    case UPDATE_PREPARE_PICTURE:
      const indexToUpdate = state.preparedToUpload.findIndex(
        pic => pic.name === action.payload.name,
      );
      const preparedToUpload = [...state.preparedToUpload];
      preparedToUpload[indexToUpdate] = {
        ...preparedToUpload[indexToUpdate],
        id: action.payload.id,
      };
      return { ...state, preparedToUpload };
    case PREPARE_PICTURE:
      const uploadingPictures = [
        ...state.uploadingPictures.filter(
          currentPicture => currentPicture.name !== action.payload.name,
        ),
      ];
      return {
        ...state,
        uploadingPictures,
      };
    case PREPARE_PICTURE_FAILED:
      const failedPicture = { ...action.payload.picture, status: 'ERROR' };
      return {
        ...state,
        preparedToUpload: [...state.preparedToUpload, failedPicture],
      };
    case CLEAN_PICTURES:
      return {
        ...state,
        preparedToUpload: state.preparedToUpload.filter(
          ({ status }) => status !== 'ERROR',
        ),
      };
    case RESET_PICTURES:
      state.preparedToUpload.forEach(pic => clearInterval(pic.interval));
      return {
        ...state,
        preparedToUpload: [],
      };
    case GET_HISTORY_SUCCESS:
      let alreadyUploadedImages = action.payload;
      if (Array.isArray(alreadyUploadedImages)) {
        const preparedToUpload = state.preparedToUpload.filter(
          picture => picture.name && picture.status !== 'SENT',
        );
        alreadyUploadedImages = alreadyUploadedImages
          .filter(event => event.type === 'PICTURE_UPLOADED')
          .flatMap(event => event.pictures.map(picture => picture.file_name))
          .map(picture => ({
            status: 'SENT',
            historic: true,
            src: picture,
          }));
        return {
          ...state,
          preparedToUpload: [...alreadyUploadedImages, ...preparedToUpload],
        };
      }
      return state;
    case UPLOAD_PICTURE:
    case UPLOAD_PICTURE_SUCCESS:
      return {
        ...state,
        preparedToUpload: [
          ...state.preparedToUpload.filter(
            currentPicture => currentPicture.name !== action.payload.name,
          ),
          action.payload,
        ],
      };
    case REMOVE_PICTURE:
      const preparedToUploadPictures = state.preparedToUpload;
      return {
        ...state,
        preparedToUpload: preparedToUploadPictures.filter(currentPicture => {
          const filterCondition = currentPicture.name !== action.payload.name;
          if (!filterCondition) {
            clearInterval(currentPicture.interval);
          }
          return filterCondition;
        }),
      };
    case ENTER_PICTURE_SECTION:
      return {
        ...state,
        isInPictureSection: true,
      };
    case EXIT_PICTURE_SECTION:
      return {
        ...state,
        isInPictureSection: false,
      };
    case MARK_PICTURE_TO_UPLOAD:
      return {
        ...state,
        uploadingPictures: [
          ...state.uploadingPictures.filter(
            currentPicture => currentPicture.name !== action.payload.name,
          ),
          action.payload.picture,
        ],
      };
    default:
      return state;
  }
}

export default pictureReducer;
