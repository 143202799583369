import { delay, put, select } from 'redux-saga/effects';
import { getCaseStart } from 'redux/actions/caseActions';
import { fetchCurrentNotification } from 'redux/actions/currentNotificationActions';
import { getHistoryStart } from 'redux/actions/historyActions';
import { dataSelector } from 'redux/selectors/common';
import { notificationAutoRefreshTimeSecSelector } from 'redux/selectors/config';
import { MissionActionType } from 'redux/actions/missionActions.types';
import actions from 'redux/actions/missionActions';
const { fetchMission } = actions;

const MILLI_TO_SEC = 1000;

export function* updateAppMissionSaga(action: MissionActionType) {
  const {
    payload: { stopRefresh, immediate },
  } = action;

  if (stopRefresh) {
    return;
  }

  const { caseGuid, missionOrderId } = yield select(dataSelector);
  const isCaseId = !missionOrderId;
  const id = missionOrderId || caseGuid;
  const notificationAutoRefreshTimeSec: number = yield select(
    notificationAutoRefreshTimeSecSelector,
  );
  const time_refresh_sec = notificationAutoRefreshTimeSec * MILLI_TO_SEC;

  if (!immediate) {
    yield delay(time_refresh_sec);
  }

  const payload = {
    id,
    shouldDisplayLoading: false,
  };

  if (isCaseId) {
    yield put(getCaseStart(payload));
    yield put(getHistoryStart({ params: { caseGuid } }));
  } else {
    yield put(fetchCurrentNotification(payload));
    yield put(fetchMission(payload));
    yield put(getHistoryStart({ params: { id } }));
  }
}
