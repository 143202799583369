import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Switch } from 'react-router';
import { hot } from 'react-hot-loader';
import { selectLanguage, selectTheme } from 'redux/selectors/config';
import { getAppTheme, GlobalStyles } from './globalStyles';
import TimeoutErrorAlert from './components/TimeoutErrorAlert';
import Header from './components/Header';
import Footer from './components/Footer';
import Helmet from './components/Helmet';
import Loader from './components/Loader';
import Recaptcha from './components/Recaptcha';
import Routes from './Routes';
import AlertManager, {
  BannerAlert,
  SpecialAlert,
} from './components/AlertManager';
import { useApplication } from './hooks/application-hooks';
import { MainContentContainer } from 'components/MainContainer';
import PrivatePolicyModal from 'components/PrivatePoliciesModal';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { selectBannerAlertStatus } from 'redux/selectors/alerts';

const App = () => {
  const theme = useSelector(selectTheme);
  const defaultLanguage = useSelector(selectLanguage);
  const displayBannerAlert = useSelector(selectBannerAlertStatus);

  useApplication();
  useEffect(() => {
    let value = '100vh';
    if (window.innerWidth <= 1024) {
      value = `${window.innerHeight}px`;
    }
    window['--real100vh'] = value;
  }, []);

  if (!theme) {
    return null;
  }
  const appTheme = getAppTheme({ theme });

  return (
    <Suspense fallback="">
      <I18nextProvider i18n={i18n(defaultLanguage)}>
        <ThemeProvider theme={appTheme}>
          <Switch>
            <>
              <TimeoutErrorAlert />
              <Helmet />
              <GlobalStyles theme={appTheme} />
              <Header />
              <MainContentContainer>
                {displayBannerAlert && <BannerAlert />}
                <SpecialAlert />
                <PrivatePolicyModal />
                <Routes />
                <Loader />
                <Footer />
              </MainContentContainer>
              <AlertManager />
              <Recaptcha />
            </>
          </Switch>
        </ThemeProvider>
      </I18nextProvider>
    </Suspense>
  );
};

export const ConnectedApp = App;

export default hot(module)(ConnectedApp);
