import PropTypes from 'prop-types';

import { LanguageWorldItem, LanguageText } from './LanguageStyled';

const LanguageMenuItem = ({ handleOnClick, itemLanguage, language }) => {
  const selected = language === itemLanguage;
  return (
    <LanguageWorldItem
      onClick={handleOnClick}
      selected={selected}
      data-testid={`menu-lang-${itemLanguage}`}
    >
      <LanguageText selected={selected}>
        {getLanguageNames(itemLanguage)}
      </LanguageText>
    </LanguageWorldItem>
  );
};

const getLanguageNames = itemLanguage =>
  languagesNamesMap[itemLanguage.split('-')[0].toLowerCase()];
const languagesNamesMap = {
  ca: 'Català',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  eu: 'Euskara',
  fr: 'Français',
  nl: 'Nederlands',
};

LanguageMenuItem.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  itemLanguage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default LanguageMenuItem;
