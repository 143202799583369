import {
  GET_PROVIDER_ROUTE_DURATION_SUCCESS,
  GET_PROVIDER_ROUTE_DURATION_FAIL,
  REMOVE_MISSION_DATA_FROM_GTM,
} from 'redux/actions/types';

const initialState = {};

function providerRouteReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROVIDER_ROUTE_DURATION_SUCCESS:
      return { ...state, travelDuration: action.payload };
    case GET_PROVIDER_ROUTE_DURATION_FAIL:
      return initialState;
    case REMOVE_MISSION_DATA_FROM_GTM:
      return initialState;
    default:
      return state;
  }
}

export default providerRouteReducer;
