import { put, call } from 'redux-saga/effects';
import getCurrentNotificationApi from 'api/currentNotification';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import {
  getCurrentNotificationFail,
  getCurrentNotificationSuccess,
} from 'redux/actions/currentNotificationActions';

export default function* getCurrentNotification({
  payload: { id, shouldDisplayLoading = true },
}) {
  try {
    if (shouldDisplayLoading) {
      yield put(startLoad());
    }
    const response = yield call(getCurrentNotificationApi, { id });
    if (!response.status || response.status > 300) {
      throw new Error('Request Failure in fetchCurrentNotificationApi');
    }
    const { result } = response;
    return yield put(getCurrentNotificationSuccess(result));
  } catch (error) {
    return yield put(getCurrentNotificationFail(error.message || error));
  } finally {
    if (shouldDisplayLoading) {
      yield put(stopLoad());
    }
  }
}
