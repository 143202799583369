import defaultTheme from '../theme/theme';

export const ColorTheme = {
  primary: {
    color: defaultTheme.primaryColor,
    dark: defaultTheme.darkPrimaryColor,
  },
  secondary: {
    color: defaultTheme.secondaryColor,
    dark: defaultTheme.darkSecondaryColor,
  },
};

type ColorThemeType = keyof typeof ColorTheme;

export default ColorThemeType;
