import {
  ADD_CHAT_MESSAGE,
  ADVANCE_CHAT_STATE,
  DISABLE_CHATBOT_NOTIFICATION,
  ENABLE_CHATBOT_NOTIFICATION,
  REPLACE_CHAT_MESSAGE,
} from 'redux/actions/types';
import { CHAT_STATUS } from 'constants/application';
import { ChatActionType } from 'redux/actions/chatActions';
import { ChatState } from 'ts/types/chat.types';

const initState: ChatState = {
  chatState: CHAT_STATUS.READY,
  isWaiting: false,
  mayAskForChatBot: true,
  messages: [{ messages: [], user: 'me' }],
  remainingMessages: [
    'chat_page-message_001',
    'chat_page-message_002',
    'chat_page-message_003',
    'option',
    'chat_page-message_004',
    'input_text',
    'chat_page-message_005',
  ],
};

function applicationReducer(state = initState, action: ChatActionType) {
  let messages, lastStream;
  switch (action.type) {
    case ADVANCE_CHAT_STATE:
      return {
        ...state,
        chatState: CHAT_STATUS.INITIATED,
      };
    case ADD_CHAT_MESSAGE:
      const { chatStream } = action.payload;
      messages = JSON.parse(JSON.stringify(state.messages));
      lastStream = messages.slice(-1)[0];
      if (chatStream.user === lastStream.user) {
        lastStream.messages.push(...chatStream.messages);
      } else {
        messages.push(chatStream);
      }
      return {
        ...state,
        remainingMessages: state.remainingMessages.slice(
          1,
          state.remainingMessages.length,
        ),
        messages,
        isWaiting: chatStream.user === 'me',
      };
    case REPLACE_CHAT_MESSAGE:
      const { message } = action.payload;
      messages = JSON.parse(JSON.stringify(state.messages));
      lastStream = messages.slice(-1)[0];
      lastStream.messages.pop();
      lastStream.messages.push(message);
      return {
        ...state,
        messages,
        isWaiting: false,
      };
    case ENABLE_CHATBOT_NOTIFICATION:
      return { ...state, mayAskForChatBot: true };
    case DISABLE_CHATBOT_NOTIFICATION:
      return { ...state, mayAskForChatBot: false };
    default:
      return state;
  }
}

export default applicationReducer;
