import styled from 'styled-components';
import { Fluid } from 'globalStyles/Containers';

const Footer = styled(Fluid)`
  margin-top: auto;
  height: auto;
  align-items: center;
  background: 'footerBackgroundColor';
  background: ${({ theme }) => theme.footerBackgroundColor};
  display: flex;
  flex-direction: column;
  padding: 0;

  hr {
    opacity: 0.3;
    border: 1px solid
      ${({ theme }) => theme.onFooterColor || theme.onSecondaryColor};
    width: 100%;
  }

  button {
    background-color: transparent;
    color: ${({ theme }) => theme.onFooterColor || theme.onSecondaryColor};
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    font-weight: normal;
    padding: 21px 29px;
    letter-spacing: 0.2px;
    line-height: normal;
    text-align: left;
    text-decoration: none;
    width: 100%;
    opacity: 1;
  }
  div {
    text-align: center;
  }
`;

export default Footer;
