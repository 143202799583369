export const CONFIGURABLE = 'CONFIGURABLE';
export const NOT_CONFIGURABLE = 'NOT_CONFIGURABLE';
export const LOGGER = 'LOGGER';
export const STEPS = [
  { title: '', key: 1 },
  { title: '', key: 2 },
  { title: '', key: 3 },
  { title: '', key: 4 },
  { title: '', key: 5 },
];

export const STEP_TITLES = [
  {
    stepNumber: 1,
    stepFraction: '1/5',
    name: 'global-step_1_text',
  },
  {
    stepNumber: 2,
    stepFraction: '2/5',
    name: 'global-step_2_text',
  },
  {
    stepNumber: 3,
    stepFraction: '3/5',
    name: 'global-step_3_text',
  },
  {
    stepNumber: 4,
    stepFraction: '4/5',
    name: 'global-step_4_text',
  },
  {
    stepNumber: 5,
    stepFraction: '5/5',
    name: 'global-step_5_text',
  },
];

export const GA_PAGE_TAGS = {
  faq: 'FAQ_page',
  helpful_tips: 'helpful_tips',
  notification: 'current_notification_page',
  history: 'history_page',
  intro: 'intro',
};

export const GLOBAL_TIMEOUT = 60000;
export const CHAT_STATUS = {
  READY: 'READY',
  INITIATED: 'INITIATED',
};
