/* eslint-disable no-unused-vars */
import { MouseEventHandler } from 'react';

export enum OptionTypes {
  TEXT = 'TEXT',
  ICON = 'ICON',
  TEXT_SQUARE = 'TEXT_SQUARE',
}

export type OptionProps = {
  /**
   * The id of the option
   */
  id: string;
  /**
   * The text of the option
   */
  text: string;
  /**
   * Optional click handler
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
  /**
   * Optional the option icon
   */
  icon?: any;
  /**
   * Optional if the option should be highlighted
   */
  highlighted?: boolean;
  /**
   * Optional if the option text should be highlighted
   */
  shouldHighlightText?: boolean;
  /**
   * Optional if highlighted is false, should downgrade the text tone
   */
  shouldDowngradeNotHighlightedTextTone?: boolean;
  /**
   * Optional: The parent theme
   */
  theme?: any;
  /**
   * Optional: The type of the option
   */
  type?: OptionTypes | keyof typeof OptionTypes;
};
