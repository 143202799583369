import { useCallback, useState, useEffect } from 'react';
import { event } from 'analytics';
import { shouldHideTheMenu, getCategory } from 'utils/toolbar-utils';

const getRoolElement = () => document.getElementById('root');

export const useOutsideClickHandler = handleOutsideClick =>
  useEffect(() => {
    getRoolElement().addEventListener('click', handleOutsideClick);
    return () =>
      getRoolElement().removeEventListener('click', handleOutsideClick, false);
  });

export const useToolbar = stem => {
  const [displayMenu, setDisplayMenu] = useState(false);
  const category = getCategory();

  const handleOutsideClick = useCallback(
    clickEvent => {
      if (
        shouldHideTheMenu({ displayMenu, child: clickEvent.target, stem }) &&
        displayMenu
      ) {
        setDisplayMenu(false);
        event(`${stem}-closed`, category);
      }
    },
    [setDisplayMenu, displayMenu, category, stem],
  );

  useOutsideClickHandler(handleOutsideClick);

  const toggleMenu = useCallback(() => {
    setDisplayMenu(!displayMenu);
    event(displayMenu ? `${stem}-closed` : `${stem}-opened`, category);
  }, [setDisplayMenu, displayMenu, category, stem]);
  return { toggleMenu, displayMenu };
};
