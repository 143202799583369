import {
  GET_LOCATION_START,
  GET_PROVIDER_ROUTE_DURATION_START,
} from 'redux/actions/types';
import { takeLatest } from 'redux-saga/effects';
import { getLocationDuration, getSuggestedLocation } from './location-saga';

export default function* routeSagas() {
  yield takeLatest(GET_LOCATION_START, getSuggestedLocation);
  yield takeLatest(GET_PROVIDER_ROUTE_DURATION_START, getLocationDuration);
}
