const GeolocatemeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0909 9.09091C17.6364 5.27273 14.6364 2.27273 10.9091 1.90909V0H9.09091V1.90909C5.27273 2.27273 2.27273 5.27273 1.90909 9.09091H0V10.9091H1.90909C2.36364 14.7273 5.36364 17.7273 9.09091 18.0909V20H10.9091V18.0909C14.7273 17.6364 17.7273 14.6364 18.0909 10.9091H20V9.09091H18.0909ZM10 6.36364C8 6.36364 6.36364 8 6.36364 10C6.36364 12 8 13.6364 10 13.6364C12 13.6364 13.6364 12 13.6364 10C13.6364 8 12 6.36364 10 6.36364ZM3 10C3 13.9 6.1 17 10 17C13.9 17 17 13.9 17 10C17 6.1 13.9 3 10 3C6.1 3 3 6.1 3 10Z"
      stroke="none"
    />
  </svg>
);

export default GeolocatemeIcon;
