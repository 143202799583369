import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

export const XIcon = ({ theme, width = '40', height = '20' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
  >
    <path
      fill={theme.specialBannerCloseButton}
      fillRule="evenodd"
      d="M19.313 12.456L12.456 12.456 12.456 19.313 10.171 19.313 10.171 12.456 3.314 12.456 3.314 10.17 10.171 10.17 10.171 3.313 12.456 3.313 12.456 10.17 19.313 10.17z"
      transform="scale(1 -1) rotate(45 37.556 -.443)"
    />
  </svg>
);

XIcon.propTypes = {
  theme: PropTypes.object.isRequired,
};

export default withTheme(XIcon);
