import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  copyrightLabelSelector,
  selectMenuLinks,
  selectPolicies,
} from 'redux/selectors/config';
import { event } from 'analytics';
import { useRoutes } from 'hooks/routes-hooks';

export const useFooterHook = () => {
  const links = useSelector(selectMenuLinks);
  const policies = useSelector(selectPolicies);
  const location = useLocation();
  const dispatch = useDispatch();
  const { getLinkPage } = useRoutes();
  const copyrightLabel = useSelector(copyrightLabelSelector);

  const footerLinks = links
    .map(link => ({ ...link, url: getLinkPage({ destination: link.url }) }))
    .filter(link => link.isFooterLink && link.url !== location.pathname);

  const goToItem = url => () => {
    event(`${url.substring(1, url.length)}`, 'footer');
    dispatch(push(url));
  };

  return { policies, goToItem, footerLinks, copyrightLabel };
};
