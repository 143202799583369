import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pagesWithoutHeaderSelector } from 'redux/selectors/config';
import { MainContainer, Scrollable } from './MainContainerStyled';
import { useEffect, useRef } from 'react';

export const mainContainerId = 'main-container';

export const MainContentContainer = ({ children }) => {
  const pagesWithoutHeader = useSelector(pagesWithoutHeaderSelector);
  const shouldDisplayHeader = !useRouteMatch({
    path: pagesWithoutHeader,
    exact: true,
  });
  const { pathname } = useLocation();
  const scrollableRef = useRef();
  useEffect(() => {
    scrollableRef.current?.scrollTo(0, 0);
  }, [pathname, scrollableRef]);
  return (
    <MainContainer
      shouldDisplayHeader={shouldDisplayHeader}
      data-testid={mainContainerId}
    >
      <Scrollable ref={scrollableRef}>{children}</Scrollable>
    </MainContainer>
  );
};
