import { setGlobalVariables } from 'utils/global-utils';
import getPolicies from './policies';
import { fetchDataByTag } from './prismic';
import { configPrismicReducer, moveToKeys } from './prismic/reducers';
import { BusinessCategory, DocsDirectory } from 'constants/synergy';
import { StringMap } from 'ts/types/common.types';

const getConfigs = async ({ partner, provider }: StringMap) => {
  const prismicConfig = await fetchDataByTag(
    [partner, provider, 'global'],
    configPrismicReducer,
    moveToKeys,
  );

  return {
    prismicConfig,
  };
};

const getProjectDocsPathName = (
  businessCategoryName: string = BusinessCategory.HOME,
) => {
  return businessCategoryName === BusinessCategory.MOTOR
    ? DocsDirectory.MOTOR
    : DocsDirectory.HOME;
};

export const getConfig = async ({ partner, provider }: StringMap) => {
  const { prismicConfig } = await getConfigs({ partner, provider });
  const projectDocsPathName = getProjectDocsPathName(
    prismicConfig.business_category,
  );

  setGlobalVariables({ config: prismicConfig });
  const policies = getPolicies({
    prismicConfig,
    partner,
    language: prismicConfig.keys.default_language,
    provider,
    projectDocsPathName,
  });

  return {
    ...prismicConfig,
    policies,
    keys: {
      ...prismicConfig.keys,
      config_file: partner,
      provider,
    },
  };
};

export const getPoliciesConfig = async (language: string) => {
  const { partner, provider } = await getPartnerAndProvider();
  const { prismicConfig } = await getConfigs({
    partner,
    provider,
  });
  const projectDocsPathName = getProjectDocsPathName(
    prismicConfig.business_category,
  );
  return getPolicies({
    prismicConfig,
    partner,
    language: language,
    provider,
    projectDocsPathName,
  });
};

const castPrismicUrlResponse = (prismicUrls: any): any[] => {
  return prismicUrls.partner.map((prismicUrl: any) => {
    const partner: any = {};
    Object.keys(prismicUrl).forEach(prismicUrlKey => {
      let value;
      const prismicValue = prismicUrl[prismicUrlKey];
      if (Array.isArray(prismicValue) && prismicValue.length > 0) {
        value = prismicValue[0].text;
      }
      if (typeof prismicValue === 'string') {
        value = prismicValue;
      }
      partner[prismicUrlKey] = value;
    });
    return partner;
  });
};

export const getPartnerAndProvider = async (): Promise<StringMap> => {
  const prismicUrls = await fetchDataByTag(['urls']);
  const { location } = window;
  const urls = castPrismicUrlResponse(prismicUrls);
  const { partner, provider } = urls.find(
    partner => partner.url === location.hostname,
  );
  return { partner, provider };
};

export const getConfigAndUrl = async () => {
  const { partner, provider } = await getPartnerAndProvider();
  const config = await getConfig({ partner, provider });
  return { partner, provider, config };
};

export const getDecisionTree = async (decisionTreeFileName: string) => {
  return fetch(`/decisionTree/${decisionTreeFileName}.json`).then(rs =>
    rs.json(),
  );
};

export default getConfig;
