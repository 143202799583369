import { event } from './analytics';

export function findCategory(path) {
  const [node, ...parents] = path;
  if (!node || !node.dataset) {
    return null;
  }
  const { category } = node.dataset;
  return category || findCategory(parents);
}

const eventClick = () => {
  document.addEventListener('click', e => {
    const { dataset } = e.target;
    const path = e.path || (e.composedPath && e.composedPath());
    const { action } = dataset;
    if (action) {
      const category = findCategory(path);
      event(action, category);
    }
  });
};

export default eventClick;
