import {
  castCustomStepSlice,
  castIdentificationToObject,
  castListToObject,
  castObjectToList,
  castPrismicObject,
  castStepOrderSliceToList,
  clean,
  getImages,
  getTheme,
} from './utils';
import {
  Configuration,
  ConfigurationKeys,
  CustomStep,
  GtmPerEnv,
  PolicyData,
  RegexData,
  TimeSlotData,
} from 'ts/types/config.types';
import { PrismicRaw, RawPolicyData, RawTimeSlotData } from './prismic.types';
import { Map, StringMap } from 'ts/types/common.types';

export const defaultPrismicReducer = (acc: any, current: any) => ({
  ...acc,
  ...current,
});

export const defaultFinalCast = (item: any): Configuration => item;

export const decisionTreeReducer = (
  acc: { [_: string]: string },
  { language, fileName }: { [_: string]: string },
): { [_: string]: string } => ({
  ...acc,
  [language]: fileName,
});

export const surveyReducer = (
  acc: { [_: string]: string },
  { event, surveyId }: { [_: string]: string },
): { [_: string]: string } => ({
  ...acc,
  [event]: surveyId,
});

export const experimentsReducer = (
  acc: any,
  { key, payload, id }: { key: string; payload: any; id: string },
) => ({
  ...acc,
  [key]: { id, payload: castPrismicObject(payload) },
});
export const regexReducer = (
  acc: { [_: string]: RegexData },
  { name, value, flag }: { [_: string]: string },
): { [_: string]: RegexData } => ({
  ...acc,
  [name]: {
    regex: value,
    flag: flag,
  },
});

export const gtmEnvReducer = (
  acc: GtmPerEnv,
  { environment, ...data }: { [_: string]: string },
): GtmPerEnv => ({
  ...acc,
  [environment]: data,
});

export const oneTrustEnvReducer = (
  acc: { [_: string]: string },
  { env, id }: { [_: string]: string },
): { [_: string]: string } => ({
  ...acc,
  [env]: id,
});

export const policyReducer = (
  acc: { [_: string]: PolicyData },
  { policy, ...policyData }: RawPolicyData,
): { [_: string]: PolicyData } => ({
  ...acc,
  [policy]: policyData,
});

const listToObjectBy = (originalList: Map[] = [], key: string): Map =>
  originalList.reduce(
    (acc, current) => ({ ...acc, [current[key]]: current }),
    {},
  );

export const customStepReducer = (
  acc: CustomStep[],
  current: CustomStep[],
): CustomStep[] =>
  Object.values({
    ...listToObjectBy(acc, 'page_name'),
    ...listToObjectBy(current, 'page_name'),
  });

export const listReducer = (acc: any, current: any) =>
  current?.length && current?.[0][Object.keys(current[0])[0]] !== null
    ? current
    : acc || [];

const timeSlotOptionsMap = ({
  id,
  start_time,
  end_time,
  ...data
}: RawTimeSlotData): TimeSlotData => {
  const paddedStartTime = `0${start_time}`;
  const paddedEndTime = `0${end_time}`;
  return {
    id: parseInt(id, 10),
    start_time: paddedStartTime.substr(paddedStartTime.length - 5),
    end_time: paddedEndTime.substr(paddedEndTime.length - 5),
    ...data,
  };
};

export const configPrismicReducer = (
  acc: Configuration,
  current: PrismicRaw,
) => ({
  ...acc,
  ...current,
  appointment_reschedule_end_hours: listReducer(
    acc.appointment_reschedule_end_hours,
    current.appointment_reschedule_end_hours,
  ).map((time: StringMap) => time['hour_minute'] || time),
  appointment_reschedule_start_hours: listReducer(
    acc.appointment_reschedule_start_hours,
    current.appointment_reschedule_start_hours,
  ).map((time: StringMap) => time['hour_minute'] || time),
  contact_reasons: listReducer(acc.contact_reasons, current.contact_reasons),
  pages_without_header: listReducer(
    acc.pages_without_header,
    current.pages_without_header,
  ),
  pages_without_footer: listReducer(
    acc.pages_without_footer,
    current.pages_without_footer,
  ),
  custom_steps: customStepReducer(
    acc.custom_steps,
    castCustomStepSlice(current.custom_steps),
  ),
  decision_tree: {
    ...(acc.decision_tree || {}),
    ...castListToObject(current, 'decision_tree', decisionTreeReducer),
  },
  decision_tree_text_to_hide: listReducer(
    acc.decision_tree_text_to_hide,
    current.decision_tree_text_to_hide,
  ).map((op: any) => op['text_option']?.toLowerCase() || op.toLowerCase()),
  gtm_env: {
    ...(acc.gtm_env || {}),
    ...castListToObject(current, 'gtm_env', gtmEnvReducer),
  },
  identification: {
    ...(acc.identification || {}),
    ...castIdentificationToObject(current.identification),
  },
  menu_links: listReducer(acc.menu_links, current.menu_links).map((data: any) =>
    clean(data, true),
  ),
  one_trust_env: {
    ...(acc.one_trust_env || {}),
    ...castListToObject(current, 'one_trust_env', oneTrustEnvReducer),
  },
  optimize_experiments_list: {
    ...(acc.optimize_experiments_list || {}),
    ...castListToObject(
      current,
      'optimize_experiments_list',
      experimentsReducer,
    ),
  },
  regex: {
    ...(acc.regex || {}),
    ...castListToObject(current, 'regex', regexReducer),
  },
  policies: {
    ...(acc.policies || {}),
    ...castListToObject(current, 'policies', policyReducer),
  },
  site_intercept_surveys: {
    ...(acc.site_intercept_surveys || {}),
    ...castListToObject(current, 'site_intercept_surveys', surveyReducer),
  },
  step_order: listReducer(
    acc.step_order,
    castStepOrderSliceToList(current.step_order),
  ),
  summary_config: listReducer(acc.summary_config, current.summary_config),
  supported_languages: listReducer(
    acc.supported_languages,
    current.supported_languages
      ?.map((data: any) => Object.values(data)[0])
      .filter((value: any) => value !== undefined && value !== null),
  ),
  events_with_provider_details: listReducer(
    acc.events_with_provider_details,
    current.events_with_provider_details
      ?.map((data: any) => Object.values(data)[0])
      .filter((value: any) => value !== undefined && value !== null),
  ),
  theme: { ...(acc.theme || {}), ...getTheme(current), ...getImages(current) },
  time_slot_options: listReducer(
    acc.time_slot_options,
    current.time_slot_options,
  ).map(timeSlotOptionsMap),
});

export const moveToKeys = (rawConfiguration: any): Configuration => {
  const {
    appointment_reschedule_end_hours,
    appointment_reschedule_start_hours,
    contact_reasons,
    custom_steps,
    decisionTree,
    decision_tree,
    decision_tree_text_to_hide,
    gtm_env,
    identification,
    menu_links,
    one_trust_env,
    optimize_experiments_list,
    regex,
    policies,
    site_intercept_surveys,
    step_order,
    summary_config,
    supported_languages,
    theme,
    time_slot_options,
    passenger_adult_options,
    passenger_children_options,
    pages_without_header,
    pages_without_footer,
    events_with_provider_details,
    ...rest
  } = rawConfiguration;
  const keys = rest as ConfigurationKeys;
  return {
    appointment_reschedule_end_hours,
    appointment_reschedule_start_hours,
    contact_reasons,
    custom_steps,
    decisionTree,
    decision_tree,
    decision_tree_text_to_hide,
    gtm_env,
    identification,
    menu_links,
    one_trust_env,
    optimize_experiments_list,
    regex,
    policies,
    site_intercept_surveys,
    step_order,
    summary_config,
    supported_languages,
    passenger_adult_options,
    passenger_children_options,
    events_with_provider_details,
    theme,
    time_slot_options,
    pages_without_header: castObjectToList(pages_without_header),
    pages_without_footer: castObjectToList(pages_without_footer),
    keys,
  };
};
