import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

export const Markdown = ({ text }) => (
  <ReactMarkdown
    disallowedElements={['script']}
    remarkPlugins={[gfm]}
    rehypePlugins={[rehypeRaw]}
    children={text}
  />
);
Markdown.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Markdown;
