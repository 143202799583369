import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const i18nModule = lang => {
  i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
      fallbackLng: false,
      ns: ['common', 'caseOpening', 'synergy'],
      backend: {
        loadPath: '/locales/{{ns}}/{{lng}}.json',
      },
      returnEmptyString: false,
      interpolation: {
        escapeValue: false,
      },
      load: 'currentOnly',
    });
  i18n.changeLanguage(lang);
  return i18n;
};

export default i18nModule;
