import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const ErrorIcon = ({ title, theme, isFloating, status }) => (
  <Icon isFloating={isFloating}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      title={title}
    >
      <circle
        cx="8"
        cy="8"
        r="8"
        fill={
          status === 'DELAYED'
            ? theme.onWarningColor
            : theme.errorAlertBorderColor
        }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12H7V10H9V12Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 9H7V3H9V9Z"
        fill="white"
      />
    </svg>
  </Icon>
);

ErrorIcon.propTypes = {
  title: PropTypes.string,
  isFloating: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
  status: PropTypes.string,
};

ErrorIcon.defaultProps = {
  title: 'Error Icon title',
  isFloating: false,
  status: 'ERROR',
};

export default withTheme(ErrorIcon);
