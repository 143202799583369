import { takeLatest } from 'redux-saga/effects';
import updateCurrentNotification from './update-current-notification-saga';
import getCurrentNotification from './get-current-notification-saga';
import {
  UPDATE_NOTIFICATION_START,
  GET_CURRENT_NOTIFICATION_START,
} from 'redux/actions/types';

export default function* currentNotificationSagas() {
  yield takeLatest(UPDATE_NOTIFICATION_START, updateCurrentNotification);
  yield takeLatest(GET_CURRENT_NOTIFICATION_START, getCurrentNotification);
}
