const specialCharsTransformationMap = {
  '\n': ' ',
};

const isObject = item =>
  typeof item === 'object' && !Array.isArray(item) && item !== null;

const generateRegExpFromObjectKeys = object => {
  const characterToString = Object.keys(object).join('');
  return new RegExp(`[${characterToString}]`, 'g');
};

const isSpecialChar = char => char.charAt(0) === '\\';

const addressSpecialCharacters = map => {
  const newMap = {};
  Object.keys(map).forEach(char => {
    if (isSpecialChar(char)) {
      newMap[char.slice(1, char.length)] = map[char];
    } else {
      newMap[char] = map[char];
    }
  });
  return newMap;
};

const avoidedCharactersRegExp = /[^a-zA-Z0-9.,_\\\-\s]/g;

const removeExtraSpaces = string =>
  string
    .replace(/\s+/g, ' ')
    .replace(/^\s|\s$/g, '')
    .trim();

const escapeToHTML = str =>
  str.replace(avoidedCharactersRegExp, char => `&#${char.charCodeAt(0)}#&`);

const escapeCharactersToCodeInString = string => {
  const mapReplacerWithoutSpecialChars = addressSpecialCharacters(
    specialCharsTransformationMap,
  );
  const replacer = match =>
    match in mapReplacerWithoutSpecialChars
      ? mapReplacerWithoutSpecialChars[match]
      : match;
  const mappedRegExp = generateRegExpFromObjectKeys(
    specialCharsTransformationMap,
  );
  return removeExtraSpaces(
    escapeToHTML(string.replace(mappedRegExp, replacer)),
  );
};

const escapeCharactersInObjectStrings = (obj, whitelist = []) => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach(key => {
    if (!whitelist.includes(key)) {
      if (typeof newObj[key] === 'string') {
        const escapedString = escapeCharactersToCodeInString(newObj[key]);
        newObj[key] = escapedString;
      } else if (isObject(obj[key])) {
        newObj[key] = escapeCharactersInObjectStrings(obj[key], whitelist);
      } else if (Array.isArray(newObj[key])) {
        const arr = newObj[key].map(item => {
          let arrayItem;
          if (typeof item === 'string') {
            const escapedString = escapeCharactersToCodeInString(item);
            arrayItem = escapedString;
          } else if (isObject(item)) {
            arrayItem = escapeCharactersInObjectStrings(item, whitelist);
          }
          return arrayItem;
        });

        newObj[key] = arr;
      }
    }
  });

  return newObj;
};

export default escapeCharactersInObjectStrings;
