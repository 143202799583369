export const notTrackedPages = [
  'intro',
  'notification',
  'upload-pictures',
  'case',
];
export const createStepMap = (array, step) =>
  array
    .map((p, index) => {
      if (typeof p === 'string') {
        return { [p]: step >= 0 ? { step, subStep: index } : { step: index } };
      } else {
        return createStepMap(p, index);
      }
    })
    .reduce((acc, current) => ({ ...acc, ...current }), {});

export const getNextStep = (orders, nextPage) =>
  createStepMap(orders)[nextPage];
