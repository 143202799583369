import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const ModalPortal = ({ children, modals, type, centered }) => {
  const divWrapper = document.createElement('div');
  if (centered) {
    divWrapper.setAttribute('style', 'margin: auto; position: relative;');
  }
  const modalSelected = modals[type];
  useEffect(() => {
    if (modalSelected) {
      modalSelected.appendChild(divWrapper);
    }
    return () => modalSelected && modalSelected.removeChild(divWrapper);
  }, [modalSelected, divWrapper]);
  return createPortal(children, divWrapper);
};

ModalPortal.propTypes = {
  children: PropTypes.node,
  modals: PropTypes.shape({
    notifications: PropTypes.object,
    cookies: PropTypes.object,
    errors: PropTypes.object,
    media: PropTypes.object,
  }).isRequired,
  type: PropTypes.string,
};

ModalPortal.defaultProps = {
  type: 'notifications',
  children: {},
};

export default ModalPortal;
