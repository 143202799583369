export const missionNamespaceSelector = state => state.mission;

export const missionSelector = state => missionNamespaceSelector(state).data;

export const missionCaseGuIdSelector = state => missionSelector(state).caseGuid;

export const missionJobReferenceSelector = state =>
  missionSelector(state).jobReference;

export const missionStepSelector = state => missionSelector(state).step;

export const missionErrorSelector = state =>
  missionNamespaceSelector(state).error;

export const missionProviderTypeSelector = state =>
  missionSelector(state).providerType;
