import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';
import { noop } from 'utils/global-utils';

export const CloseIcon = ({
  title = 'Close Icon',
  theme,
  isFloating = false,
  onClick = noop,
  testid = 'close-icon',
}) => (
  <Icon
    isFloating={isFloating}
    onClick={onClick}
    dataAttributes={[{ key: 'testid', value: testid }]}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="13px"
      height="13px"
      viewBox="0 0 13 13"
      version="1.1"
    >
      <title>{title}</title>
      <g
        id="Home_20190219"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="claim-received/step-1.2"
          transform="translate(-286.000000, -28.000000)"
          fill={theme.onPrimaryColor}
          fillRule="nonzero"
        >
          <g id="icon/close" transform="translate(282.000000, 24.000000)">
            <polygon
              id="Shape"
              transform="translate(10.606602, 10.606602) rotate(45.000000) translate(-10.606602, -10.606602) "
              points="18.1066017 11.6826188 11.6826188 11.6826188 11.6826188 18.1066017 9.53058459 18.1066017 9.53058459 11.6826188 3.10660172 11.6826188 3.10660172 9.53058459 9.53058459 9.53058459 9.53058459 3.10660172 11.6826188 3.10660172 11.6826188 9.53058459 18.1066017 9.53058459"
            />
          </g>
        </g>
      </g>
    </svg>
  </Icon>
);

CloseIcon.propTypes = {
  title: PropTypes.string,
  isFloating: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
};

export default withTheme(CloseIcon);
