import { css } from 'styled-components';

const sourceSansProRegular = '/assets/fonts/SourceSansPro-Regular.ttf.woff2';
const sourceSansProSemiBold = '/assets/fonts/SourceSansPro-Semibold.ttf.woff2';
const sourceSansProBold = '/assets/fonts/SourceSansPro-Bold.ttf.woff2';

const latoLight = '/assets/fonts/Lato-Light.woff2';
const latoRegular = '/assets/fonts/Lato-Regular.woff2';
const latoBold = '/assets/fonts/Lato-Bold.woff2';

const museoSansRoundedRegular = '/assets/fonts/MuseoSansRounded-Regular.ttf';
const museoSansRoundedSemiBold = '/assets/fonts/MuseoSansRounded-Semibold.ttf';
const museoSansRoundedBold = '/assets/fonts/MuseoSansRounded-Bold.ttf';

const camptonRegular = '/assets/fonts/campton-Regular.woff';
const camptonSemiBold = '/assets/fonts/campton-Semibold.woff';

const tescoRegular = '/assets/fonts/Tesco-Regular.woff2';
const tescoSemibold = '/assets/fonts/Tesco-SemiBold.ttf';
const tescoBold = '/assets/fonts/Tesco-Bold.woff2';

const avenirNextRegular = '/assets/fonts/AvenirNext.woff2';
const avenirNextSemibold = '/assets/fonts/AvenirNext-Semibold.woff2';
const avenirNextBold = '/assets/fonts/AvenirNextDemi-Bold.woff2';

const maryAnnRegular = '/assets/fonts/Mary_ann-Regular.woff';
const maryAnnSemibold = '/assets/fonts/Mary_ann-Semibold.woff';
const maryAnnBold = '/assets/fonts/Mary_ann-Bold.woff';

const proximaSoftRegular = '/assets/fonts/ProximaSoft-Regular.woff';
const proximaSoftLight = '/assets/fonts/ProximaSoft-Light.woff';
const proximaSoftBold = '/assets/fonts/ProximaSoft-Bold.woff';

const gothicSTDRegular =
  '/assets/fonts/itc-avant-garde-gothic-std-Regular.woff';
const gothicSTDLight = '/assets/fonts/itc-avant-garde-gothic-std-Light.woff';
const gothicSTDDemi = '/assets/fonts/itc-avant-garde-gothic-std-Demi.woff';

const graphikBoldOtf = '/assets/fonts/GraphikBold.otf';
const graphikBoldTtf = '/assets/fonts/GraphikBold.ttf';
const graphikBoldWoff = '/assets/fonts/GraphikBold.woff';
const graphikBoldWoff2 = '/assets/fonts/GraphikBold.woff2';
const graphikLightOtf = '/assets/fonts/GraphikLight.otf';
const graphikLightTtf = '/assets/fonts/GraphikLight.ttf';
const graphikLightWoff = '/assets/fonts/GraphikLight.woff';
const graphikLightWoff2 = '/assets/fonts/GraphikLight.woff2';
const graphikMediumOtf = '/assets/fonts/GraphikMedium.otf';
const graphikMediumTtf = '/assets/fonts/GraphikMedium.ttf';
const graphikMediumWoff = '/assets/fonts/GraphikMedium.woff';
const graphikMediumWoff2 = '/assets/fonts/GraphikMedium.woff2';
const graphikRegularOtf = '/assets/fonts/GraphikRegular.otf';
const graphikRegularTtf = '/assets/fonts/GraphikRegular.ttf';
const graphikRegularWoff = '/assets/fonts/GraphikRegular.woff';
const graphikRegularWoff2 = '/assets/fonts/GraphikRegular.woff2';

const futuraLTBook = '/assets/fonts/FuturaLT-Book.woff';
const futuraLTBold = '/assets/fonts/FuturaLT-Bold.woff';

const openSansLight = '/assets/fonts/OpenSansLight.woff2';
const openSansBold = '/assets/fonts/OpenSansBold.woff2';
const openSansRegular = '/assets/fonts/OpenSansRegular.woff2';

const santanderLight = '/assets/fonts/SantanderText-Light.woff2';
const santanderBold = '/assets/fonts/SantanderHeadlineW05-Bold.woff2';
const santanderRegular = '/assets/fonts/SantanderLogo-Regular.woff2';

const segurCaixaLight = '/assets/fonts/ProximaNovaLight.otf';
const segurCaixaBold = '/assets/fonts/ProximaNovaBold.otf';
const segurCaixaRegular = '/assets/fonts/ProximaNovaRegular.otf';

const newTransportLight = '/assets/fonts/NewTransportAA-Light.woff2';
const newTransportBold = '/assets/fonts/NewTransportAA-Bold.woff2';
const newTransportRegular = '/assets/fonts/NewTransportAA-Regular.woff2';

const robotoSlabLight = '/assets/fonts/RobotoSlab-Light.ttf';
const robotoSlabBold = '/assets/fonts/RobotoSlab-Bold.ttf';
const robotoSlabRegular = '/assets/fonts/RobotoSlab-Regular.ttf';

const fontSmithMeBold = '/assets/fonts/FSMe-Bold.woff2';
const fontSmithMeRegular = '/assets/fonts/FSMe-Regular.woff2';

const generateDefaultFontFace = ({
  regularFont,
  semiBoldFont,
  boldFont,
  fontName,
  fontFormat,
}) => css`
  /* latin */
  @font-face {
    font-family: '${fontName}';
    font-style: normal;
    font-weight: 400;
    src: url(${regularFont}) format('${fontFormat}');
  }
  /* latin */
  @font-face {
    font-family: ${fontName};
    font-style: normal;
    font-weight: 600;
    src: url(${semiBoldFont}) format('${fontFormat}');
  }
  /* latin */
  @font-face {
    font-family: ${fontName};
    font-style: normal;
    font-weight: 700;
    src: url(${boldFont}) format('${fontFormat}');
  }
`;

export const sourceSansPro = css`
  /* latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
      url(${sourceSansProRegular}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
      url(${sourceSansProSemiBold}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
      url(${sourceSansProBold}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
`;

export const Lato = css`
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: local('Lato Light'), local('Lato-Light'),
      url(${latoLight}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'),
      url(${latoRegular}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* latin */
  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: local('Lato Bold'), local('Lato-Bold'),
      url(${latoBold}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
`;

export const museoSansRounded = css`
  @font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-weight: 300;
    src: url(${museoSansRoundedRegular});
  }

  @font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-weight: 400;
    src: url(${museoSansRoundedRegular});
  }

  @font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-weight: normal;
    src: url(${museoSansRoundedRegular});
  }

  @font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-weight: 700;
    src: url(${museoSansRoundedSemiBold});
  }

  @font-face {
    font-family: 'Museo Sans Rounded';
    font-style: normal;
    font-weight: 900;
    src: url(${museoSansRoundedBold});
  }
`;

export const graphik = css`
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 300;
    src: url(${graphikLightOtf}) format('opentype'),
      url(${graphikLightTtf}) format('truetype'),
      url(${graphikLightWoff}) format('woff'),
      url(${graphikLightWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    src: url(${graphikRegularOtf}) format('opentype'),
      url(${graphikRegularTtf}) format('truetype'),
      url(${graphikRegularWoff}) format('woff'),
      url(${graphikRegularWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    src: url(${graphikMediumOtf}) format('opentype'),
      url(${graphikMediumTtf}) format('truetype'),
      url(${graphikMediumWoff}) format('woff'),
      url(${graphikMediumWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 700;
    src: url(${graphikBoldOtf}) format('opentype'),
      url(${graphikBoldTtf}) format('truetype'),
      url(${graphikBoldWoff}) format('woff'),
      url(${graphikBoldWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
`;

export const gothicSTD = generateDefaultFontFace({
  fontName: 'ITC Avant Garde Gothic',
  fontFormat: 'woff',
  regularFont: gothicSTDLight,
  semiBoldFont: gothicSTDRegular,
  boldFont: gothicSTDDemi,
});

export const campton = generateDefaultFontFace({
  fontName: 'campton',
  fontFormat: 'woff',
  regularFont: camptonRegular,
  semiBoldFont: camptonRegular,
  boldFont: camptonSemiBold,
});

export const tesco = generateDefaultFontFace({
  fontName: 'Tesco',
  fontFormat: 'woff2',
  regularFont: tescoRegular,
  semiBoldFont: tescoSemibold,
  boldFont: tescoBold,
});

export const avenirNext = generateDefaultFontFace({
  fontName: 'Avenir-Next',
  fontFormat: 'woff2',
  regularFont: avenirNextRegular,
  semiBoldFont: avenirNextSemibold,
  boldFont: avenirNextBold,
});

export const maryAnn = generateDefaultFontFace({
  fontName: 'Mary Ann',
  fontFormat: 'woff',
  regularFont: maryAnnRegular,
  semiBoldFont: maryAnnSemibold,
  boldFont: maryAnnBold,
});

export const fontSmithMe = generateDefaultFontFace({
  fontName: 'FS Me',
  fontFormat: 'woff2',
  regularFont: fontSmithMeRegular,
  semiBoldFont: fontSmithMeBold,
  boldFont: fontSmithMeBold,
});

export const proximaSoft = generateDefaultFontFace({
  fontName: 'Proxima Soft',
  fontFormat: 'woff',
  regularFont: proximaSoftLight,
  semiBoldFont: proximaSoftRegular,
  boldFont: proximaSoftBold,
});

export const futuraLT = generateDefaultFontFace({
  fontName: 'FuturaLT',
  fontFormat: 'woff',
  regularFont: futuraLTBook,
  semiBoldFont: futuraLTBook,
  boldFont: futuraLTBold,
});

export const openSans = generateDefaultFontFace({
  fontName: 'OpenSans',
  fontFormat: 'woff2',
  regularFont: openSansLight,
  semiBoldFont: openSansRegular,
  boldFont: openSansBold,
});

export const santander = generateDefaultFontFace({
  fontName: 'Santander',
  fontFormat: 'woff2',
  regularFont: santanderLight,
  semiBoldFont: santanderRegular,
  boldFont: santanderBold,
});

export const segurCaixa = generateDefaultFontFace({
  fontName: 'SegurCaixa',
  fontFormat: 'otf',
  regularFont: segurCaixaLight,
  semiBoldFont: segurCaixaRegular,
  boldFont: segurCaixaBold,
});

export const newTransport = generateDefaultFontFace({
  fontName: 'NewTransport',
  fontFormat: 'woff2',
  regularFont: newTransportLight,
  semiBoldFont: newTransportRegular,
  boldFont: newTransportBold,
});

export const robotoSlab = generateDefaultFontFace({
  fontName: 'Roboto Slab',
  fontFormat: 'ttf',
  regularFont: robotoSlabLight,
  semiBoldFont: robotoSlabRegular,
  boldFont: robotoSlabBold,
});
