import openCase from 'api/openCase';
import { push } from 'connected-react-router';
import { providerToCountry } from 'mappers/providers';
import { call, put, select } from 'redux-saga/effects';
import caseCreationActions from 'redux/actions/caseCreationActions';
import { addGtmOpenCaseDataLayer } from 'redux/actions/gtmActions';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import { resetPictures } from 'redux/actions/pictureActions';
import {
  caseCreationSelector,
  decisionTreeDropOffContentSelector,
  decisionTreeDropOffSelector,
  decisionTreeSelector,
  isPropertyOccupancySelector,
} from 'redux/selectors/caseCreation';
import {
  currentLanguageSelector,
  partnerNameSelector,
  partnerSelector,
  selectProvider,
  selectShowPropertyOccupancy,
} from 'redux/selectors/config';
import { uploadedPicturesSelector } from 'redux/selectors/picture';
import { routes } from 'Routes/routesConfig';
import { isTheSameEnvironmentVariable } from 'utils/app-utils';
import { getRecaptchaToken } from 'utils/sagas-utils';
import { uploadPicturesToCMX } from '../picture/uploadPicturesToCMX';
import { migrateData } from './mapData';

const { createCaseSuccess, createCaseFail } = caseCreationActions;

const RECAPTCHA_PUBLIC_KEY = process.env.REACT_APP_RECAPTCHA_PUBLIC_SECRET;
const TIME_TO_EXPIRE_PICTURE = 1620000;

const getNextPage = dropOff => {
  switch (dropOff) {
    case 'normal':
      return routes.DROPOFF;
    case 'success':
      return routes.DROPOFF_SUCCESS;
    default:
      return routes.SUCCESS;
  }
};

export function* createCaseSaga() {
  try {
    yield put(startLoad());
    const caseCreationData = yield select(caseCreationSelector);
    const decisionTree = yield select(decisionTreeSelector);
    let dropOffType = yield select(decisionTreeDropOffSelector);
    const shouldCheckOccupancy = yield select(selectShowPropertyOccupancy);
    const isPropertyOccupancy = yield select(isPropertyOccupancySelector);
    const content = yield select(decisionTreeDropOffContentSelector);
    const picturesTobeRefreshedOnCMX = caseCreationData.pictures.filter(
      picture =>
        new Date() - new Date(picture.uploadedAt) > TIME_TO_EXPIRE_PICTURE,
    );
    if (shouldCheckOccupancy && !isPropertyOccupancy) {
      dropOffType = 'normal';
    }
    if (picturesTobeRefreshedOnCMX.length) {
      yield* uploadPicturesToCMX({
        payload: { pictures: picturesTobeRefreshedOnCMX },
      });
      const refreshedPictures = yield select(uploadedPicturesSelector);
      refreshedPictures.forEach(refreshedPicture => {
        caseCreationData.pictures[
          caseCreationData.pictures.findIndex(
            picture => picture.name === refreshedPicture.name,
          )
        ].id = refreshedPicture.id;
      });
      yield put(resetPictures());
    }
    const provider = yield select(selectProvider);
    const partner_reference = yield select(partnerSelector);
    const partner_name = yield select(partnerNameSelector);
    const preferred_language = yield select(currentLanguageSelector);
    const country = providerToCountry[provider];
    const openCaseBody = migrateData({
      caseCreationData,
      decisionTree,
      partner_reference,
      country,
      partner_name,
      preferred_language: preferred_language.substring(0, 2),
    });

    const isReCaptchaEnable = !isTheSameEnvironmentVariable(
      RECAPTCHA_PUBLIC_KEY,
      'RECAPTCHA_DISABLE',
    );

    const recaptchaToken = isReCaptchaEnable
      ? yield getRecaptchaToken()
      : 'RECAPTCHA_DISABLE';

    const response = yield call(openCase, {
      body: openCaseBody,
      recaptchaToken,
    });
    const {
      result: { caseGuid, caseReference, ...result },
      status,
    } = response;
    if (status === 201) {
      yield put(createCaseSuccess({ caseGuid, caseReference, content }));
      yield put(
        addGtmOpenCaseDataLayer({
          case_reference: caseReference,
          hashed_case_id: caseGuid,
        }),
      );
      yield put(push(getNextPage(dropOffType)));
    } else {
      yield put(createCaseFail(result));
      yield put(push(routes.ERROR));
    }
  } catch (error) {
    yield put(createCaseFail(error?.message || error));
    yield put(push(routes.ERROR));
  } finally {
    yield put(stopLoad());
  }
}
