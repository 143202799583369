import {
  TOGGLE_TUTORIAL,
  ADD_CASE_TUTORIAL,
} from 'redux/actions/tutorialActions';
const initialState = {
  showTutorial: false,
  cases: [],
};

function tutorialReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CASE_TUTORIAL:
      return state.cases?.includes(action.caseGuid)
        ? state
        : {
            ...state,
            showTutorial: true,
            cases: [...state.cases, action.caseGuid],
          };
    case TOGGLE_TUTORIAL:
      return {
        ...state,
        showTutorial: !state.showTutorial,
      };
    default:
      return state;
  }
}

export default tutorialReducer;
