import { put, select } from 'redux-saga/effects';
import { disableAnalytics, event, isProviderInitialized } from 'analytics';
import {
  initAnalyticsLogging,
  initOnlyRequiredAnalyticsLogging,
  isTheSameEnvironmentVariable,
} from 'utils/app-utils';
import { shouldInitiateClaritySelector } from 'redux/selectors/config';
import {
  analyticsDeactivationSuccess,
  analyticsInitiationSuccess,
} from 'redux/actions/analyticsActions';
import {
  customStepsSelector,
  gtmEnvSelector,
  gtmIdSelector,
  selectLanguage,
  showSurveysSelector,
  siteInterceptSurveysSelector,
} from 'redux/selectors/config';
import {
  hasInitiatedAnalyticsSelector,
  isAnalyticsAcceptedSelector,
} from 'redux/selectors/analytics';
import { languageSelector } from 'redux/selectors/common';
import {
  createSiteInterceptScript,
  removeSiteInterceptScript,
} from 'redux/sagas/cookies/cookies-saga';
import loadClarity from 'analytics/scripts/clarity';

export const ALLOW_INTERNAL_TRACK = 'TRACK';

export function* initiateAnalytics() {
  const gtm_env = yield select(gtmEnvSelector);
  const gtm_id = yield select(gtmIdSelector);
  const customSteps = yield select(customStepsSelector);
  const shouldInitiateClarity = yield select(shouldInitiateClaritySelector);
  initAnalyticsLogging(customSteps, gtm_env, gtm_id);
  if (shouldInitiateClarity) {
    loadClarity();
  }
  yield put(analyticsInitiationSuccess());
}

export function* disableAnalyticsEvents() {
  if (!isProviderInitialized()) {
    const gtm_env = yield select(gtmEnvSelector);
    const gtm_id = yield select(gtmIdSelector);
    const customSteps = yield select(customStepsSelector);
    initOnlyRequiredAnalyticsLogging(customSteps, gtm_env, gtm_id);
  } else {
    disableAnalytics();
  }
  yield put(analyticsDeactivationSuccess());
}

export function* handleEvents({ payload, type }) {
  if (
    isTheSameEnvironmentVariable(
      process.env.REACT_APP_SHOULD_LOG_INTERNAL_EVENTS,
      ALLOW_INTERNAL_TRACK,
    )
  ) {
    yield event(type, null, null, payload, false, true);
  }
}

export function* handleSurveys({ payload }) {
  const hasInitiatedAnalytics = yield select(hasInitiatedAnalyticsSelector);
  const isAnalyticsAccepted = yield select(isAnalyticsAcceptedSelector);
  const siteInterceptSurveys = yield select(siteInterceptSurveysSelector);
  const shouldShowSurvey = yield select(showSurveysSelector);

  if (
    shouldShowSurvey &&
    siteInterceptSurveys[payload] &&
    hasInitiatedAnalytics &&
    isAnalyticsAccepted
  ) {
    const defaultLanguage = yield select(selectLanguage);
    const language = yield select(languageSelector);
    removeSiteInterceptScript();
    createSiteInterceptScript(
      siteInterceptSurveys[payload],
      (language || defaultLanguage).split('-')[0],
    );
    window.document
      .getElementsByClassName('survey')[0]
      .setAttribute('id', siteInterceptSurveys[payload]);
    setTimeout(() => {
      window.QSI && window.QSI.API && window.QSI.API.run();
    }, 1000);
  }
}
