import { Switch, Route } from 'react-router-dom';
import { useSimpleRoutes } from 'hooks/routes-hooks';

const Routes = () => {
  const { routes } = useSimpleRoutes();
  return (
    <Switch>
      {routes.map(route => (
        <Route {...route} key={route.id} />
      ))}
    </Switch>
  );
};

export default Routes;
