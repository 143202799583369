import { getProps } from 'utils/global-utils';
import fetchApi from 'utils/api-utils';
import { ApiResponse } from 'ts/interfaces/api-response.interface';
import { MissionResponse } from 'ts/types/mission.types';

const MISSION_URL = process.env.REACT_APP_MISSION_URL;

export const getMission = async ({
  id,
}: {
  id: number | string;
}): Promise<ApiResponse<MissionResponse>> => {
  const rs = await fetchApi(MISSION_URL, {
    method: 'GET',
    params: {
      id,
    },
  });
  const data = getProps(rs, 'json') && (await rs.json());
  return { status: rs.status, ok: rs.ok, result: data };
};

export default getMission;
