import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const CheckMarkIcon = ({ title, theme, isFloating }) => (
  <Icon isFloating={isFloating}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <circle
          cx="12.5"
          cy="12.5"
          r="12.5"
          fill={theme.successColor}
          fillRule="nonzero"
        />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeWidth="2"
          d="M7.5 12.5l4.167 3.333 6.666-6.666"
        />
      </g>
    </svg>
  </Icon>
);

CheckMarkIcon.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  isFloating: PropTypes.bool,
};

CheckMarkIcon.defaultProps = {
  title: 'Check Mark title',
  isFloating: false,
};

export default withTheme(CheckMarkIcon);
