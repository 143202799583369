import PropTypes from 'prop-types';

import { LanguageItem, LanguageText } from './LanguageStyled';

const LanguageMenuItem = ({ handleOnClick, itemLanguage, language }) => {
  const selected = language === itemLanguage;
  return (
    <LanguageItem
      onClick={handleOnClick}
      selected={selected}
      data-testid={`menu-lang-${itemLanguage}`}
    >
      <LanguageText selected={selected}>
        {itemLanguage.split('-')[0].toUpperCase()}
      </LanguageText>
    </LanguageItem>
  );
};

LanguageMenuItem.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  itemLanguage: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default LanguageMenuItem;
