import { takeLatest } from 'redux-saga/effects';
import {
  GLOBAL_EVENT_CONSENT_ONETRUST,
  SET_CONFIGURATION_SUCCESS,
  SET_RECAPTCHA,
} from 'redux/actions/types';
import {
  initCookiesWall,
  handleOneTrustEvent,
  handleRecaptchaLoaded,
} from './cookies-saga';

export default function* cookiesSagas() {
  yield takeLatest(SET_CONFIGURATION_SUCCESS, initCookiesWall);
  yield takeLatest(GLOBAL_EVENT_CONSENT_ONETRUST, handleOneTrustEvent);
  yield takeLatest(SET_RECAPTCHA, handleRecaptchaLoaded);
}
