import { OPTIMIZE_INIZIALIZED, INITIALIZE_OPTIMIZE } from './types';

export const initializeOptimize = payload => ({
  type: INITIALIZE_OPTIMIZE,
  payload,
});

export const optimizeInitialized = payload => ({
  type: OPTIMIZE_INIZIALIZED,
  payload,
});
