import { call, put, select } from 'redux-saga/effects';
import { dataDomainScriptIdSelector } from 'redux/selectors/config';
import { hasAcceptedPrivatePoliciesSelector } from 'redux/selectors/privatePolicies';
import { showModal } from 'redux/actions/modalActions';
import {
  acceptAnalytics,
  refuseAnalytics,
} from 'redux/actions/analyticsActions';
import { initializeOptimize } from 'redux/actions/optimizeActions';
import { optimizeIDSelector } from 'redux/selectors/config';

const analyticsCookiesId = process.env.REACT_APP_ANALYTICS_COOKIES_ID;

export const createOneTrustScript = ({ dataDomainScriptId }) => {
  const headTag = document.head;
  const scriptTag = document.createElement('script');
  scriptTag.setAttribute(
    'src',
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  );
  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute('charset', 'UTF-8');
  scriptTag.setAttribute('data-testid', 'one-trust-script');
  scriptTag.setAttribute('id', 'one-trust-script');
  scriptTag.setAttribute('data-domain-script', dataDomainScriptId);
  headTag.appendChild(scriptTag);
};

export const createSiteInterceptScript = (siteInterceptID, language) => {
  const headTag = document.head;
  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('id', 'siteIntercept');
  scriptTag.setAttribute('src', '/assets/scripts/siteIntercept.js');
  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute('charset', 'UTF-8');
  scriptTag.setAttribute('ZID', siteInterceptID);
  scriptTag.setAttribute('language', language);
  headTag.appendChild(scriptTag);
};

export const createOptimizeScript = optimizeID => {
  const headTag = document.head;
  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('id', 'optimize');
  scriptTag.setAttribute(
    'src',
    `https://www.googleoptimize.com/optimize.js?id=${optimizeID}`,
  );
  headTag.appendChild(scriptTag);
};

export const removeOptimizeScript = () => {
  delete window.google_optimize;
  Array.from(document.querySelectorAll('script'))
    .filter(({ id }) => id === 'optimize')
    .forEach(script => script.parentNode.removeChild(script));
};

export const removeSiteInterceptScript = () => {
  Array.from(document.querySelectorAll('script'))
    .filter(
      ({ id, src }) =>
        src.includes('siteintercept.qualtrics') || id === 'siteIntercept',
    )
    .forEach(script => script.parentNode.removeChild(script));
};

export function* initCookiesWall() {
  try {
    const dataDomainScriptId = yield select(dataDomainScriptIdSelector);
    if (dataDomainScriptId) {
      yield call(createOneTrustScript, { dataDomainScriptId });
      return;
    }
    yield put(acceptAnalytics());
  } catch (error) {
    console.warn(error);
  }
}

export function* handleOneTrustEvent({ payload }) {
  try {
    const hasAcceptedPrivatePolicies = yield select(
      hasAcceptedPrivatePoliciesSelector,
    );
    if (!hasAcceptedPrivatePolicies) {
      yield put(showModal({ type: 'privatePolicies' }));
    }
    if (payload?.detail?.includes(analyticsCookiesId)) {
      yield put(acceptAnalytics());
      const optimizeID = yield select(optimizeIDSelector);
      yield call(createOptimizeScript, optimizeID);
      yield put(initializeOptimize());
      return;
    }
    yield call(removeOptimizeScript);
    yield call(removeSiteInterceptScript);
    yield put(refuseAnalytics());
  } catch (error) {
    console.error(error);
  }
}

export function handleRecaptchaLoaded() {
  const fragment = document.createDocumentFragment();
  const oneTrustContainer = document.getElementById('one-trust');
  if (oneTrustContainer.children.length === 0) {
    const oneTrustApp = document.getElementById('onetrust-consent-sdk');
    if (oneTrustApp) {
      fragment.appendChild(oneTrustApp);
      oneTrustContainer.appendChild(fragment);
    }
  }
}
