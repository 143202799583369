import { put, call } from 'redux-saga/effects';
import applicationActions from 'redux/actions/applicationActions';
import { addGtmDataLayer } from 'redux/actions/gtmActions';
import { getConfigAndUrl } from 'api/config';
import { staticDataLayer } from 'constants/gtm';
import { getConfigData } from './gtm-saga-helper';
const { setConfigurationSuccess, setConfigurationFail } = applicationActions;

export default function* setConfig() {
  try {
    const { config, provider } = yield call(getConfigAndUrl);
    yield put(setConfigurationSuccess({ ...config, provider }));
    yield put(
      addGtmDataLayer({
        ...staticDataLayer,
        ...getConfigData(config),
      }),
    );
  } catch (error) {
    yield put(setConfigurationFail(error));
  }
}
