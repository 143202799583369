import { FC } from 'react';
import styled from 'styled-components';
import defaultTheme from '../../theme/theme';

import GeolocateIcon from '../../assets/icons/Geolocalize';
import NotGeolocateIcon from '../../assets/icons/NotGeolocalizeme';

import {
  ButtonProps,
  ButtonSize,
  ButtonStyledProps,
  ButtonTypes,
  getColorProps,
  ButtonColorDictionary,
} from '../Button/Button.types';

import { GeolocatemeButtonBaseTheme } from './GeolocatemeButton.types';

const colorDictionary: ButtonColorDictionary = {
  [ButtonTypes.PRIMARY]: {
    background: '',
    color: 'primaryColor',
    dark: 'darkPrimaryColor',
  },
  [ButtonTypes.SECONDARY]: {
    background: '',
    color: 'secondaryColor',
    dark: 'darkSecondaryColor',
  },
};

const getColor = ({ buttonType, theme }: getColorProps): string =>
  theme?.[colorDictionary[buttonType].color]
    ? theme?.[colorDictionary[buttonType].color]
    : GeolocatemeButtonBaseTheme.palette[buttonType].color;

const ButtonStyled = styled.button<ButtonStyledProps>`
  cursor: pointer;
  padding: ${({ size }) => GeolocatemeButtonBaseTheme.size[size].padding};
  border-radius: 50%;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover:not(:disabled) {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.5);
  }

  :disabled {
    cursor: not-allowed;
    svg {
      fill: ${({ theme }) =>
        theme.colorButtonDisabled || defaultTheme.colorButtonDisabled};
      stroke: ${({ theme }) =>
        theme.colorButtonDisabled || defaultTheme.colorButtonDisabled};
    }
  }

  svg {
    width: ${({ size }) => GeolocatemeButtonBaseTheme.size[size].fontSize};
    height: ${({ size }) => GeolocatemeButtonBaseTheme.size[size].fontSize};
    fill: ${getColor};
    stroke: ${getColor};
  }
`;

/**
 * Primary UI component for user interaction
 */
const GeolocatemeButton: FC<ButtonProps> = ({
  buttonType = ButtonTypes.PRIMARY,
  size = ButtonSize.LARGE,
  onClick,
  dataTestId,
  type = 'button',
  disabled = false,
  ...props
}) => (
  <ButtonStyled
    data-testid={dataTestId}
    type={type}
    buttonType={buttonType as ButtonTypes}
    onClick={onClick}
    size={size}
    disabled={disabled}
    {...props}
  >
    {disabled ? <NotGeolocateIcon /> : <GeolocateIcon />}
  </ButtonStyled>
);

export default GeolocatemeButton;
