import { call, delay, put, select } from 'redux-saga/effects';
import applicationActions from 'redux/actions/applicationActions';
import { getPoliciesConfig } from 'api/config';
import { languageSelector } from 'redux/selectors/common';
import {
  selectLanguage,
  currentLanguageSelector,
  dataDomainScriptIdSelector,
} from 'redux/selectors/config';
import { addGtmDataLayer } from 'redux/actions/gtmActions';
import { CHANGE_LANGUAGE_READY } from 'redux/actions/types';
const {
  changeLanguageFail,
  changeLanguageStart,
  changeLanguageSuccess,
  retryLanguageSetup,
  updatePoliciesConfigurationSuccess,
} = applicationActions;

export default function* handleLanguageChange({ type, payload }) {
  try {
    const language = yield select(languageSelector);
    const currentLanguage = yield select(currentLanguageSelector);
    const defaultLanguage = yield select(selectLanguage);
    const dataDomainScriptId = yield select(dataDomainScriptIdSelector);
    const languageToUse =
      type === CHANGE_LANGUAGE_READY ? payload : language || defaultLanguage;
    if (dataDomainScriptId && languageToUse !== currentLanguage) {
      if (window.OneTrust && languageToUse) {
        yield put(changeLanguageStart(languageToUse));
        window.OneTrust.changeLanguage(languageToUse);
        const policies = yield call(getPoliciesConfig, languageToUse);
        yield put(updatePoliciesConfigurationSuccess(policies));
        yield put(changeLanguageSuccess(languageToUse));
        yield put(
          addGtmDataLayer({ language: languageStandarization(languageToUse) }),
        );
      } else {
        yield delay(50);
        yield put(retryLanguageSetup());
      }
    }
  } catch (error) {
    yield put(changeLanguageFail(error));
  }
}

function languageStandarization(languageToStandarize) {
  switch (languageToStandarize) {
    case 'fr':
      return 'fr_FR';
    default:
      return languageToStandarize;
  }
}
