import { takeLatest, takeEvery } from 'redux-saga/effects';
import { takeEverySequentially } from 'utils/sagas-utils';
import {
  initiateAnalytics,
  handleEvents,
  disableAnalyticsEvents,
  handleSurveys,
} from './analytics-saga';
import {
  addGtmDataLayer,
  addOpenCaseGtmDataLayer,
  addCaseGtmDataLayer,
  addGtmPageView,
  addGtmMissionPageView,
  addGtmOpenCasePageView,
} from './gtm-saga';
import {
  ADD_GTM_DATALAYER,
  ADD_GTM_OPEN_CASE_DATALAYER,
  REMOVE_MISSION_DATA_FROM_GTM,
  ADD_GTM_PAGEVIEW,
  ADD_GTM_MISSION_PAGEVIEW,
  ADD_GTM_OPEN_CASE_PAGEVIEW,
  ACCEPT_ANALYTICS,
  REFUSE_ANALYTICS,
} from 'redux/actions/types';

export default function* analyticsSagas() {
  yield takeLatest(ACCEPT_ANALYTICS, initiateAnalytics);
  yield takeLatest(REFUSE_ANALYTICS, disableAnalyticsEvents);
  yield takeEverySequentially(ADD_GTM_DATALAYER, addGtmDataLayer);
  yield takeLatest(ADD_GTM_OPEN_CASE_DATALAYER, addOpenCaseGtmDataLayer);
  yield takeLatest(REMOVE_MISSION_DATA_FROM_GTM, addCaseGtmDataLayer);
  yield takeLatest(ADD_GTM_PAGEVIEW, addGtmPageView);
  yield takeLatest(ADD_GTM_OPEN_CASE_PAGEVIEW, addGtmOpenCasePageView);
  yield takeLatest(ADD_GTM_MISSION_PAGEVIEW, addGtmMissionPageView);
  yield takeLatest(ADD_GTM_MISSION_PAGEVIEW, handleSurveys);

  yield takeEvery('*', handleEvents);
}
