import { AlertTypes } from 'constants/alerts';
import {
  ADD_ALERT,
  DISMISS_ALERT,
  DISMISS_ALL_ALERTS,
  TOGGLE_ALERT,
} from './types';

export const addErrorAlert = (message, title, cause) => ({
  type: ADD_ALERT,
  payload: {
    type: AlertTypes.ERROR,
    shouldVanish: true,
    message,
    title,
    cause,
  },
});

export const addSuccessAlert = message => ({
  type: ADD_ALERT,
  payload: {
    type: AlertTypes.SUCCESS,
    shouldVanish: true,
    message,
  },
});

export const dismissAlert = payload => ({
  type: DISMISS_ALERT,
  payload,
});

export const dismissAllAlerts = payload => ({
  type: DISMISS_ALL_ALERTS,
  payload,
});

export const toggleAlert = payload => {
  return {
    type: TOGGLE_ALERT,
    payload,
  };
};
