import firebase from 'firebase/app';
import 'firebase/messaging';
import notificationOptions from 'constants/notificationOptions';
import encodeRequestToHtml from 'utils/encoder';
import { updateNotification } from 'redux/actions/currentNotificationActions';
import { noop } from './global-utils';

export default class FirebaseService {
  constructor(props) {
    const {
      should_display_request_notifications_popup,
      dispatch,
      mission,
      caseOrder,
    } = props;
    this.isCaseId = !!caseOrder?.caseGuid;
    this.mission = mission;
    this.caseOrder = caseOrder;
    this.updateNotification = dispatch
      ? payload => dispatch(updateNotification(payload))
      : noop;
    this.show_notifications = should_display_request_notifications_popup;
  }

  isNotificationSupported = () =>
    'serviceWorker' in navigator &&
    this.show_notifications &&
    (window.Notification?.permission === 'default' ||
      window.Notification?.permission === 'granted') &&
    (this.mission?.customerNotificationOption ||
      this.caseOrder?.customerNotificationOption) ===
      notificationOptions.DEFAULT;

  requestPermission = async () => {
    await firebase.messaging().requestPermission();
  };

  getRegistration = async ({ registrationName }) => {
    const registrations = await navigator.serviceWorker.getRegistrations();
    if (registrations) {
      const firebaseServiceWorker = registrations.find(
        register =>
          register &&
          register.active &&
          register.active.scriptURL &&
          register.active.scriptURL.includes(registrationName),
      );
      if (!firebaseServiceWorker && registrations.length > 0) {
        return registrations[0];
      }
      return firebaseServiceWorker;
    }
    return null;
  };

  spawnNotificationOnTheSW = async (title, options) => {
    try {
      const firebaseServiceWorker = await this.getRegistration({
        registrationName: 'notification-abode-sw.js',
      });
      if (firebaseServiceWorker) {
        return firebaseServiceWorker.showNotification(title, options);
      }
    } catch (error) {
      console.log('There´s an error on the Register Service worker');
      return error;
    }
    return null;
  };

  refreshToken = async () => {
    const messaging = firebase.messaging();
    const { missionOrderId } = this.mission;
    const { caseGuid } = this.caseOrder;
    try {
      const token = await messaging.getToken();
      console.log('Token refreshed: ', token);
      if (this.updateNotifications) {
        this.updateNotifications({
          missionOrderId,
          caseGuid,
          deviceId: token,
          customerNotificationOption: notificationOptions.FCM,
        });
      }
    } catch (err) {
      console.log('Unable to retrieve refreshed token ', err);
      if (this.updateNotifications) {
        this.updateNotifications({
          missionOrderId,
          caseGuid,
          deviceId: null,
          customerNotificationOption: notificationOptions.SMS,
        });
      }
    }
  };

  requestPermissionAndSubscribe = async () => {
    try {
      const messaging = await firebase.messaging();
      await messaging.usePublicVapidKey(process.env.REACT_APP_PUBLIC_KEY);
      await this.requestPermission();
      this.subscribe(messaging);
      return await this.getToken(messaging);
    } catch {
      return null;
    }
  };

  getToken = async messaging =>
    encodeRequestToHtml({ token: await messaging.getToken() }).token;
  subscribe = messaging => {
    try {
      messaging.onBackgroundMessage(payload => {
        console.log('Receiving message', payload);
        const { notification } = payload;
        if (notification) {
          const { title = 'Abode', ...options } = notification;
          this.spawnNotificationOnTheSW(title, options);
        }
      });
      messaging.onTokenRefresh(() => {
        this.refreshToken();
      });
    } catch (error) {
      console.log('SUBSCRIBE ERROR:', error);
    }
  };
}

export const initializeFCM = async () => {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
  });

  const messaging = firebase.messaging();
  return { messaging };
};
