export const recaptchaBadgeClassname = 'grecaptcha-badge';

export const onLoad = () => {
  if (window.grecaptcha && window.grecaptcha.ready) {
    window.grecaptcha.ready(hideCaptcha);
  }
};

const hideCaptcha = () => {
  const reCaptchaBadgeElements = document.getElementsByClassName(
    recaptchaBadgeClassname,
  );

  if (reCaptchaBadgeElements) {
    const badge = reCaptchaBadgeElements[0];
    if (badge?.style) {
      badge.style.display = 'none';
    }
  }
};
