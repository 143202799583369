import fetchApi from 'utils/api-utils';

const SEND_CHAT_MESSAGE_URL = process.env.REACT_APP_SEND_CHAT_MESSAGE_URL;

export const sendChatMessage = async ({ case_guid, message }) => {
  const rs = await fetchApi(SEND_CHAT_MESSAGE_URL, {
    body: {
      case_guid,
      message: message.substring(0, 254),
    },
    method: 'POST',
  });
  return { status: rs.status, ok: rs.ok, data: rs };
};

export default sendChatMessage;
