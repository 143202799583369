import { useDispatch } from 'react-redux';
import { toggleAlert } from 'redux/actions/alertActions';
import Markdown from 'components/Markdown';
import { event } from 'analytics';
import {
  BannerContainer,
  BannerIconContainer,
  BannerTextContainer,
} from './BannerAlertStyled';
import AlertIcon from 'components/Icons/AlertIcon';
import XIcon from 'components/Icons/XIcon';
import { useTranslate } from 'hooks/translation-hooks';

const BANNER_ALERT_KEY = 'banner';

const BannerAlert = ({ message, category, closeAction }) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const closeBanner = () => {
    if (closeAction) {
      closeAction();
    } else {
      dispatch(toggleAlert(BANNER_ALERT_KEY));
    }
    event('close', category || 'special_event_banner');
  };

  return (
    <BannerContainer data-testid="bannner-alert">
      <BannerIconContainer>
        <AlertIcon />
      </BannerIconContainer>
      <BannerTextContainer>
        <Markdown text={message || t('special_event_banner-text_1')} />
      </BannerTextContainer>
      <div data-testid="close-banner" onClick={closeBanner}>
        <XIcon />
      </div>
    </BannerContainer>
  );
};

export default BannerAlert;
