import { call, cancel, fork, put, race, take } from 'redux-saga/effects';
import { expendRecaptcha } from 'redux/actions/recaptchaActions';
import { SET_RECAPTCHA, SET_RECAPTCHA_ERROR } from 'redux/actions/types';
import { requestRecaptchaToken } from 'redux/sagas/recaptcha/requestRecaptcha';
import generateToken from 'api/generateToken';

const takeEverySequentially = (patternOrChannel, saga, ...args) =>
  fork(function* () {
    const actionQueue = [];
    let freePath = true;
    let action, signal;
    while (true) {
      ({ action, signal } = yield race({
        action: take(patternOrChannel),
        signal: take(`${patternOrChannel}_DONE`),
      }));
      if (action) {
        if (freePath) {
          yield fork(saga, ...args.concat(action));
          freePath = false;
        } else {
          actionQueue.push(action);
        }
      } else if (signal) {
        if (actionQueue.length > 0) {
          yield fork(saga, ...args.concat(actionQueue.pop()));
        } else {
          freePath = true;
        }
      }
    }
  });

function* getRecaptchaToken() {
  const task = yield fork(requestRecaptchaToken);

  const { token, error } = yield race({
    token: take(SET_RECAPTCHA),
    error: take(SET_RECAPTCHA_ERROR),
  });

  if (error) {
    yield cancel(task);
    return;
  }

  yield put(expendRecaptcha());
  return token.payload;
}

function* getSynergySessionToken() {
  const googleToken = yield getRecaptchaToken();
  const {
    result: { sessionToken },
  } = yield call(generateToken, {
    body: {
      recaptcha: googleToken.split('').map(char => char.charCodeAt(0)),
    },
  });
  return sessionToken;
}

export { takeEverySequentially, getRecaptchaToken, getSynergySessionToken };
