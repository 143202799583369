import { useEffect, useState } from 'react';
import { Column, Row } from './ModalStyled';
import { noop } from 'utils/global-utils';

export const useModal = props => {
  const {
    onClickPrimaryButton = noop,
    disableClosePrimaryButton,
    onClickSecondaryButton = noop,
    disableCloseSecondaryButton,
    type = 'notifications',
    shouldCloseOnOutsideClick = false,
    onCloseButton,
    buttonDirection = 'ROW',
    title = '',
    status,
    body = '',
    componentBody,
    primaryButtonLabel = '',
    secondaryButtonLabel = '',
    customButtonLeft,
    customButtonRight,
    ...carryProps
  } = props;
  const [visible, setVisible] = useState(props.visible || true);
  const modals = {
    notifications: document.getElementById('modal-notifications'),
    media: document.getElementById('modal-media-selection'),
    cookies: document.getElementById('modal-cookies'),
    privatePolicies: document.getElementById('modal-private-policies'),
    error: document.getElementById('modal-errors'),
  };
  useEffect(() => {
    if (shouldCloseOnOutsideClick) {
      const modalSelected = modals[type];
      modalSelected.addEventListener('click', event => {
        if (event.target.id === modalSelected.id) {
          onCloseButton();
        }
      });
    }
    return () => {
      const modalSelected = modals[type];
      if (modalSelected) {
        modalSelected.removeEventListener('click', null, false);
      }
    };
  }, []);
  const onClickFirstButton = () => {
    if (!disableClosePrimaryButton) {
      setVisible(!visible);
    }
    onClickPrimaryButton();
  };

  const onClickSecondButton = () => {
    if (!disableCloseSecondaryButton) {
      setVisible(!visible);
    }
    onClickSecondaryButton();
  };

  const createButtonToolbar = ({ children }) => {
    if (buttonDirection === 'ROW') {
      return <Row>{children}</Row>;
    }
    return <Column>{children}</Column>;
  };
  return {
    carryProps,
    customButtonLeft,
    customButtonRight,
    body,
    componentBody,
    modals,
    title,
    type,
    primaryButtonLabel,
    status,
    visible,
    secondaryButtonLabel,
    onClickFirstButton,
    createButtonToolbar,
    onCloseButton,
    onClickSecondButton,
  };
};
