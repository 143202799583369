import { put, call, all } from 'redux-saga/effects';
import { postPicture } from 'api/picture';
import { addGtmPageView as pageview } from 'redux/actions/gtmActions';
import { addSuccessAlert, addErrorAlert } from 'redux/actions/alertActions';
import {
  createPictureStart,
  createPictureFail,
  createPictureSuccess,
  uploadPicture,
  uploadPictureSuccess,
} from 'redux/actions/pictureActions';

export function* postPictureFromApi({ payload }) {
  const { caseGuid, missionOrderId, pictures, successMessage, errorMessage } =
    payload;
  try {
    yield put(createPictureStart());
    const picturesToUpload = yield all(
      pictures.filter(picture => picture.status !== 'SENT'),
    );
    const responses = yield all(
      picturesToUpload.map(picture =>
        call(postPicture, {
          picture,
          caseGuid,
          missionOrderId,
        }),
      ),
    );
    yield all(
      responses.flatMap((response, index) => {
        if (response.status === 201) {
          put(pageview('PICTURES_UPLOAD_SUCCESS'));
          clearInterval(picturesToUpload[index].interval);
          return [
            put(
              uploadPictureSuccess({
                ...picturesToUpload[index],
                status: 'SENT',
              }),
            ),
            put(addSuccessAlert(successMessage)),
          ];
        } else {
          return [
            put(uploadPicture({ ...picturesToUpload[index], status: 'ERROR' })),
            put(addErrorAlert(errorMessage, undefined, response)),
          ];
        }
      }),
    );
  } catch (error) {
    return yield put(createPictureFail(error));
  } finally {
    yield put(createPictureSuccess());
  }
  return null;
}
