import { getName } from 'utils/random-utils';

export const getRandomName = () => `Abode-${getName()}`;

export const setGlobalVariables = ({ config }) => {
  window.ABODE = {
    timeout: config?.timeout || '60000',
  };
};

export const getGlobalVariable = ({ key }) => window.ABODE[key];

export const getProps = (object, keys, defaultVal) => {
  if (!object) {
    return defaultVal;
  }
  if (keys.length) {
    const keysProcessed = Array.isArray(keys) ? keys : keys.split('.');
    const objectProcessed = object[keysProcessed[0]];
    if (objectProcessed && keysProcessed.length > 1) {
      return getProps(objectProcessed, keysProcessed.slice(1));
    }
    return objectProcessed === undefined ? defaultVal : objectProcessed;
  }
  return undefined;
};

export const convertFileToBase64 = async ({ file }) =>
  new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split('base64,').pop());
  });

export const noop = () => {};

export const checkBooleanInPayload = (
  action,
  stateValue,
  defaultValue = true,
) => {
  if (!stateValue || !action) {
    return defaultValue;
  }
  const booleanPayload = getProps(action, `payload.${stateValue}`);
  if (booleanPayload === true || booleanPayload === false) {
    return booleanPayload;
  }

  return defaultValue;
};

export const isChildDescendant = ({ parentId, child }) => {
  let node = child?.parentNode;
  while (node != null) {
    if (node.id === parentId) {
      return true;
    }
    node = node?.parentNode;
  }
  return false;
};

export const toKebabCase = name => name.replace(/[A-Z]/g, '-$&').toLowerCase();

export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = s => {
  return s.replace(/([_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('_', '');
  });
};

export const cleanObject = obj =>
  Object.entries(obj).reduce(
    (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
    {},
  );
