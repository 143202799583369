import { takeLatest } from 'redux-saga/effects';
import {
  CHANGE_LANGUAGE_READY,
  RETRY_LANGUAGE_SETUP,
  SET_CONFIGURATION_START,
  SET_RECAPTCHA,
  GET_CASE_SUCCESS,
  GET_MISSION_SUCCESS,
} from 'redux/actions/types';
import loadConfig from './load-config-saga';
import specialPopupBannerAndTutorialEnabler from './enable-special-popup-banner-and-tutorial-saga';
import handleLanguageChange from './handle-language-change';

export default function* configSagas() {
  yield takeLatest(
    [GET_CASE_SUCCESS, GET_MISSION_SUCCESS],
    specialPopupBannerAndTutorialEnabler,
  );
  yield takeLatest(SET_CONFIGURATION_START, loadConfig);
  yield takeLatest(GET_CASE_SUCCESS, handleLanguageChange);
  yield takeLatest(GET_MISSION_SUCCESS, handleLanguageChange);
  yield takeLatest(RETRY_LANGUAGE_SETUP, handleLanguageChange);
  yield takeLatest(SET_RECAPTCHA, handleLanguageChange);
  yield takeLatest(CHANGE_LANGUAGE_READY, handleLanguageChange);
}
