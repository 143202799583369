import { put, select } from 'redux-saga/effects';
import { toggleAlert } from 'redux/actions/alertActions';
import { addCaseGuidTutorial } from 'redux/actions/tutorialActions';
import {
  showTutorialSelector,
  tutorialCasesSelector,
} from 'redux/selectors/tutorial';
import {
  alertSpecialSelector,
  alertBannerSelector,
} from 'redux/selectors/config';
import {
  selectBannerAlertsIsShownStatus,
  selectSpecialAlertIsShownStatus,
} from 'redux/selectors/alerts';
import { missionSelector } from 'redux/selectors/mission';
import { caseSelector } from 'redux/selectors/case';
import {
  getCaseAndMissionDataFromCase,
  getCaseAndMissionDataFromMission,
} from './gtm-saga-helper';
import { addGtmDataLayer } from 'redux/actions/gtmActions';

export default function* setSpecialBannerAlert({
  payload: { caseGuid, missionOrderId },
}) {
  const cases = yield select(tutorialCasesSelector);
  const isSpecialShown = yield select(selectSpecialAlertIsShownStatus);
  const isBannerShown = yield select(selectBannerAlertsIsShownStatus);
  const specialEnabled = yield select(alertSpecialSelector);
  const bannerEnabled = yield select(alertBannerSelector);

  const isTutorialDisplayedBefore = yield select(showTutorialSelector);
  const caseOrder = yield select(caseSelector);
  const mission = yield select(missionSelector);
  yield put(
    addGtmDataLayer(
      missionOrderId
        ? getCaseAndMissionDataFromMission(mission, missionOrderId)
        : getCaseAndMissionDataFromCase(caseOrder, caseGuid),
    ),
  );
  if (specialEnabled && !cases.includes(caseGuid) && !isSpecialShown) {
    yield put(toggleAlert('hasBeenSpecialActivated'));
    yield put(toggleAlert('special'));
  }
  if (bannerEnabled && !isBannerShown) {
    yield put(toggleAlert('hasBeenBannerActivated'));
    yield put(toggleAlert('banner'));
  }
  if (!isTutorialDisplayedBefore) {
    yield put(addCaseGuidTutorial(caseGuid));
  }
}
