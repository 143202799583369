import LanguageWorldItem from './LanguageWorldItem';
import {
  LanguageWorldContainer,
  LanguageBox,
  PointyThing,
} from './LanguageStyled';
import { useLanguages } from './LanguageHook';
import { useState } from 'react';
import WorldIcon from '../Icons/WorldIcon';
import { event } from 'analytics';

const LanguagesList = () => {
  const { handleButtonClick, language, languages } = useLanguages();
  const [showLanguageList, setShowLanguageList] = useState(false);
  const toggleShowLanguageList = () => {
    event('language_icon_toggle', 'language_selection', [
      showLanguageList ? 'close' : 'open',
    ]);
    setShowLanguageList(!showLanguageList);
  };

  const renderLanguagesText = languageItem => (
    <LanguageWorldItem
      key={languageItem}
      language={language}
      handleOnClick={handleButtonClick(languageItem)}
      itemLanguage={languageItem}
    />
  );

  return (
    languages.length && (
      <LanguageWorldContainer
        data-testid="menu-lang-container"
        onClick={toggleShowLanguageList}
      >
        <WorldIcon />
        {showLanguageList && (
          <LanguageBox>
            <PointyThing />
            {languages.map(renderLanguagesText)}
          </LanguageBox>
        )}
      </LanguageWorldContainer>
    )
  );
};

export default LanguagesList;
