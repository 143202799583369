import {
  GET_MISSION_FAIL,
  GET_MISSION_START,
  GET_MISSION_SUCCESS,
  UPDATE_APP_MISSION_START,
} from './types';
import { createAction } from './action-utils';
import {
  FetchMissionParams,
  UpdateMissionPayload,
} from './missionActions.types';
import { MissionResponse } from 'ts/types/mission.types';

const actions = {
  fetchMission: (params: FetchMissionParams) =>
    createAction(GET_MISSION_START, params),
  getMissionSuccess: (payload: MissionResponse) =>
    createAction(GET_MISSION_SUCCESS, payload),
  getMissionFail: (error: string) => createAction(GET_MISSION_FAIL, error),
  updateAppMission: (payload: UpdateMissionPayload) =>
    createAction(UPDATE_APP_MISSION_START, payload),
};

export default actions;
