import { LOCATION_CHANGE } from 'connected-react-router';
import { takeLatest } from 'redux-saga/effects';
import {
  trackPageViewOnRouteChange,
  moveCurrentStep,
  goHome,
} from './route-saga';
import { GO_HOME } from 'redux/actions/types';

export default function* routeSagas() {
  yield takeLatest(LOCATION_CHANGE, trackPageViewOnRouteChange);
  yield takeLatest(LOCATION_CHANGE, moveCurrentStep);
  yield takeLatest(GO_HOME, goHome);
}
