import styled from 'styled-components';

export const AlertManagerGrid = styled.div`
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
  z-index: 2;
`;

export const AlertManagerContainer = styled.div`
  position: relative;
  top: 31px;
  z-index: 2;

  > div {
    margin: 10px auto;
  }
`;
