export const picturesSelector = state => {
  return state.pictures;
};

export const insidePictureSectionSelector = state => {
  return picturesSelector(state).isInPictureSection;
};

export const uploadingPicturesSelector = state => {
  return picturesSelector(state).uploadingPictures;
};

export const uploadedPicturesSelector = state => {
  return picturesSelector(state).preparedToUpload;
};

export const isPicturesLoadingSelector = state => {
  return !!picturesSelector(state).isLoading;
};
