import {
  GET_MISSION_FAIL,
  GET_MISSION_START,
  GET_MISSION_SUCCESS,
  REMOVE_MISSION_DATA_FROM_GTM,
  UPLOAD_PICTURE_SUCCESS,
} from 'redux/actions/types';
import { MissionResponse, MissionState } from 'ts/types/mission.types';
import { MissionActionType } from 'redux/actions/missionActions.types';

const initialState: MissionState = {
  data: {},
  error: null,
};

function missionReducer(
  state = initialState,
  action: MissionActionType = { type: '', payload: null },
) {
  switch (action.type) {
    case GET_MISSION_START:
      return {
        ...state,
        error: null,
      };
    case GET_MISSION_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_MISSION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPLOAD_PICTURE_SUCCESS:
      const { data } = state;
      const { numberOfPicturesUploaded } = data as MissionResponse;
      if (numberOfPicturesUploaded !== undefined) {
        (data as MissionResponse).numberOfPicturesUploaded =
          numberOfPicturesUploaded + 1;
      }
      return {
        ...state,
        data,
      };
    case REMOVE_MISSION_DATA_FROM_GTM:
      return initialState;
    default:
      return state;
  }
}

export default missionReducer;
