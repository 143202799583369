import { push } from 'connected-react-router';
import { put, select } from 'redux-saga/effects';
import {
  followMyCaseFail,
  followMyCaseSuccess,
} from 'redux/actions/caseActions';
import { caseGuidCreatedSelector } from 'redux/selectors/caseCreation';
import routes from 'Routes/routesConfig';

export function* followMyCaseSaga() {
  try {
    const caseGuid = yield select(caseGuidCreatedSelector);
    yield put(push(routes.CASE.replace(':id', caseGuid)));
    yield put(followMyCaseSuccess(caseGuid));
  } catch (error) {
    yield put(followMyCaseFail(error));
  }
}
