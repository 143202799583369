import PropTypes from 'prop-types';
import SuccessIcon from 'components/Icons/SuccessIcon/SuccessIcon';
import GenericAlert from '../GenericAlert';
import { AlertTypes } from 'constants/alerts';

const SuccessAlert = props => {
  return (
    <GenericAlert {...props} type={AlertTypes.SUCCESS}>
      <SuccessIcon data-testid="success-tick-icon" />
    </GenericAlert>
  );
};

SuccessAlert.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
};

SuccessAlert.defaultProps = {
  id: '',
  message: '',
  title: '',
};

export default SuccessAlert;
