import { SHOW_MODAL, HIDE_MODAL } from 'redux/actions/types';

export const filterModal = (modals, modalType) =>
  modals.filter(({ type }) => modalType !== type);

const defaultState = {
  modals: [],
};

function modalReducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        modals: [
          ...filterModal(state.modals, action.payload.type),
          action.payload,
        ],
      };
    case HIDE_MODAL:
      return { modals: filterModal(state.modals, action.payload.type) };
    default:
      return state;
  }
}

export default modalReducer;
