import { put, call, select } from 'redux-saga/effects';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import { addErrorAlert } from 'redux/actions/alertActions';
import sendChatMessage from 'api/chat/sendChatMessage';
import chatActions from 'redux/actions/chatActions';
import { dataSelector } from 'redux/selectors/common';
const { replaceChatMessage } = chatActions;

export function* sendChatMessageSaga({ payload: { message } }) {
  try {
    yield put(startLoad());
    const { caseGuid: case_guid } = yield select(dataSelector);
    yield call(sendChatMessage, {
      case_guid,
      message,
    });
  } catch (error) {
    yield put(addErrorAlert(error));
  } finally {
    yield put(replaceChatMessage(message));
    yield put(stopLoad());
  }
}
