import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  height: 48px;
  cursor: pointer;
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  padding: 14px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 3px;

  :disabled {
    border: none;
    border-bottom: solid 2px
      ${({ theme }) => theme.borderColorDisabled || theme.onBackgroundColor};
    background-color: ${({ theme }) => theme.backgroundColorButtonDisabled};
    color: ${({ theme }) => theme.onPrimaryColor};
  }
`;

export const MainButton = styled(Button)`
  border: none;
  border-bottom: solid 2px
    ${({ theme }) => theme.borderColorButton || theme.primaryColor};
  background: ${({ theme }) => theme.primaryButtonColor || theme.primaryColor};
  color: ${({ theme }) => theme.onPrimaryButtonColor || theme.onPrimaryColor};
`;

export const OutlinedButton = styled(Button)`
  color: ${({ theme }) => theme.primaryButtonColor || theme.primaryColor};
  background: ${({ theme }) => theme.onPrimaryButtonColor || 'transparent'};
`;

export const SecondaryButton = styled(Button)`
  color: ${({ theme }) => theme.primaryButtonColor || theme.primaryColor};
  border: solid 1px
    ${({ theme }) => theme.primaryButtonColor || theme.primaryColor};
  background: ${({ theme }) => theme.onPrimaryButtonColor || 'transparent'};
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 1.17px;
  text-align: center;

  &:focus,
  &:active {
    outline: none;
  }
`;
