import { put, call } from 'redux-saga/effects';
import { uploadPictureToCMX } from 'api/picture';
import {
  createPictureStart,
  createPictureSuccess,
  preparePicture,
  preparePictureSuccess,
  preparePictureFailed,
  updatePreparePictureSuccess,
} from 'redux/actions/pictureActions';
import { event } from 'analytics';

export function* postPictureToCMX({
  picture,
  recaptchaToken,
  refresh,
  refresher,
}) {
  let isPictureSent = false;
  const UPLOAD_TO_CMX_ACTION = 'upload_to_cmx';
  const PICTURES_CATEGORY = 'pictures';
  const SUCCESSFUL = 'successful';
  const FAILED = 'failed';
  try {
    yield put(createPictureStart());
    yield put(preparePicture(picture));
    const response = yield call(uploadPictureToCMX, {
      picture,
      recaptchaToken,
    });
    const { result, status } = response;
    if (status === 200) {
      isPictureSent = true;
      picture.id = result;
      if (!refresh) {
        picture.interval = setInterval(refresher, 1620000);
        yield put(preparePictureSuccess(picture));
      } else {
        yield put(updatePreparePictureSuccess(picture));
      }
      yield event(UPLOAD_TO_CMX_ACTION, PICTURES_CATEGORY, [SUCCESSFUL]);
      return { isPictureSent };
    }
    yield put(preparePictureFailed({ result, status, response, picture }));
    yield event(UPLOAD_TO_CMX_ACTION, PICTURES_CATEGORY, [FAILED]);
  } catch (err) {
    yield put(preparePictureFailed({ err, picture }));
    yield event(UPLOAD_TO_CMX_ACTION, PICTURES_CATEGORY, [FAILED]);
  } finally {
    yield put(createPictureSuccess());
  }
  return { isPictureSent };
}
