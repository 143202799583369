import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Icon } from '../IconStyled';

export const SuccessIcon = ({ title, theme, isFloating }) => (
  <Icon isFloating={isFloating}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="success-icon"
      title={title}
    >
      <circle cx="8" cy="8" r="8" fill={theme.successColor} />
      <path d="M3 8.07692L6.84615 11.1538L13 5" stroke="white" />
    </svg>
  </Icon>
);

SuccessIcon.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  isFloating: PropTypes.bool,
};

SuccessIcon.defaultProps = {
  title: 'Success title',
  isFloating: false,
};

export default withTheme(SuccessIcon);
