import { fetchCurrentNotification } from 'redux/actions/currentNotificationActions';
import { startLoad, stopLoad } from 'redux/actions/loadingActions';
import { addSuccessAlert, addErrorAlert } from 'redux/actions/alertActions';
import { updateAppointment } from 'api/appointment/updateAppointment';
import { call, put, select } from 'redux-saga/effects';
import { missionJobReferenceSelector } from 'redux/selectors/mission';

export function* updateEventSaga({
  payload: {
    status,
    missionOrderId,
    additional_information,
    successMessage,
    errorMessage,
  },
}) {
  try {
    const jobReference = yield select(missionJobReferenceSelector);
    yield put(startLoad());
    const response = yield call(updateAppointment, {
      status,
      jobReference,
      missionOrderId,
      additional_information,
    });
    if (!response.ok) {
      throw new Error('Api error:', response.result);
    }
    yield put(addSuccessAlert(successMessage));
    yield put(fetchCurrentNotification({ id: missionOrderId }));
  } catch (error) {
    yield put(addErrorAlert(errorMessage));
  } finally {
    yield put(stopLoad());
  }
}
