import { GlobalActionModel } from './applicationActions.types';
import { ApplicationActionType } from './applicationActions';
import { CaseCreationActionType } from './caseCreationActions';

export const createAction = <T extends string, P extends {}>(
  type: T,
  payload: P,
) => {
  return {
    type,
    payload,
  } as {
    readonly type: T;
    readonly payload: P;
  };
};

export const getActionList = (actions: {
  [actionName: string]: (...args: any) => { type: string; payload: any };
}) => {
  return Object.values(actions).map(action => {
    return {} as ReturnType<typeof action>;
  });
};

export type ActionTypes =
  | GlobalActionModel
  | ApplicationActionType
  | CaseCreationActionType;
