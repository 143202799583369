import { FC, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import BingMapApi from './BingMapApi';
import { BingMapProps } from './BingMap.types';
import {
  isBingMapsLoaded,
  generateMapId,
  getRenderOptions,
  getBingMapScriptTag,
  clearMapPins,
  addMapPins,
} from './services';

const MapStyled = styled.div`
  position: relative;
  width: 100%;
`;

const BingMap: FC<BingMapProps> = ({
  bingMapsKey,
  zoom,
  pushPins,
  onClick,
  forwardMapApi,
  dataTestId,
  center,
}) => {
  const mountedRef = useRef(true);
  const [mapElementId] = useState(generateMapId());
  const [Microsoft, setMicrosoft] = useState<any | undefined>(undefined);
  const [bingMapApi, setBingMapApi] = useState<BingMapApi | undefined>(
    undefined,
  );

  // Load the Microsoft object into the state
  useEffect(() => {
    if (!isBingMapsLoaded()) {
      const script = getBingMapScriptTag();
      document.getElementsByTagName('body')[0].appendChild(script);
      // Declare the global function that will be called once the bing maps script is loaded.
      // The function passed as callback in the script tag is called bingMapsCallback
      window.bingMapsCallback = () => {
        mountedRef.current && setMicrosoft(window.Microsoft);
      };
    } else {
      setMicrosoft(window.Microsoft);
    }
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Create an instance of BingMapApi and attach it to the state
  useEffect(() => {
    if (Microsoft?.Maps?.Map) {
      const renderOptions = getRenderOptions(bingMapsKey, zoom);
      const map = new Microsoft.Maps.Map(`#${mapElementId}`, renderOptions);
      // Load the Search Module to for the Bing Map instance
      Microsoft.Maps.loadModule('Microsoft.Maps.Search', () => {
        if (mountedRef.current) {
          const searchManager = new Microsoft.Maps.Search.SearchManager(map);
          setBingMapApi(new BingMapApi(map, searchManager));
        }
      });
    }
  }, [bingMapsKey, Microsoft, mapElementId, zoom]);

  // Forward the map api instance only if required
  useEffect(() => {
    if (bingMapApi && forwardMapApi) {
      forwardMapApi(bingMapApi);
    }
  }, [bingMapApi, forwardMapApi]);

  // Repaint the pins
  useEffect(() => {
    if (bingMapApi?.map.entities) {
      clearMapPins(bingMapApi?.map);
      addMapPins(bingMapApi?.map, pushPins);
    }
  }, [bingMapApi, pushPins]); // eslint-disable-line react-hooks/exhaustive-deps

  // Center the map if the center is not null and the bing maps api exists
  useEffect(() => {
    if (!bingMapApi || !center) {
      return;
    }
    bingMapApi.center(center);
  }, [bingMapApi, center]);

  // Attach the click handler on every repaint to avoid wrong state issues
  useEffect(() => {
    if (bingMapApi && onClick) {
      const addHandler = Microsoft.Maps.Events.addHandler;
      const clickHandlerId = addHandler(bingMapApi.map, 'click', handleClick);
      return () => {
        Microsoft.Maps.Events.removeHandler(clickHandlerId);
      };
    }
  });

  const handleClick = (ev: any) => {
    if (onClick) {
      const { latitude, longitude } = ev.location;
      onClick({ latitude, longitude });
    }
  };

  return <MapStyled id={mapElementId} data-testid={dataTestId} />;
};

export default BingMap;
