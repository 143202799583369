import { FC, useEffect, useMemo, useState, ChangeEvent } from 'react';
import InputStyled from '../InputStyled';
import { InputStyledSize } from '../InputStyled/InputStyled.types';
import ClearIcon from '../../assets/icons/Clear';
import { AutocompleteProps } from './Autocomplete.types';
import {
  StyledAutocomplete,
  StyledOptionsList,
  StyledOption,
  StyledClearButton,
} from './styled';

const Autocomplete: FC<AutocompleteProps> = ({
  value,
  dataTestId = 'autocomplete',
  options,
  onChange,
  onFocus,
  onSearch,
  getOptionLabel,
  ref,
  inputSize = InputStyledSize.SMALL,
  ...rest
}) => {
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [shouldShowOptions, setShouldShowOptions] = useState<boolean>(false);

  useEffect(() => {
    setSearchInputValue(getOptionLabel ? getOptionLabel(value) : value);
  }, [value, getOptionLabel]);

  const filteredOptions = useMemo(() => {
    if (onSearch) {
      return options;
    }
    return options.filter(option => `${option}`.includes(searchInputValue));
  }, [options, onSearch, searchInputValue]);

  const handleSearchInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(ev.target.value);
    onSearch && onSearch(ev.target.value);
  };

  const handleOptionSelected = (selectedOption: any) => {
    setSearchInputValue(getOptionLabel ? getOptionLabel(value) : value);
    setShouldShowOptions(false);
    onChange && onChange(selectedOption as any);
  };

  const shouldShowClearButton = Boolean(value || searchInputValue);
  const shouldShowOptionsList =
    shouldShowOptions && filteredOptions && filteredOptions.length > 0;

  return (
    <StyledAutocomplete data-testid={dataTestId}>
      <InputStyled
        ref={ref}
        {...rest}
        value={searchInputValue}
        onChange={handleSearchInputChange}
        onFocus={() => setShouldShowOptions(true)}
        inputSize={inputSize}
        dataTestId={`${dataTestId}-input`}
      />
      {shouldShowClearButton && (
        <StyledClearButton
          data-testid={`${dataTestId}-clear`}
          onClick={() => handleOptionSelected(undefined)}
        >
          <ClearIcon />
        </StyledClearButton>
      )}
      {shouldShowOptionsList && (
        <StyledOptionsList>
          {filteredOptions.map((option, index) => (
            <StyledOption
              size={inputSize as InputStyledSize}
              onClick={() => handleOptionSelected(option)}
              key={`${dataTestId}-${index}`}
            >
              {getOptionLabel(option)}
            </StyledOption>
          ))}
        </StyledOptionsList>
      )}
    </StyledAutocomplete>
  );
};

export default Autocomplete;
