import {
  CREATE_PICTURE_FAIL,
  CREATE_PICTURE_START,
  CREATE_PICTURE_SUCCESS,
  RESET_PICTURES,
  REMOVE_PICTURE,
  PREPARE_PICTURE,
  PREPARE_PICTURE_FAILED,
  CLEAN_PICTURES,
  UPLOAD_PICTURE,
  UPLOAD_PICTURE_SUCCESS,
  IS_PICTURE_LOADING,
  ENTER_PICTURE_SECTION,
  EXIT_PICTURE_SECTION,
  MARK_PICTURE_TO_UPLOAD,
  PREPARE_PICTURE_SUCCESS,
  UPLOAD_PICTURES,
  UPLOAD_PICTURE_TO_CMX,
  UPLOAD_PICTURES_TO_CMX,
  UPDATE_PREPARE_PICTURE,
} from './types';

export const createPictureStart = () => ({
  type: CREATE_PICTURE_START,
});

export const createPictureSuccess = payload => ({
  type: CREATE_PICTURE_SUCCESS,
  payload,
});

export const loadPictures = payload => ({
  type: IS_PICTURE_LOADING,
  payload,
});

export const resetPictures = payload => ({
  type: RESET_PICTURES,
  payload,
});

export const removePicture = payload => ({
  type: REMOVE_PICTURE,
  payload,
});

export const preparePicture = payload => ({
  type: PREPARE_PICTURE,
  payload,
});

export const preparePictureSuccess = payload => ({
  type: PREPARE_PICTURE_SUCCESS,
  payload,
});

export const updatePreparePictureSuccess = payload => ({
  type: UPDATE_PREPARE_PICTURE,
  payload,
});

export const preparePictureFailed = payload => ({
  type: PREPARE_PICTURE_FAILED,
  payload,
});

export const cleanPictures = payload => ({
  type: CLEAN_PICTURES,
  payload,
});

export const uploadPicture = payload => ({
  type: UPLOAD_PICTURE,
  payload,
});

export const uploadPictureSuccess = payload => ({
  type: UPLOAD_PICTURE_SUCCESS,
  payload,
});

export const markPictureToUpload = payload => ({
  type: MARK_PICTURE_TO_UPLOAD,
  payload,
});

export const createPictureFail = error => ({
  type: CREATE_PICTURE_FAIL,
  payload: error,
});

export const enterPictureSection = () => ({
  type: ENTER_PICTURE_SECTION,
});

export const exitPictureSection = () => ({
  type: EXIT_PICTURE_SECTION,
});

export const postPicture = ({
  caseGuid,
  missionOrderId,
  pictures,
  successMessage,
  errorMessage,
  errorTitle,
}) => ({
  type: UPLOAD_PICTURES,
  payload: {
    caseGuid,
    missionOrderId,
    pictures,
    successMessage,
    errorMessage,
    errorTitle,
  },
});

export const postPictureToCMX = ({ picture, recaptchaToken }) => ({
  type: UPLOAD_PICTURE_TO_CMX,
  payload: {
    picture,
    recaptchaToken,
  },
});

export const uploadPicturesToCMX = payload => ({
  type: UPLOAD_PICTURES_TO_CMX,
  payload,
});
