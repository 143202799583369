import {
  GET_PLATE,
  GET_PLATE_UNNECESSARY,
  GET_PLATE_SUCCESS,
  GET_PLATE_FAIL,
  CLEAR_PLATE_READY,
} from 'redux/actions/types';
import { CarDetailsState } from 'ts/types/car-details.types';
import { CarDetailsActionType } from 'redux/actions/carDetails';

const initState: CarDetailsState = {
  data: {},
  error: undefined,
  carDetailsReady: false,
};

function applicationReducer(state = initState, action: CarDetailsActionType) {
  switch (action.type) {
    case GET_PLATE:
      return {
        ...state,
        data: action.payload,
        error: undefined,
      };
    case GET_PLATE_UNNECESSARY:
      return {
        ...state,
        carDetailsReady: true,
      };
    case GET_PLATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        carDetailsReady: true,
      };
    case GET_PLATE_FAIL:
      return {
        ...state,
        error: action.payload,
        carDetailsReady: true,
      };
    case CLEAR_PLATE_READY:
      return {
        ...state,
        carDetailsReady: false,
      };
    default:
      return state;
  }
}

export default applicationReducer;
